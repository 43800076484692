import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "../../ui/home/screens/LandingPage";
import ExploreCourse from "../../ui/home/screens/explore_course/ExploreCourse";
import JobSearchPage from "../../ui/home/screens/jobSearch/JobSearchPage";
import About from "../../ui/home/screens/About";
import Careers from "../../ui/home/screens/Careers";
import Contact from "../../ui/home/screens/Contact";
import Blogs from "../../ui/home/screens/Blogs";
import BlogInner from "../../ui/home/screens/BlogInner";
import StudyAbroad from "../../ui/home/screens/studyabroad/StudyAbroad";
import Webinars from "../../ui/home/screens/webinars/Webinars";
import Testimonials from "../../ui/home/screens/Testimonials";
import JobDetail from "../../ui/home/screens/jobSearch/JobDetail";
import LoanInfo from "../../ui/home/screens/LoanInfo";
import Migrate from "../../ui/home/screens/migrate/screen/Migrate";
import Destination from "../../ui/home/screens/Destination";
import BookAppointment from "../../ui/home/screens/BookAppointment";
import CourseDetail from "../../ui/home/screens/explore_course/CourseDetail";
import PageNotFound from "../../ui/home/screens/PageNotFound";
import ScrollTop from "../../general/ScrollTop";
import WhyToCanada from "../../ui/home/includes/migrate/WhyToCanada";
import WhyToAustralia from "../../ui/home/includes/migrate/WhyToAustralia";
import Offers from "../../ui/home/screens/Offers";

function AppRouter() {
  return (
    <>
      <ScrollTop />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/find-a-course" element={<ExploreCourse />} />
        <Route path="/course/:id" element={<CourseDetail />} />
        <Route path="/jobs" element={<JobSearchPage />} />
        <Route path="/job/:id" element={<JobDetail />} />
        <Route path="/loan-assistance/" element={<LoanInfo />} />
        <Route path="/about" element={<About />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:id" element={<BlogInner />} />
        <Route path="/study-abroad" element={<StudyAbroad />} />
        <Route path="/webinars" element={<Webinars />} />
        {/* <Route path="/migrate" element={<Migrate />} /> */}
        <Route path="/why-to-canada" element={<WhyToCanada />} />
        <Route path="/why-to-australia" element={<WhyToAustralia />} />
        
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/destination/:id" element={<Destination />} />
        <Route path="/book-appointment" element={<BookAppointment />} />
        <Route path="/offers" element={<Offers />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default AppRouter;
