import React from 'react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import PartcipateAndnWin from '../ui/home/includes/partcipateandnwin/PartcipateAndnWin';

function ParticipateModal({ isModal, setModal }) {
  const handleClose = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to close the Participate and Win?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, close it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setModal(false);
      }
    });
  };

  return (
    <MainContainer>
      {isModal ? <Overlay onClick={handleClose}></Overlay> : ""}
      <BackContainer style={{ transform: isModal && "scale(1,1)" }}>
        <Modal className="modal">
          <Container>
            <Close onClick={handleClose}>
              <i className="ri-close-line"></i>
            </Close>
            <PartcipateAndnWin setModal={setModal}/>
          </Container>
        </Modal>
      </BackContainer>
    </MainContainer>
  )
}

export default React.memo(ParticipateModal);

const BackContainer = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  margin: 0 auto;
  right: 0;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
`;
const Overlay = styled.div`
  background: rgba(35, 46, 65, 0.37);
  /* backdrop-filter: blur(2px); */
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0px;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 30%;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: 0.5s;
  z-index: 101;
  border-radius: 5px;
  @media all and (max-width: 1700px) {
    width: 30%;
  }
  @media all and (max-width: 1440px) {
    width: 35%;
  }
  @media all and (max-width: 1280px) {
    width: 40%;
  }
  @media all and (max-width: 1080px) {
    width: 45%;
  }
  @media all and (max-width: 768px) {
    top: 43%;
    width: 65%;
  }
  @media all and (max-width: 480px) {
    width: 90%;
  }
`;
const MainContainer = styled.div``;
const Close = styled.div`
  color: #c0c1c0;
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 30px;
  cursor: pointer;
  @media all and (max-width: 1440px) {
    top: 20px;
  }
`;
const Container = styled.div`
  background-color: #fff;
  display: flex;
  border-radius: 20px;
`;
