import React from "react";
import no_data from "../../../../../assets/images/nodata/2953960.png";
import { styled } from "styled-components";

function Nodata() {
  return (
    <Container>
      <img src={no_data} alt="no-data" />
    </Container>
  );
}

export default Nodata;

const Container = styled.div`
  width: 40%;
  margin: 0 auto;
  img {
    width: 100%;
    display: block;
  }
  @media all and (max-width: 1440px) {
    width: 80%;
  }
`;
