import React, { useEffect, useState } from "react";
import $ from "jquery";
import jQuery from "jquery";
import { sliderImages } from "../../../general/commonArrays";
import { styled } from "styled-components";
import mapimage from "../../../../assets/images/countries/Map.svg";
import loc from "../../../../assets/images/countries/loc.svg";
import Slider from "react-slick";

const CountrySlider = ({ setLat, setLong }) => {
  const [selectedCountry, setSelectedCountry] = useState("Germany");
  const [iconPosition, setIconPosition] = useState({ x: 460, y: 105 });
  const [transitionEnabled, setTransitionEnabled] = useState(false);
  const [isClicked, setClicked] = useState();

  const handleClick = (item) => {
    setIconPosition({ x: item.top, y: item.left });
    setTransitionEnabled(true);
  };
  useEffect(() => {
    $(".slide").hiSlide();
  }, []);

  //slick settings
  const settings = {
    centerMode: true,
    centerPadding: "0px",
    slidesToShow: 5,
    slidesToScroll: 1,
    margin: 20,
    dots: true,
    focusOnSelect: true,
  };
  useEffect(() => {
    const handleResize = () => {
      // Get the new window dimensions
      const { innerWidth, innerHeight } = window;
      // Adjust the icon's position if it exceeds the window bounds
      setIconPosition((prevPosition) => ({
        x: Math.min(prevPosition.x, innerWidth),
        y: Math.min(prevPosition.y, innerHeight),
      }));
    };

    // Attach the resize event listener
    window.addEventListener("resize", handleResize);

    return () => {
      // Clean up the event listener on component unmount
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  (function ($) {
    const slide = function (ele, options) {
      const $ele = $(ele);
      const setting = $.extend(
        true,
        {
          speed: 1000,
          interval: 2000,
        },
        options
      );
      const $lis = $ele.find("li");
      const $next = $ele.find(".hi-next");
      const $prev = $ele.find(".hi-prev");
      let timer = null;

      $next.on("click", function () {
        next();
      });

      $prev.on("click", function () {
        states.push(states.shift());
        move();
      });

      $ele
        .on("mouseenter", function () {
          clearInterval(timer);
          timer = null;
        })
        .on("mouseleave", function () {
          //   autoPlay();
        });

      const states = [
        {
          zIndex: 1,
          width: 65,
          height: 65,
          top: 60,
          left: -112,
          opacity: 0.7,
        },
        {
          zIndex: 2,
          width: 110,
          height: 96,
          top: 28,
          left: -30,
          opacity: 0.7,
        },
        {
          zIndex: 3,
          width: 140,
          height: 130,
          top: -5,
          left: 102,
          opacity: 0.7,
        },
        {
          zIndex: 4,
          width: 170,
          height: 180,
          top: -53,
          left: 263,
          opacity: 1,
        },
        {
          zIndex: 3,
          width: 140,
          height: 130,
          top: -2,
          left: 454,
          opacity: 0.7,
        },
        {
          zIndex: 2,
          width: 110,
          height: 96,
          top: 32,
          left: 613,
          opacity: 0.7,
        },
        {
          zIndex: 1,
          width: 65,
          height: 65,
          top: 63,
          left: 742,
          opacity: 0.7,
        },
      ];

      const move = () => {
        $lis.each(function (index, element) {
          const state = states[index];
          element.style.cssText = `z-index: ${state?.zIndex}; width: ${state?.width}px; height: ${state?.height}px; top: ${state?.top}px; left: ${state?.left}px; `;
        });
      };
      const next = () => {
        states.unshift(states.pop());
        move();
      };

      move();
      $lis.on("click", function () {
        states.unshift(states.pop());
        states[0].zIndex = 4;
        move();
      });
    };

    $.fn.hiSlide = function (options) {
      return this.each(function () {
        slide(this, options);
      });
    };
  })(jQuery);

  return (
    <>
      <Container>
        <Content>
          <ImageContainer>
            <img src={mapimage} alt="img" />
            <Loc
              style={{
                position: "absolute",
                top: iconPosition.y,
                left: iconPosition.x,
              }}
              className={transitionEnabled ? "icon-transition" : ""}
            >
              <img src={loc} alt="image" />
            </Loc>
          </ImageContainer>
        </Content>
      </Container>
      <CountryName>{selectedCountry} </CountryName>
      <ImageSlider className="con">
        <Slider {...settings}>
          {sliderImages.map((item) => (
            <Images
              className={isClicked === item.id && "active"}
              onClick={() => {
                setClicked(item.id);
                handleClick(item);
                setSelectedCountry(item.title);
              }}
            >
              <img
                src={item.image}
                className={isClicked === item.id && "active-img"}
                alt="image"
              />
            </Images>
          ))}
        </Slider>
      </ImageSlider>
    </>
  );
};

export default CountrySlider;

const Container = styled.div``;
const Banner = styled.div`
  background-color: rgba(0, 0, 0, 0.463);
  position: absolute;
  bottom: 0;
  color: #fff;
  width: fit-content;
  white-space: pre;
  left: 50%;
  right: 50%;
  transform: translate(-50%);
  padding: 10px;
  border-radius: 20px;
  text-align: center;
`;
const Content = styled.div``;
const ImageSlider = styled.div`
  /* display: flex;
  justify-content: center; */
  width: 60%;
  margin: 0 auto;
  height: 300px;
  margin-top: 50px;
`;
const Images = styled.div`
  width: 80% !important;
  position: relative;
  /* margin-right: 30px !important; */
  cursor: pointer;
  img {
    display: block;
    width: 100%;
    border-radius: 10px;
    &.active-img {
    }
  }
  transition: 0.4s ease;
  /* &.active {
    width: 250px;
    height: 167px;
    transition: 0.4s ease;
  } */
`;
const Loc = styled.div`
  width: 60px;
  img {
    display: block;
    width: 100%;
  }
  &.icon-transition {
    transition: top 0.4s ease, left 0.4s ease;
  }
`;
const ImageContainer = styled.div`
  position: relative;
  /* width: 50%; */
  width: 1000px;
  margin: 0 auto;
  img {
    display: block;
    width: 100%;
  }
  /* @media all and (max-width: 1600px) {
    width: 54%;
  }
  @media all and (max-width: 1500px) {
    width: 58%;
  }
  @media all and (max-width: 1400px) {
    width: 64%;
  }
  @media all and (max-width: 1300px) {
    width: 66%;
  } */
`;
const CountryName = styled.h4`
  text-align: center;
  letter-spacing: 10px;
  background: linear-gradient(
    9deg,
    rgba(0, 152, 246, 1) 0%,
    rgba(0, 92, 185, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
