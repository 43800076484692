import React from "react";
import styled from "styled-components";
import {
  excluive_immigration,
  skilled_immigration,
} from "../../../../general/commonArrays";
import Slider from "react-slick";

export default function Migration() {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    arrows: false,
  };
  
  return (
    <Container>
      <Skilled>
        <TopBar>
          <Title>Skilled Immigration</Title>
          <Description>
          If you're interested in migrating and settling
          down in another country, Medcity International
          Overseas Corporation can help you secure Permanent Residency
          (PR) in Canada, Australia, or New Zealand. Our experienced 
          consultants guide you through eligibility assessments, application
           preparation, and visa applications, ensuring a smooth and successful process.
          </Description>
        </TopBar>
        {/* <SubTitle>
          Select your preferred country to proceed with Skilled Worker Visa
        </SubTitle> */}
        <Cards>
          {skilled_immigration.map((item, index) => (
            <Card key={index}>
              <ImageContainer>
                <img src={item.image} alt={item.title} />
              </ImageContainer>
              <Detail>
                <TitleText>{item.title} </TitleText>
                <Desc>{item.description}</Desc>
              </Detail>
            </Card>
          ))}
        </Cards>
      </Skilled>
      {/* <Exclusive>
        <TitleEx>Exclusive Immigration Tools</TitleEx>
        <SubEx>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.Nullam non
          rhoncus orci. Ut consectetur, nisl et faucibusauctor,{" "}
        </SubEx>
        <FinderCards>
          {excluive_immigration.map((item, index) => (
            <FCard key={index}>
              <IconContainer>
                <img src={item.image} alt={item.title} />
              </IconContainer>
              <TitleF> {item.title} </TitleF>
              <FDescr>{item.description} </FDescr>
              <FButton>Explore Now</FButton>
            </FCard>
          ))}
        </FinderCards>
        <FindeMob>
          <Slider {...settings}>
            {excluive_immigration.map((item, index) => (
              <FCard key={index}>
                <IconContainer>
                  <img src={item.image} alt={item.title} />
                </IconContainer>
                <TitleF> {item.title} </TitleF>
                <FDescr>{item.description} </FDescr>
                <FButton>Explore Now</FButton>
              </FCard>
            ))}
          </Slider>
        </FindeMob>
      </Exclusive> */}
    </Container>
  );
}

const Container = styled.div``;
const Skilled = styled.div`
  background-color: #f8f8f8;
  padding: 40px 60px;
  @media (max-width: 480px) {
    padding: 20px;
  }
`;
const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`;
const Title = styled.h1`
  width: 40%;
  font-size: 45px;
  background: linear-gradient(
    90deg,
    #21a9c1 0%,
    #19a2bb 26.47%,
    #109bb5 52.94%,
    #1897b3 76.47%,
    #1f93b0 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media all and (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
    font-size: 35px;
  }

  @media screen and (max-width:580px) {
    font-size: 26px;
    text-align: center;
  }
`;
const Description = styled.p`
  width: 60%;
  color: #000000b7;
  line-height: 22px;
  @media all and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width:580px) {
    font-size: 16px;
    text-align: justify;
    word-break: break-all;
  }
`;
const SubTitle = styled.h3`
  text-align: center;
  font-weight: normal;
  margin-bottom: 50px;

  @media screen and (max-width:580px) {
    font-size: 16px;
  }
`;
const Cards = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;
const Card = styled.div`
  width: 45%;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;
const ImageContainer = styled.div`
  img {
    display: block;
    width: 100%;
  }
`;
const Detail = styled.div`
  background-color: #ebebea;
  padding: 40px;

  @media screen and (max-width:580px) {
    padding: 15px;
  }
`;
const TitleText = styled.div`
  text-align: center;
  color: #335f8a;
  font-size: 18px;
  margin-bottom: 20px;
`;
const Exclusive = styled.div`
  background-color: #161c35;
  padding: 40px 60px;
`;
const TitleEx = styled.h3`
  font-size: 26px;
  font-weight: normal;
  color: #fff;
  margin-bottom: 30px;
  text-align: center;
`;
const SubEx = styled.p`
  color: #fff;
  text-align: center;
`;
const FindeMob = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    margin-top: 30px;
  }
`;
const FinderCards = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 30px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const FCard = styled.div`
  background-color: #ffffff;
  padding: 40px;
  @media (max-width: 768px) {
    width: 75% !important;
    margin: 0 auto !important;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  height: 166px;
`;
const TitleF = styled.div`
  color: #335f8a;
  margin-bottom: 10px;
  text-align: center;
`;
const FDescr = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;
const FButton = styled.div`
  height: 40px;
  width: 150px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00aec0;
  font-size: 14px;
  margin: 0 auto;
`;
const Desc = styled.p`
  color: #000000b7;
  line-height: 24px;

  @media screen and (max-width:580px) {
    text-align: justify;
    word-break: break-all;
  }
`;
