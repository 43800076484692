import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import bg from "../../assets/images/enquiryform/bg.png";
import { countries, degree_type } from "../general/commonArrays";
import { accountsConfig } from "../../axiosConfig";
import Select from "react-select";
import ButtonLoader from "../general/loader/ButtonLoader";
import LeftBottomImg from "../../assets/images/enquiryform/icons/plane.svg";
import RightBottomImg from "../../assets/images/enquiryform/icons/building.svg";

export default function EnquiryForm({ isModal, setModal }) {
  const [degree, setDegree] = useState("");
  const [country, setCountry] = useState("");
  const [purpose, setpurpose] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [countryOption, setCountryOption] = useState("");
  const [phone, setPhone] = useState("");
  const [isTalk, setTalk] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [getCountryList, setCountryList] = useState([]);
  const [page, setPage] = useState(0);
  const [getProgrammes, setProgrammes] = useState([]);

  const purposes = ['Study','Migrate','Work'];
  
  useEffect(() => {
    if (countryOption?.length === 0) {
      fetchOptions();
    }
  }, [countryOption]);
  // ----------country---------------
  const fetchOptions = () => {
    const countryOptions = getCountryList?.map((item) => ({
      value: item.name,
      label: item.name,
      id: item.id,
    }));
    setCountryOption(countryOptions);
  };
  const optionsCountry = countryOption;

  //get Programme
  useEffect(() => {
    accountsConfig.get("study/get-programme/", {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setProgrammes(Data);
      } else {
      }
    });
  }, []);

  //get countries
  useEffect(() => {
    accountsConfig
      .get("study/get-countries/?priority_country=priority", {})
      .then((res) => {
        const StatusCode = res.data.app_data.StatusCode;
        const Data = res.data.app_data.data;
        if (StatusCode == 6000) {
          setCountryList(Data);
        } else {
        }
      });
  }, []);

  // -----------------Enquiry-----------------------
  const assignHandler = (e) => {
    setLoading(true);
    e.preventDefault();
    accountsConfig
      .post("study/user-data-enquiry/", {
        programme: degree,
        selected_country: country.id?.toString(),
        name: name,
        email: email,
        phone_code: "+91",
        phone: phone,
        is_expert_talk: isTalk,
      })
      .then((response) => {
        const data = response.data.app_data.data;
        if (response.data.app_data.StatusCode === 6000) {
          setModal(false);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {});
  };

  return (
    <>
      <MainContainer>
        {isModal ? <Overlay onClick={() => setModal(false)}></Overlay> : ""}
        <BackContainer style={{ transform: isModal && "scale(1,1)" }}>
          <Modal className="modal">
            <Container>
              <Left>
                <Subtitle>Lorem ipsum dolor sit amet consectetur.</Subtitle>
                <Title>
                  Lorem ipsum dolor sit amet consectetur.Ask us your doubts
                </Title>
                <Desc>
                  Lorem ipsum dolor sit amet consectetur. Lectus vitae vulputate
                  sit eleifend. Quis adipiscing ac viverra massa malesuada
                  rhoncus. Tortor lobortis mauris id donec. In vel sed blandit
                  lacus lorem at et fermentum. Dui in ac non velit. Vel odio
                  vitae morbi.
                </Desc>
              </Left>
              <Right>
                <Close onClick={() => setModal(false)}>
                  <i class="ri-close-line"></i>
                </Close>
                <TitleText>Start your journey with us</TitleText>
                {page == 0 ? (
                  <StartSection>
                    <Cover>
                      <Label>Your purpose of travel</Label>
                      <Tabs>
                        {
                        purposes.map((item,index)=>(
                          <Tab
                          onClick={()=>purpose === item ? setpurpose('') : setpurpose(item)}
                          key={index}
                          className={purpose === item && 'active' }
                          >
                            {item}
                          </Tab>
                        ))
                        }
                      </Tabs>
                    </Cover>
                    <Cover>
                      <Label>Which country you want to study in?</Label>
                      <Tabs className="country">
                        {getCountryList.map((x, index) => (
                          <Tab
                            key={index}
                            onClick={() => setCountry(x)}
                            className={country.id == x.id && "active"}
                          >
                            {x.name}
                          </Tab>
                        ))}
                      </Tabs>
                    </Cover>
                    <Cover>
                      <Label>Talk with our experts?</Label>
                      <Tabs>
                        <Tab
                          onClick={() => setTalk("True")}
                          className={isTalk == "True" && "active"}
                        >
                          Yes
                        </Tab>
                        <Tab
                          onClick={() => setTalk("False")}
                          className={isTalk == "False" && "active"}
                        >
                          No
                        </Tab>
                      </Tabs>
                    </Cover>
                  </StartSection>
                ) : (
                  <Form>
                    <Cover className="form">
                      <Label>Tell us your name</Label>
                      <Input
                        type="text"
                        placeholder="Enter your name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Cover>
                    <Cover className="form">
                      <Label>Share us your email</Label>
                      <Input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Cover>
                    <Cover className="forms">
                      <Label>Phone Number</Label>
                      <CoverInput>
                        <Code>+91</Code>
                        <Input
                          type="number"
                          placeholder="Enter your phone number"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </CoverInput>
                    </Cover>
                    <Pov>
                      *By submitting the form you are agreeing to our terms &
                      conditions and privacy policy
                    </Pov>
                  </Form>
                )}
                <Next
                  onClick={(e) => {
                    setPage(1);
                    page == 1 && assignHandler(e);
                  }}
                >
                  {isLoading ? <ButtonLoader /> : page == 1 ? "Submit" : "Next"}
                </Next>
                <Dots>
                  <Dot
                    className={page == 0 && "active"}
                    onClick={() => setPage(0)}
                  ></Dot>
                  <Dot
                    className={page == 1 && "active"}
                    onClick={() => setPage(1)}
                  ></Dot>
                </Dots>
              </Right>
            </Container>
          </Modal>
        </BackContainer>
      </MainContainer>
    </>
  );
}
const BackContainer = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  margin: 0 auto;
  right: 0;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
`;
const Overlay = styled.div`
  background: rgba(21, 62, 126, 0.927);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0px;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 60%;
  /* max-width: 350px; */
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: 0.5s;
  z-index: 101;
  border-radius: 5px;
  /* background: #fff; */
  @media all and (max-width: 1700px) {
    width: 70%;
  }
  @media all and (max-width: 1440px) {
    width: 65%;
  }
  @media all and (max-width: 1280px) {
    width: 70%;
  }
  @media all and (max-width: 1080px) {
    width: 80%;
  }
  @media all and (max-width: 768px) {
    width: 65%;
  }
  @media all and (max-width: 480px) {
    width: 90%;
  }
`;
const MainContainer = styled.div``;
const Close = styled.div`
  color: #c0c1c0;
  position: absolute;
  right: 30px;
  top: 30px;
  font-size: 30px;
  cursor: pointer;
  @media all and (max-width: 1440px) {
    top: 20px;
  }
`;
const Container = styled.div`
  background-color: #fff;
  display: flex;
  height: 650px;
  @media all and (max-width: 480px) {
    height: unset;
  }
`;
const Left = styled.div`
  color: #fff;
  background-color: #00aec0;
  padding: 80px 40px;
  width: 55%;
  background-image: url(${LeftBottomImg});
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;

  @media all and (max-width: 768px) {
    display: none;
  }
`;
const Title = styled.h3`
  color: #fff;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 40px;
`;

const Subtitle = styled.p`
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 174.9%; /* 17.49px */
  letter-spacing: 0.3px;
  margin-bottom: 15px;
`;
const Desc = styled.p`
  color: #fff;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 174.9%; /* 24.486px */
`;
const Line = styled.div`
  background-color: #fff;
  width: 100%;
  height: 1px;
  margin: 20px 0;
`;
const Right = styled.div`
  width: 45%;
  padding:30px 60px;
  background-image: url(${RightBottomImg});
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
  @media all and (max-width: 768px) {
    width: unset;
  }
  @media all and (max-width: 480px) {
    padding: 30px;
  }
`;
const TitleText = styled.h2`
  color: #565757;
  font-size: 26px;
  margin-bottom: 30px;
  @media all and (max-width: 768px) {
    font-size: 24px;
  }
  @media all and (max-width: 480px) {
    font-size: 20px;
  }
`;
const Cover = styled.div`
  margin-bottom: 35px;
  &.form {
    margin-bottom: 25px;
  }
  &.forms {
    margin-bottom: 5px;
  }
`;
const Label = styled.div`
  color: #565757;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 15px;
`;
const Tabs = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  &.country {
    flex-wrap: wrap;
  }
`;
const Tab = styled.div`
  padding: 8px 16px;
  border: 1px solid #c3c3c3;
  font-size: 14px;
  color: #696969;
  cursor: pointer;
  &.active {
    background-color: #00AEC0;
    color: #fff;
    border: 1px solid transparent;
  }
  @media all and (max-width: 480px) {
    padding: 6px 16px;
  }
`;
const Next = styled.div`
  width: 150px;
  margin: 0 auto;
  background-color: #01afc0;
  color: #fff;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media all and (max-width: 480px) {
    width: 120px;
    height: 30px;
  }
`;
const Dots = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 5px;
`;
const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #c0c1c0;
  &.active {
    background-color: #c0c1c0;
  }
`;

const StartSection = styled.div``;
const Form = styled.div``;
const Input = styled.input`
  border: 1px solid #cfcfcf;
  width: 100%;
  height: 40px;
  padding-left: 20px;
  box-sizing: border-box;
`;
const CoverInput = styled.div`
  display: flex;
  gap: 10px;
`;
const Code = styled.div`
  border: 1px solid #c3c3c3;
  display: flex;
  color: #7b7b7b;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
`;
const Pov = styled.h6`
  color: #7b7b7b;
  font-family: "ubuntu-light";
  margin-bottom: 50px;
`;
