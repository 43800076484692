import React from "react";

//packages
import styled from "styled-components";

//imports
import Title from "./titles/Title";
import DescriptionText from "./titles/DescriptionText";
import SubTitle from "./titles/SubTitle";
import {
  entry_program_canada,
  requirements,
} from "../../../../../general/commonArrays";

//images
import bluepoint from "../../../../../../assets/images/migrate/bluepoint.png";

export default function WorkPermits() {
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Left>
            <Title label="Family Sponsorship" />
            <DescriptionText label="Canada prioritizes family reunification in its immigration policy, offering various Sponsorship Immigration pathways. In 2023-25, over 100,000 new immigrants annually will be welcomed through family sponsorship programs, the second-highest category after economic immigrants. Eligible sponsors, Canadian citizens or permanent residents, can sponsor dependent children, parents, grandparents, spouses, siblings, and more. Each family member requires a separate application." />
            <SubTitle label="Sponsoring a Spouse:" />
            <DescriptionText label="For sponsoring a spouse, the sponsor must be 18+, a citizen or permanent resident, demonstrate financial ability, and prove the relationship. Spousal Open Work Permits are available for those awaiting permanent residence approval." />
            <SubTitle label="Sponsoring Dependent Children:" />
            <DescriptionText label="To sponsor dependent children, they must be unmarried and under 22, or over 22 with a dependency condition." />
            <SubTitle label="Sponsoring Parents/Grandparents:" />
            <DescriptionText label="Sponsoring parents/grandparents under the Parent and Grandparent Program requires the sponsor to be 18+, prove the relationship, meet income requirements, and commit to a 20-year sponsorship agreement." />
            <SubTitle label="Other Requirements Include:" />
            <Points>
              {requirements.map((obj, index) => (
                <CoverPoint key={index}>
                  <ICon>
                    <img src={bluepoint} alt="circle" />
                  </ICon>
                  <CoverTextPoint>
                    <Span>{obj.title} </Span>
                  </CoverTextPoint>
                </CoverPoint>
              ))}
            </Points>
            <DescriptionText label="Note: The sponsorship agreement's duration varies for each family member. Sponsored individuals are not eligible for government assistance, and sponsors are obligated even if circumstances change." />
          </Left>
          <Right>
            <Title label="Canada Work Permits Overview" />
            <DescriptionText label="A Canadian work permit allows non-permanent residents to live and work in Canada. Approximately half a million permits are issued annually, providing a pathway to permanent residence. To be eligible, applicants must demonstrate intent to leave post-permit, financial stability, admissibility, and employment intention with an eligible employer." />
            <SubTitle label="Types of Work Permits:" />
            <Points>
              {entry_program_canada.slice(0, 2).map((obj, index) => (
                <CoverPoint key={index}>
                  <ICon>
                    <img src={bluepoint} alt="circle" />
                  </ICon>
                  <CoverTextPoint>
                    <Span>{obj.title} </Span>
                  </CoverTextPoint>
                </CoverPoint>
              ))}
            </Points>
            <SubTitle label="Canada Work Permit Programs:" />
            <Points>
              {entry_program_canada.slice(0, 2).map((obj, index) => (
                <CoverPoint key={index}>
                  <ICon>
                    <img src={bluepoint} alt="circle" />
                  </ICon>
                  <CoverTextPoint>
                    <Span>{obj.title} </Span>
                  </CoverTextPoint>
                </CoverPoint>
              ))}
            </Points>
            <SubTitle label="Bringing Family Members:" />
            <DescriptionText label="Family members can accompany work permit holders, applying for visitor, student, or open work permits. Dependent children may also visit or study in Canada." />
            <SubTitle label="Application Process:" />
            <DescriptionText label="Apply online, including biometrics. Authorities assess employer eligibility, applicant qualification, and may conduct interviews. Successful applicants receive a letter of introduction at the port of entry, leading to the issuance of the work permit." />
            <SubTitle label="Canada Work Permit Information:" />
            <DescriptionText label="Includes permitted work type, employer details, location, and validity period. Required documents at entry include passport, visitor visa, port of entry letter, proof of experience and education, and employer-specific documents." />
            <SubTitle label="Global Skills Strategy for Faster Processing:" />
            <DescriptionText label="Introduced for expedited processing within two weeks. Criteria include application from outside Canada, job classification under specific categories, and compliance with LMIA requirements for certain cases." />
          </Right>
        </Content>
      </Wrapper>
    </Container>
  );
}
const Container = styled.div`
  padding: 80px 0;
  @media (max-width: 480px) {
    padding: 40px 0;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div`
  display: flex;
  gap: 50px;
  @media (max-width: 480px) {
    display: block;
  }
`;
const Right = styled.div`
  width: 50%;
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const Left = styled.div`
  width: 50%;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Points = styled.div`
  &.flex {
    display: flex;
    flex-wrap: wrap;
  }
  margin-bottom: 30px;
`;
const CoverPoint = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  &.flex {
    width: 50%;
  }
  @media (max-width: 480px) {
    align-items: flex-start;
  }
`;
const ICon = styled.div``;
const CoverTextPoint = styled.div`
  display: flex;
  gap: 5px;
`;
const TextPoint = styled.h4`
  font-size: 18px;
`;
const Span = styled.p`
  color: #2b2b2b;
  @media (max-width: 480px) {
    line-height: 26px;
  }
`;
