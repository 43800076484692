import { createContext, useReducer, useState } from "react";
import { reducer } from "./reducer";

export const StoreContext = createContext();


const initialState = {
  is_LoggedIn: false,
  phase:1,
  data: {
    access_token: null,
    refresh_token: null,
  },
  apply_details:{
    basic_info:{
    },
    current_educational_info:{
    },
    past_educational_info:{
    },
    college_interested:{
      
    }
  }
};

function Store({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  
  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
}

export default Store;
