import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { accountsConfig } from "../../../../../../axiosConfig";

export default function Guidelines() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false);
  const [getData, setData] = useState([]);

  useEffect(() => {
    accountsConfig.get("study/list-guidelines/").then((res) => {
      const data = res.data.app_data.data;
      if (res.data.app_data.StatusCode == 6000) {
        setData(data);
      } else {
        setData([]);
      }
    });
  }, []);
  const showNext = () => {
    setFade(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % getData.length);
      setFade(false);
    }, 500);
  };
  const showPrevious = () => {
    setFade(true);
    setTimeout(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + getData.length) % getData.length
      );
      setFade(false);
    }, 500);
  };
  const currentBlog = getData[currentIndex];

  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Title>Guidelines</Title>
          <SubTitle>
            Medcity helps you to leap forward to your dream destination….
          </SubTitle>
          <Desc>
            Our organization’s ultimate aim is to guide students to the best
            possible institutions, thus enabling them to build their future.
            Here are some guidelines to follow while applying to study abroad.
          </Desc>
          <Section>
            <Steps>
              <Step>
                <ImageContainer>
                  <img
                    src={currentBlog?.image}
                    className={`fade-image ${fade ? "fade" : ""}`}
                    alt=""
                  />
                </ImageContainer>
                <Right>
                  <CoverRight>
                    <Count>{currentBlog?.step}</Count>
                    <Text>{currentBlog?.title} </Text>
                    <Description>{currentBlog?.description} </Description>
                  </CoverRight>
                  <ButtonCover>
                    <Button onClick={() => showPrevious()}>
                      Prev
                      <i class="ri-arrow-right-s-line"></i>
                    </Button>
                    <Button onClick={() => showNext()}>
                      Next
                      <i class="ri-arrow-right-s-line"></i>
                    </Button>
                  </ButtonCover>
                </Right>
              </Step>
              <Bars>
                {getData.map((obj, index) => (
                  <Bar className={index == currentIndex && "active"}></Bar>
                ))}
              </Bars>
            </Steps>
          </Section>
        </Content>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  padding: 60px 0;
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const Bars = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
`;
const Bar = styled.div`
  height: 10px;
  width: 34px;
  border-radius: 2px;
  background-color: #e2e2e2;
  transform: skewX(317deg);
  &.active {
    background-color: var(--blue);
  }
`;
const Title = styled.h1`
  color: #00aec0;
  font-family: "ubuntu-medium";
  text-align: center;
  margin-bottom: 20px;
`;
const SubTitle = styled.div`
  margin-bottom: 20px;
`;
const Desc = styled.p`
  color: #555555;
`;
const Section = styled.div`
  margin-top: 50px;
`;
const Steps = styled.div``;
const Step = styled.div`
  display: flex;
  gap: 30px;
  border: 1px solid #e6e6e6;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 57px 211px 0px #00000012;
  height: 280px;
  @media (max-width: 480px) {
    flex-direction: column;
    height: unset;
  }
`;
const ImageContainer = styled.div`
  width: 24%;
  img {
    display: block;
    width: 100%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const CoverRight = styled.div`
  @media (max-width: 480px) {
    margin-bottom: 20px;
  }
`;
const Right = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const Count = styled.h2`
  font-family: "Rubik Scribble";
  color: #00aec0;
  margin-bottom: 20px;
`;
const Text = styled.h3`
  color: #00aec0;
  margin-bottom: 10px;
`;
const Description = styled.p`
  line-height: 24px;
  color: #555555;
`;
const ButtonCover = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Button = styled.div`
  background-color: var(--blue);
  font-size: 14px;
  color: #fff;
  height: 30px;
  cursor: pointer;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
