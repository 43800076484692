import React, { useEffect } from "react";
import { styled } from "styled-components";
import Header from "./Header";
import bg from "../../../../assets/videos/med.mp4";
import mob_bg from "../../../../assets/videos/medcity-mob.mp4";

function Spotlight() {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  useEffect(() => {
    if (!isIOS){
      document.getElementById("background-video").play();
    }
    document.getElementById("mobile-video").play();
  }, []);
  return (
    <Container>
      <video id="background-video" muted autoplay loop playsinline>
        <source src={bg} type="video/mp4" />
        <source src={bg} type="video/webm" />
        <source src={bg} type="video/ogg" />
      </video>
      <video id="mobile-video" muted autoplay loop autoPlay playsInline>
        <source src={mob_bg} type="video/mp4" />
        <source src={mob_bg} type="video/webm" />
        <source src={mob_bg} type="video/ogg" />
      </video>
      {/* <Whatsapp href="https://wa.me/+919645020503">
        <i class="ri-whatsapp-fill"></i>
      </Whatsapp> */}
    </Container>
  );
}

export default Spotlight;

const Container = styled.div`
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;

  /* @media screen and (max-width:580px) {
    height: 80vh;

    video{
      object-fit:contain;
    }
  } */
`;
const Whatsapp = styled.a`
  color: #25d366;
  font-size: 26px;
  background: #fff;
  z-index: 10000;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  position: fixed;
  cursor: pointer;
  border-radius: 50%;
  bottom: 50px;
  right: 50px;
  font-size: 40px;
  @media all and (max-width: 480px) {
    right: 30px;
  }
`;
