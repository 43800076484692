import React, { useRef } from "react";
import styled from "styled-components";
import leftArrow from "../../assets/images/icons/left-arrow.svg";
import upArrow from "../../assets/images/icons/uparrow.svg";
import downArrow from "../../assets/images/icons/downarrow.svg";
import plus from "../../assets/images/icons/plus.svg";
import minus from "../../assets/images/jobs/icons/minus.svg";

export default function JobFilterModal({
  isModal,
  setModal,
  test,
  maxexp,
  minexp,
  expanded,
  showMore,
  country,
  type,
  industry,
  jobtitle,
  countryList,
  jobtitleList,
  industryList,
  typeList,

  handleCountryChange,
  handleJobTitleChange,
  handleIndustryChange,
  handleTypeChange,
  handleExp,
  handleExpand,
  handleShowMore,
  clear,
}) {
  const form = useRef(null);
  const clearAll = () => {
    form.current.reset();
    clear();
  };

  return (
    <>
      <MainContainer>
        {isModal ? <Overlay onClick={() => setModal(false)}></Overlay> : ""}
        <BackContainer style={{ transform: isModal && "scale(1,1)" }}>
          <Modal>
            <Container>
              <TopBar>
                <Arrow onClick={() => setModal(false)}>
                  <img src={leftArrow} alt="" />
                </Arrow>
                Filters
              </TopBar>
              <Section className="section" ref={form}>
                <FilterOptions>
                  {/* Job Title */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(1)}>
                        <FilterName>Job Title</FilterName>
                        <ArrowImg>
                          <img
                            src={expanded === 1 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 1 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {jobtitleList &&
                              showMore !== 1 &&
                              jobtitleList?.length >= 5 &&
                              jobtitleList?.map((item, indx) => {
                                if (indx < 5 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="jobtitle"
                                        value={item.id}
                                        onClick={() =>
                                          handleJobTitleChange(item.id)
                                        }
                                        checked={jobtitle === item.id}
                                      />
                                      <DropDownItemName>
                                        {item.name}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(1)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {jobtitleList &&
                              (jobtitleList?.length <= 5 || showMore === 1) &&
                              jobtitleList?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="jobtitle"
                                    value={item.id}
                                    onClick={() =>
                                      handleJobTitleChange(item.id)
                                    }
                                    checked={jobtitle === item.id}
                                  />
                                  <DropDownItemName>
                                    {item.name}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}
                            {showMore === 1 && (
                              <ShowMore onClick={() => handleShowMore(1)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* Country */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(2)}>
                        <FilterName>Country</FilterName>
                        <ArrowImg onClick={() => handleExpand(2)}>
                          <img
                            src={expanded === 2 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 2 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {countryList &&
                              showMore !== 2 &&
                              countryList?.length >= 5 &&
                              countryList?.map((item, indx) => {
                                if (indx < 5 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="country"
                                        value={item.id}
                                        onClick={() =>
                                          handleCountryChange(item.id)
                                        }
                                        checked={country === item.id}
                                      />
                                      <DropDownItemName>
                                        {item.name}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(2)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {countryList &&
                              (countryList?.length <= 5 || showMore === 2) &&
                              countryList?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="country"
                                    value={item.id}
                                    onClick={() => handleCountryChange(item.id)}
                                    checked={country === item.id}
                                  />
                                  <DropDownItemName>
                                    {item.name}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}
                            {showMore === 2 && (
                              <ShowMore onClick={() => handleShowMore(2)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* Experience */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(3)}>
                        <FilterName>Experience</FilterName>
                        <ArrowImg onClick={() => handleExpand(3)}>
                          <img
                            src={expanded === 3 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 3 && (
                        <DropdownContainer>
                          <DropDownItems>
                            <Labels>
                              <Label>Min: {minexp}</Label>
                              <Label>Max: {maxexp}</Label>
                            </Labels>
                            <Range
                              type="range"
                              min="0"
                              max="50"
                              id="min"
                              value={minexp}
                              onChange={(e) => handleExp(e)}
                            />
                            <Range
                              type="range"
                              min="0"
                              max="50"
                              id="max"
                              value={maxexp}
                              onChange={(e) => handleExp(e)}
                            />
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* Industry */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(4)}>
                        <FilterName>Industry</FilterName>
                        <ArrowImg onClick={() => handleExpand(4)}>
                          <img
                            src={expanded === 4 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 4 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {industryList &&
                              showMore !== 4 &&
                              industryList?.length >= 5 &&
                              industryList?.map((item, indx) => {
                                if (indx < 5 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="industry"
                                        value={item.id}
                                        onClick={() =>
                                          handleIndustryChange(item.id)
                                        }
                                        checked={industry === item.id}
                                      />
                                      <DropDownItemName>
                                        {item.name}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(4)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {industryList &&
                              (industryList?.length < 5 || showMore === 4) &&
                              industryList?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="industry"
                                    value={item.id}
                                    onClick={() =>
                                      handleIndustryChange(item.id)
                                    }
                                    checked={industry === item.id}
                                  />
                                  <DropDownItemName>
                                    {item.value}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}
                            {showMore === 4 && (
                              <ShowMore onClick={() => handleShowMore(4)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* Employment type */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(6)}>
                        <FilterName>Employment type</FilterName>
                        <ArrowImg onClick={() => handleExpand(6)}>
                          <img
                            src={expanded === 6 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 6 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {typeList &&
                              showMore !== 6 &&
                              typeList?.length >= 5 &&
                              typeList?.map((item, indx) => {
                                if (indx < 5 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="type"
                                        value={item.value}
                                        onClick={() =>
                                          handleTypeChange(item.value)
                                        }
                                        checked={type === item.value}
                                      />
                                      <DropDownItemName>
                                        {item.name}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(6)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {typeList &&
                              (typeList?.length < 5 || showMore === 6) &&
                              typeList?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="type"
                                    value={item.value}
                                    onClick={() => handleTypeChange(item.value)}
                                    checked={type === item.value}
                                  />
                                  <DropDownItemName>
                                    {item.name}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}

                            {showMore === 6 && (
                              <ShowMore onClick={() => handleShowMore(6)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                </FilterOptions>
              </Section>
              <BottomBtns>
                <ClearAll onClick={() => clearAll()}>
                  Clear all filters
                </ClearAll>
                <Apply onClick={() => setModal(false)}>Apply</Apply>
              </BottomBtns>
            </Container>
          </Modal>
        </BackContainer>
      </MainContainer>
    </>
  );
}
const BackContainer = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  margin: 0 auto;
  right: 0;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
`;
const Overlay = styled.div`
  background: rgb(0 0 0 / 67%);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0px;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  margin: 0 auto;
  position: absolute;
  transition: 0.5s;
  z-index: 101;
  border-radius: 5px;
  height: 100vh;
  width: 100vw;
  /* background: #fff; */
`;
const MainContainer = styled.div``;
const Button = styled.div`
  background-color: #00b0c2;
  color: #fff;
  width: 150px;
  height: 35px;
  margin: 0 auto;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;

const Container = styled.div`
  position: relative;
  background-color: #fff;
  height: 100%;
`;
const InputCards = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  @media all and (max-width: 1080px) {
    flex-wrap: wrap;
  }
`;
const CloseBar = styled.div`
  background: #f5f4f5;
  position: absolute;
  padding: 10px;
  right: 20px;
  top: 20px;
  border-radius: 8px;
  cursor: pointer;
`;

// const Select = styled.select`
//   border: 1px solid #006492;
//   height: 40px;
//   background-color: #fff;
//   width: 250px;
//   padding-left: 20px;
//   color: #575657;
//   font-family: "ubuntu-regular";
//   outline: none;
//   @media all and (max-width: 1440px) {
//     width: 200px;
//   }
// `;
const Labels = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0 12px;
`;

const Label = styled.p`
  font-size: 14px;
  font-style: normal;
  color: #2c2c2c;
  margin: 0px;
  padding: 0px;
`;

const Section = styled.form`
  height: 77vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
`;

const FilterOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  /* border-bottom: 1px solid black; */
  padding-bottom: 25px;
`;

const Cover = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #d9d9d9;
`;

const Range = styled.input`
  width: 100%;
  background-color: #00aec0;
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  position: absolute;
  bottom: 0;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-color: #00aec0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: relative;
    z-index: 5;
    cursor: pointer;
  }

  &::-webkit-slider-runnable-track {
    position: relative;
    z-index: 1;
  }

  &#max {
    &::-webkit-slider-runnable-track {
      background-color: red;
      height: 0px;
    }

    &::-webkit-slider-thumb {
      vertical-align: middle;
      position: relative;
      transform: translateY(-50%);
    }
  }
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media all and (max-width: 1080px) {
    width: 95%;
  }
`;
const DropDown = styled.div`
  color: #006492;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const DropdownTop = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const DropDownItem = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;
`;

const FilterName = styled.span`
  color: #565757;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const TopBar = styled.div`
  width: 100%;
  padding: 15px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  z-index: 100;
  position: relative;
`;

const Arrow = styled.div`
  margin-right: 21px;
`;

const BottomBtns = styled.div`
  display: flex;
  gap: 14px;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  height: 10vh;
  background: #fff;
  padding: 0 15px;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
`;

const ClearAll = styled.div`
  border: 1px solid #909090;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  flex: 1 0 0;
  color: #909090;
`;

const Apply = styled.div`
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  flex: 1 0 0;
  background-color: #00aec0;
  color: #fff;
`;

const ShowMore = styled.div`
  display: flex;
  gap: 10px;
  margin: 5px 0;
  cursor: pointer;

  span {
    color: #00aec0;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
`;

const DropDownItemName = styled.p`
  color: #565757;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const ArrowImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 100%;
    display: block;
    height: auto;
  }
`;

const DropDownItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Radio = styled.input`
  width: 13px;
  height: 13px;
`;
