import React from 'react'
import { useLocation } from 'react-router-dom';
import styled from 'styled-components'

function GuidMe() {
    const location = useLocation();
    const isOfferPage = location.pathname === '/offers';
    return (
        <Section isOfferPage={isOfferPage}>
                <Left>
                    <Heading>Let us help you with your search</Heading>
                    <Content>Simply answer these 5 questions to see courses perfectly matched to you</Content>
                </Left>
                <Right>
                    <Button isOfferPage={isOfferPage} href='https://wa.me/+919645020503'>Start here</Button>
                </Right>
        </Section>
    )
}

export default GuidMe

const Section = styled.div`
    padding: ${props => props.isOfferPage ? '20px 30px' : '34px 40px'};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1090px){
        gap: 30px;
        flex-direction: column;
    }
    @media (max-width: 425px){
        padding: ${props => props.isOfferPage ? '30px 20px' : '34px 20px'};
    }
`
const Left  = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media (max-width: 1090px){
        text-align:start;
    }
`
const Right  = styled.div`
`
const Heading = styled.div`
    font-size: 20px;
    font-family: 'ubuntu-bold';
    opacity: 80%;
     @media (max-width: 425px){
        font-size: 18px;
    }
`
const Content = styled.div`
    font-size: 18px;
    font-family: 'ubuntu-medium';
    opacity: 80%;
     @media (max-width: 425px){
        font-size: 16px;
    }
`

const Button = styled.a`
    cursor: pointer;
    font-size: 20px;
    border-radius: 108px;
    color: white;
    background-color: var(--headerColor);
    padding: 14px 25px;
    font-family: 'ubuntu-bold';
    @media (max-width:800px){
        padding: ${props => props.isOfferPage ? '12px 20px' : '14px 25px'};
        font-size: 16px;
    }
`

