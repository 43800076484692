import React, { useEffect, useState } from "react";
import styled from "styled-components";

import flag from "../../assets/images/destination/icons/flag.svg";
import { ADD, REMOVE, hideOverFlow } from "../general/functions";

function DestinationProfileViewModal({ data, modal, setModal }) {
  
  useEffect(() => {
    if (modal) {
      hideOverFlow(ADD);
    } else {
      hideOverFlow(REMOVE);
    }
  }, [modal]);

  const handleModal = () => {
    setModal(false);
  };

  return (
    <Section className={modal && "active"}>
      <OverLay onClick={handleModal} />
      <Modal>
        <Container>
          <Top>
            <Title>{data?.country_name} Candidate Profile</Title>
            <CloseBtn onClick={handleModal}>close</CloseBtn>
          </Top>
          <Content>
            <Item className="key">
              <Block className="key">Backlogs</Block>
              <Block>{data?.backlogs}</Block>
            </Item>
            <Item>
              <Block className="key">Percentage/CGPA/GPA</Block>
              <Block>{data?.percentage_GGPA_GPA}</Block>
            </Item>
            <Item className="key">
              <Block className="key">GRE/IELTS/GMAT/PTE</Block>
              <Block>{data?.gre_ielts_gmat_pte}</Block>
            </Item>
            <Item>
              <Block className="key">Medicals</Block>
              <Block>{data?.medicals}</Block>
            </Item>
          </Content>
        </Container>
        <Bottom>
          <Heading>Visa checklist</Heading>
          <Boxes>
            {data?.visa_check_list?.map((obj) => (
              <Box>
                <Icon>
                  <img src={flag} alt="" />
                </Icon>
                <Value>{obj?.title}</Value>
              </Box>
            ))}
          </Boxes>
        </Bottom>
      </Modal>
    </Section>
  );
}
export default DestinationProfileViewModal;

const Section = styled.section`
  position: fixed;
  inset: 0;
  backdrop-filter: blur(2px);
  z-index: 255;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0000008a;
  transform: scale(0, 0);

  &.active {
    transform: scale(1, 1);
  }
`;

const Modal = styled.div`
  box-sizing: border-box;
  height: 85%;
  max-height: 575px;
  width: 75%;
  max-width: 1000px;
  margin: auto;
  position: relative;
  z-index: 256;
  background-color: rgba(248, 248, 248, 1);
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  overflow-y: auto;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
  @media screen and (max-width: 768px) {
    width: 95%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  box-sizing: border-box;
  height: 50%;
  min-height: 275px;

  @media screen and (max-width: 1025px) {
    height: 65%;
  }

  @media screen and (max-width: 768px) {
    padding: 15px;
  }

  @media screen and (max-width: 580px) {
    height: auto;
  }
`;

const OverLay = styled.div`
  position: absolute;
  inset: 0;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Content = styled.div`
  border: 1px solid rgba(179, 179, 179, 1);
`;

const Title = styled.h3`
  font-size: 25px;
  color: rgba(86, 87, 87, 1);

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

const CloseBtn = styled.button`
  border-radius: 5px;
  border: 1px solid rgba(105, 105, 105, 1);
  background-color: transparent;
  padding: 5px 12px;
  box-sizing: border-box;
  cursor: pointer;
`;

const Item = styled.div`
  padding: 10px;
  display: flex;

  &.key {
    background-color: rgba(238, 238, 238, 1);
  }

  @media screen and (max-width: 1150px) {
    justify-content: space-between;
  }
  @media screen and (max-width: 550px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Block = styled.div`
  font-size: 15px;
  color: rgba(86, 87, 87, 1);
  position: relative;
  padding-left: 10px;

  &.key {
    width: 35%;
    padding-left: 0px;

    &::after {
      content: ":";
      position: absolute;
      right: 0;
    }
  }

  @media screen and (max-width: 1280px) {
    &.key {
      width: 25%;
    }
  }
  @media screen and (max-width: 1130px) {
    &.key {
      width: 40%;

      &::after {
        display: none;
      }
    }
    width: 60%;
  }

  @media screen and (max-width: 580px) {
    &.key {
      width: 50%;
      font-weight: bold;
      min-width: 175px;
    }
    width: 80%;
  }

  @media screen and (max-width: 425px) {
    width: 100%;
  }
`;

const Bottom = styled.div`
  width: 100%;
  background-color: #e8e8e8;
  padding: 30px;
  box-sizing: border-box;
  /* height: 50%; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 275px;
  overflow: auto;

  @media screen and (max-width: 768px) {
    padding: 15px;
  }
`;

const Heading = styled.div`
  font-size: 21px;
  color: rgba(86, 87, 87, 1);
  font-family: "ubuntu-medium";
`;

const Boxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  height: 60%;
  /* max-height: 150px; */
  /* overflow-x: hidden; */
  /* overflow-y: scroll; */
`;

const Box = styled.div`
  width: 48%;
  display: flex;
  gap: 10px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Value = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(86, 87, 87, 1);
  margin: 0;
  padding: 0;
`;
