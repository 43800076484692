import React from "react";
import styled from "styled-components";

import thankYou from "../../../assets/images/course/modal/icon/thank-you.svg";
import { useNavigate } from 'react-router-dom';

function ThankYou({setmodal}) {
 
  return (
    <Section>
      <Wrapper>
        <Img>
          <img src={thankYou} alt="" />
        </Img>
        <Info>
          <Heading>Thank You</Heading>
          <Para>
            Thanks for your interest. We will contact you shortly with one of
            our consultants to guide you through the process. Please check for
            mails in the SPAM folder of your mailbox.
          </Para>
        </Info>
        <Buttons>
          <Btn onClick={()=>setmodal(false)}>Explore more courses</Btn>
        </Buttons>
      </Wrapper>
    </Section>
  );
}

export default ThankYou;

const Section = styled.section``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  align-items: center;

  @media screen and (max-width:580px) {
    gap: 25px;
  }
`;

const Img = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;

    @media screen and  (max-width:580px) {
      width:80%;
      margin:0 auto;
    }
`;

const Info = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction:column;
  gap:20px;

  @media screen and (max-width:580px) {
    width:90%;
  }
`;

const Buttons = styled.div``;

const Heading = styled.h3`
  text-align: center;
  font-weight: unset;
  color: rgba(86, 87, 87, 1);
  font-size: 28px;
  font-family: "ubuntu-medium";
  padding: 0;
  margin: 0;
`;

const Para = styled.p`
  color: rgba(86, 87, 87, 1);
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  padding: 0;
  margin: 0;
  font-weight: unset;

  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
`;

const Btn = styled.div`
  box-sizing: border-box;
  padding: 12px 25px;
  background-color: rgba(0, 174, 192, 1);
  color: white;
  cursor: pointer;
`;
