import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import logo from "../../../../assets/images/header/logo.png";
import { Link, Outlet } from "react-router-dom";
import overlay from "../../../../assets/images/header/headeroverlay.png";

function Header() {
  const [isActive, setActive] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  const [backgroundColor, setBackgroundColor] = useState(
    "hsl(0deg 0% 0% / 32%)"
  );

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      // Adjust the logic based on your requirements
      if (scrollPosition > 100) {
        setBackgroundColor("rgba(0, 100, 147, 0.567)"); // Change to default background color
      } else {
        setBackgroundColor("hsl(0deg 0% 0% / 32%)"); // Change to desired background color
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isVisible = prevScrollPos > currentScrollPos;

      setPrevScrollPos(currentScrollPos);
      setIsVisible(isVisible);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);
  // setTimeout(() => {
  //   setIsVisible(false);
  // }, 2000);
  return (
    <>
      <Container
        style={{ backgroundColor }}
        className={isVisible ? "sticky-header visible" : "sticky-header hidden"}
      >
        <Wrapper>
          <Content>
            <LogoSection>
              <a href="#">
                <img src={logo} alt="" />
              </a>
            </LogoSection>
            <ContentSection>
              <CoverSection>
                <Contact>
                  <ATag href="tel:+919645020503">
                    <i class="ri-phone-fill"></i>
                    <span>+919645020503 </span> |
                  </ATag>
                  <ATag href="tel:+918943280333">+918943280333</ATag>
                  <LoginButtons>
                    <Button>Login</Button>
                    <Book>
                      <i class="ri-arrow-right-s-fill"></i>
                      <span>Book an Appointment</span>
                    </Book>
                  </LoginButtons>
                </Contact>
                <NavSection>
                  <Nav>
                    <span>About Us</span>
                    <DropContainer>
                      <i class="ri-arrow-down-s-fill"></i>
                    </DropContainer>
                  </Nav>
                  <Nav>
                    <span>Destinations</span>
                    <DropContainer>
                      <i class="ri-arrow-down-s-fill"></i>
                    </DropContainer>
                  </Nav>
                  <Nav>
                    <span>Study Abroad</span>
                    <DropContainer>
                      <i class="ri-arrow-down-s-fill"></i>
                    </DropContainer>
                  </Nav>
                  <Nav>
                    <span>Immigration</span>
                    <DropContainer>
                      <i class="ri-arrow-down-s-fill"></i>
                    </DropContainer>
                  </Nav>
                  <Nav to="/blogs">
                    <span>Blog</span>
                  </Nav>
                  <Nav>
                    <span>Testimonials</span>
                  </Nav>
                  <Nav>
                    <span>Contact Us</span>
                  </Nav>
                </NavSection>
              </CoverSection>
              <LoginButton>
                <Button>Login</Button>
                <Book>
                  <i class="ri-arrow-right-s-fill"></i>
                  <span>Book an Appointment</span>
                </Book>
              </LoginButton>
              <Hamburger onClick={() => setActive(!isActive)}>
                <i class="ri-menu-3-line"></i>
              </Hamburger>
              <HamburgerDrop type={isActive}>
                <Nav>
                  <span>About Us</span>
                  <DropContainer>
                    <i class="ri-arrow-down-s-fill"></i>
                  </DropContainer>
                </Nav>
                <Nav>
                  <span>Destinations</span>
                  <DropContainer>
                    <i class="ri-arrow-down-s-fill"></i>
                  </DropContainer>
                </Nav>
                <Nav>
                  <span>Study Abroad</span>
                  <DropContainer>
                    <i class="ri-arrow-down-s-fill"></i>
                  </DropContainer>
                </Nav>
                <Nav>
                  <span>Study Abroad</span>
                </Nav>
                <Nav>
                  <span>Immigration</span>
                </Nav>
                <Nav to="/blogs">
                  <span>Blog</span>
                </Nav>
                <Nav>
                  <span>Testimonials</span>
                </Nav>
                <Nav>
                  <span>Contact Us</span>
                </Nav>
              </HamburgerDrop>
            </ContentSection>
          </Content>
        </Wrapper>
      </Container>
      <Outlet />
    </>
  );
}

export default Header;

const Container = styled.div`
  padding: 30px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: hsl(0deg 0% 0% / 32%);
  /* background: url(${overlay}); */
  color: #fff;
`;
const LoginButtons = styled.div`
  display: none;
  @media all and (max-width: 1360px) {
    display: flex;
    align-items: center;
  }
`;
const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  position: relative;
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const LogoSection = styled.div`
  width: 20%;
  img {
    width: 100%;
    display: block;
  }
`;
const ContentSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  width: 80%;
  @media all and (max-width: 1360px) {
    width: 75%;
  }
`;
const CoverSection = styled.div`
  margin-right: 50px;
  @media all and (max-width: 1280px) {
    margin-right: 15px;
  }
  @media all and (max-width: 1080px) {
    display: none;
  }
`;
const Contact = styled.div`
  display: flex;
  justify-content: flex-end;
  border-bottom: 3px solid #fff;
  padding-bottom: 10px;
  @media all and (max-width: 1360px) {
    align-items: center;
  }
`;

const ATag = styled.a`
  font-family: "ubuntu-light";
  margin-right: 20px;
  color: #fff;
  font-family: "ubuntu-medium";
  font-size: 18px;
  span {
    margin-right: 10px;
  }
  i {
    margin-right: 5px;
    color: #00b1c3;
    /* border: 1px solid #cacaca; */
    border-radius: 50%;
    padding: 4px;
    background: #fff;
  }
`;
const NavSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
  margin-top: 10px;
`;
const Nav = styled(Link)`
  margin-right: 20px;
  cursor: pointer;
  font-family: "ubuntu-light";
  transition: 0.4s ease;
  display: flex;
  &:hover {
    transition: 0.4s ease;
    color: #00b1c3;
  }
  span {
    white-space: pre;
    font-family: "ubuntu-medium";
    font-size: 18px;
    @media all and (max-width: 1440px) {
      font-size: 15px;
    }
  }
  @media all and (max-width: 1360px) {
    margin-bottom: 10px;
  }
  @media all and (max-width: 1080px) {
    margin-bottom: 30px;
    margin-right: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const DropContainer = styled.div``;
const HamburgerDrop = styled.div`
  display: none;

  @media all and (max-width: 1080px) {
    display: block;
    z-index: ${({ type }) => (type ? 1 : -1)};
    opacity: ${({ type }) => (type ? 1 : 0)};
    position: absolute;
    width: 100%;
    transition: 0.4s ease;
    top: 60px;
    right: -30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      -45deg,
      rgba(0, 100, 147, 1) 0%,
      rgba(0, 177, 195, 1) 100%
    );
    padding: 30px;
  }
`;
const Hamburger = styled.div`
  display: none;
  @media all and (max-width: 1080px) {
    display: block;
    margin-left: 50px;
    font-size: 30px;
  }
`;
const Button = styled.div`
  border: 2px solid #fff;
  width: 200px;
  height: 40px;
  /* color: #00b1c3; */
  /* background: #fff; */
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease;
  border-radius: 4px;
  font-family: "ubuntu-medium";
  font-size: 18px;
  &:hover {
    transform: scale(1.04);
    transition: 0.4s ease;
    color: #00b1c3;
    background: #fff;
  }
  @media all and (max-width: 1360px) {
    width: 150px;
    margin-right: 20px;
  }
  @media all and (max-width: 1080px) {
    margin-right: 10px;
  }
`;
const LoginButton = styled.div`
  width: 20%;
  @media all and (max-width: 1360px) {
    /* display: none; */
  }
`;
const Book = styled.div`
  width: 200px;
  cursor: pointer;
  height: 40px;
  /* border: 2px solid #00b1c3; */
  margin-top: 10px;
  /* background: linear-gradient(
    to right,
    #21a9c1 0%,
    26.470589637756348%,
    #109bb5 52.941179275512695%,
    76.47058963775635%,
    #1f93b0 100%
  ); */
  background: #fff;
  display: flex;
  font-family: "ubuntu-medium";
  font-size: 16px;
  color: #00b1c3;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease;
  border-radius: 4px;
  &:hover {
    transform: scale(1.04);
    transition: 0.4s ease;
    color: #fff;
    background: #00b1c3;
  }
  @media all and (max-width: 1360px) {
    width: 150px;
    font-size: 12px;
    margin-top: 0;
  }
  @media all and (max-width: 1080px) {
    margin-top: 0;
  }
  @media all and (max-width: 480px) {
    display: none;
  }
`;
