const SET_USER_DATA = "SET_USER_DATA";
const USER_LOGOUT = "USER_LOGOUT";
const UPDATE_PHASE = "UPDATE_PHASE";
const SET_BASIC_DATA = "SET_BASIC_DATA";
const SET_CURRENT_EDUCATION_INFO = "SET_CURRENT_EDUCATION_INFO";
const SET_PAST_EDUCATION_INFO = "SET_PAST_EDUCATION_INFO";
const SET_COLLEGE_INTERESTED = "SET_COLLEGE_INTERESTED";


export {
  SET_USER_DATA,
  USER_LOGOUT,
  UPDATE_PHASE,
  SET_BASIC_DATA,
  SET_CURRENT_EDUCATION_INFO,
  SET_PAST_EDUCATION_INFO,
  SET_COLLEGE_INTERESTED,
};
