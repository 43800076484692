import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { accountsConfig } from '../../../../axiosConfig';
import { useNavigate } from 'react-router-dom';

function DreamDestinations() {
    const navigate = useNavigate()
    const [getDestinations, setDestinations] = useState([]);

    useEffect(() => {
        accountsConfig.get("destinations/get-destinations/").then((res) => {
            const data = res.data.app_data.data;
            if (res.data.app_data.StatusCode === 6000) {
                setDestinations(data);
            } else {
                setDestinations([]);
            }
        });
    }, []);

    return (
        <Section>
            <Wrapper className="wrapper">
                <Title>Dream Destinations</Title>
                <CardContainerPC >
                    {getDestinations.map((destination, index) => (
                        <Card key={index} onClick={()=>navigate(`/destination/${destination.id}`)} style={{ backgroundImage: `url(${destination?.image})` }}>
                            <Location>{destination?.country_name}</Location>
                        </Card>
                    ))}
                </CardContainerPC>
                {/* <CardContainerTab >
                    <Swiper
                        spaceBetween={30}
                        slidesPerView={4}
                        breakpoints={{
                            200: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            1600: {
                                slidesPerView: 4,
                                spaceBetween: 30,
                            },
                        }}
                    >
                        {getDestinations.map((destination, index) => (
                            <SwiperSlide key={index}>
                                <Card onClick={()=>navigate(`/destination/${destination.id}`)} style={{ backgroundImage: `url(${destination?.image})` }}>
                                    <Location>{destination?.country_name}</Location>
                                </Card>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </CardContainerTab> */}
            </Wrapper>
        </Section>
    );
}

export default DreamDestinations;

const Section = styled.div`
    padding: 50px 0px;
    @media (max-width: 767px) {
        padding: 30px 0px;  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: 580px) {
    gap: 50px;
  }
  @media (max-width: 580px) {
    gap: 50px;
  }
  @media (max-width: 767px) {
    gap: 20px;
  }
`;

const Title = styled.h1`
  color: #000;
  @media (max-width: 767px) {
    font-size: 22px;
  }
`;
const CardContainerPC = styled.div`
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 1.5rem;
    @media (max-width:767px){
        grid-template-columns: repeat(2,1fr);
    }
`;

const CardContainerTab = styled.div`
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: center;
    align-items: center;
    display: none;
    @media (max-width:1023px){
        display: block;
    }
`
const Card = styled.div`
cursor: pointer;
    height: 350px;
    /* width: 18.5vw; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; 
    overflow: hidden; 

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(41, 35, 35, 0.5); 
        border-radius: 20px; 
        z-index: 0; 
    }
    @media (max-width:1300px){
        height: 200px ;
    }
    @media (max-width:1024px){
        width: 100%;
    }
    @media (max-width:768px){
        width: 100%;
        height: 200px;
    }
    @media (max-width:425px){
        width: 100%;
        height: 150px;
    }
`;

const Location = styled.div`
    font-family: 'ubuntu-bold';
    font-size: 30px;
    color: white;
    text-transform: uppercase;
    z-index: 10;
    @media (max-width:1300px){
        font-size: 25px;
    }
    @media (max-width:425px){
        font-size: 18px;
    }
`;
