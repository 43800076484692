import React, { useState } from "react";
import { styled } from "styled-components";
import {
  Arts,
  all_courses,
  business,
  computer,
  engineering,
  health,
  hospitality,
  law,
  tab_names,
} from "../../../general/commonArrays";
import Slider from "react-slick";

function Courses() {
  const [active, setActive] = useState("Engineering");
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 4000,
    autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Heading>
            <Title>Our Courses</Title>
            <Para>
              Medcity International Overseas Corporation provides unlimited
              education opportunities. We help to bridge the gap between
              students aspiring for further education abroad and universities
              into which they seek admission.
            </Para>
          </Heading>

          <CourseSection>
            {all_courses.map((item, index) => (
              <CourseCard key={index}>
                <Icon>
                  <img src={item.icon} alt="image" />
                </Icon>
                <TextCard>
                  <CourseTitle>{item.title} </CourseTitle>
                  <CourseDesc>{item.description}</CourseDesc>
                  <CoverStream>
                    {item.streams.map((item, index) => (
                      <Streams key={index}>
                        <span>{item.title} </span>
                      </Streams>
                    ))}
                  </CoverStream>
                </TextCard>
              </CourseCard>
            ))}
          </CourseSection>
          <MobileCourseSection>
            <Slider {...settings}>
              {all_courses.map((item, index) => (
                <CourseCard key={index}>
                  <Icon>
                    <img src={item.icon} alt="image" />
                  </Icon>
                  <TextCard>
                    <CourseTitle>{item.title} </CourseTitle>
                    <CourseDesc>{item.description}</CourseDesc>
                    <CoverStream>
                      {item.streams.map((item, index) => (
                        <Streams key={index}>
                          <span>{item.title} </span>
                        </Streams>
                      ))}
                    </CoverStream>
                  </TextCard>
                </CourseCard>
              ))}
            </Slider>
          </MobileCourseSection>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default Courses;

const Container = styled.div`
  padding: 0;
  background: #fff;
`;
const CourseDesc = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: #000000b7;
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const MobileCourseSection = styled.div`
  display: none;
  @media all and (max-width: 768px) {
    display: block;
  }
`;
const CoverStream = styled.div`
  max-height: 160px;
  margin-top: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 20px;
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar {
    width: 1px;
  }
  &::-webkit-scrollbar-thumb {
    background: linear-gradient(
      9deg,
      rgba(0, 152, 246, 1) 0%,
      #005cb9 100%
    ) !important;
    outline: 1px solid #009cb9 !important;
    border-radius: 50px;
  }
`;

const CourseCard = styled.div`
  cursor: pointer;
  align-items: center;
  width: 32%;
  margin-bottom: 30px;
  border-radius: 8px;
  /* transition: 0 0.4s ease;
  &:hover {
    box-shadow: 36px 49px 57px -51px rgba(0, 0, 0, 0.75);
    transition: 0.4s ease;
    transform: scale(1.01);
  } */
  /* max-height: 700px; */
  /* overflow: auto; */

  /* @media all and (max-width: 1280px) {
    width: 40%;
  } */
  @media all and (max-width: 1080px) {
    width: 49%;
  }
`;
const Icon = styled.div`
  margin-right: 30px;
  background-color: #7c4dff33;
  border-radius: 20px;
  width: 100%;
  img {
    display: block;
    width: 100%;
    margin: 0 auto;
    border-radius: 10px 10px 0 0;
  }
`;
const TextCard = styled.div`
  background-color: #ebebea;
  padding: 30px 50px;
  border-radius: 0 0 10px 10px;
`;
const CourseTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  color: #335f8a;
  white-space: nowrap;
  @media all and (max-width: 1780px) {
    font-size: 22px;
  }
  @media all and (max-width: 1680px) {
    font-size: 20px;
  }
  @media all and (max-width: 768px) {
    white-space: normal;
  }
`;
const Streams = styled.div`
  padding: 5px;
  span {
    background: #fff;
    display: block;
    padding: 6px 20px;
    color: #262626;
    font-size: 15px;
    border-radius: 8px;
  }
`;
const CourseSection = styled.div`
  /* grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  display: grid; */
  /* grid-gap: 30px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
  @media all and (max-width: 980px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media all and (max-width: 768px) {
    display: none;
  }
`;
const Cards = styled.div`
  /* width: 25%; */
  height: 100px;
  background: #eeeeee;
  border-radius: 0 0 40px 40px;
  color: #000000d2;
  padding: 20px 0 0 40px;
  transition: 0.4s ease;
  transition: all 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    transition: 0.4s ease;
    transition: all 0.3s ease-in-out;
    color: #fff;
    background: linear-gradient(
      0deg,
      rgba(0, 100, 147, 1) 0%,
      rgba(0, 177, 195, 1) 100%
    );
  }
`;
const Heading = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  /* margin: 50px 0; */
  @media all and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 50px;
  }
`;
const Title = styled.h1`
  font-size: 50px;
  /* color: var(--darkblue); */
  background: rgb(0, 152, 246);
  background: linear-gradient(
    9deg,
    rgba(0, 152, 246, 1) 0%,
    rgba(0, 92, 185, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 50px;
  @media all and (max-width: 768px) {
    font-size: 40px;
    margin-right: 0;
  }
  @media all and (max-width: 480px) {
    font-size: 28px;
  }
`;
const Para = styled.p`
  color: #2b2b2b;
  width: 60%;
  line-height: 26px;
  @media all and (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    margin-top: 10px;
    text-align: center;
  }
  @media all and (max-width: 480px) {
    font-size: 14px;
  }
`;
const TabSection = styled.div``;
const Tabs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 1080px) {
    flex-wrap: wrap;
  }
`;
const TabLink = styled.div`
  border-radius: 20px 20px 0 0;
  padding: 0px 15px 0px 15px;
  color: #555353;
  border: 1px solid #4f4f4f;
  background: #fff;
  z-index: 1;
  text-align: center;
  width: ${({ type }) => (type === "Arts" ? "60%" : "100%")};
  cursor: pointer;
  transform: scale(1.05);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  font-size: 14px;
  &.active {
    margin-right: 0px;
    border: 1px solid transparent;
    color: #fff;
    z-index: 2;
    padding-left: ${({ type }) =>
      type === "Hospitality & Tourism Management" ? "40px" : ""};

    background: var(--darkblue);
    transition: 0.4s ease;
    /* font-size: 16px; */
    transform: scale(1.1);
    @media all and (max-width: 1300px) {
      padding-left: 15px !important;
    }
  }
  @media all and (max-width: 1080px) {
    width: 30% !important;
    margin-bottom: 20px;
    margin-right: 20px;
  }
`;
