import React from "react";

//packages
import styled from "styled-components";

//imports
import Title from "./titles/Title";
import SubTitle from "./titles/SubTitle";
import DescriptionText from "./titles/DescriptionText";
import { entry_program_canada } from "../../../../../general/commonArrays";

//images
import bluepoint from "../../../../../../assets/images/migrate/bluepoint.png";

export default function StartupVisa() {
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Title label="Canada Start-Up Visa for Entrepreneurs" />
          <SubTitle label="Introduction:" />
          <DescriptionText
            label="Canada has witnessed a surge in immigration,
            especially among skilled individuals. Entrepreneurs are now welcomed
            through the Start-Up Visa program, aligning with Canada's goal of
            boosting its economy and creating job opportunities."
          />
          <SubTitle label="Eligibility Criteria:" />
          <DescriptionText
            label="To qualify for the Start-Up Visa, entrepreneurs must
            meet specific requirements:"
          />
          <SubTitle label="Eligibility Criteria:" />
          <DescriptionText
            label=" To qualify for the Start-Up Visa, entrepreneurs must meet specific
            requirements:"
          />
          <Points>
            {entry_program_canada.map((obj, index) => (
              <CoverPoint key={index}>
                <ICon>
                  <img src={bluepoint} alt="circle" />
                </ICon>
                <CoverTextPoint>
                  <Span>{obj.title} </Span>
                </CoverTextPoint>
              </CoverPoint>
            ))}
          </Points>
          <SubTitle label="Application Process:" />
          <DescriptionText label="The PR application process involves three phases:" />
          <Points>
            {entry_program_canada.slice(0, 3).map((obj, index) => (
              <CoverPoint key={index}>
                <ICon>
                  <img src={bluepoint} alt="circle" />
                </ICon>
                <CoverTextPoint>
                  <Span>{obj.title} </Span>
                </CoverTextPoint>
              </CoverPoint>
            ))}
          </Points>
          <SubTitle label="Timeline:" />
          <DescriptionText label="The processing time for the entire process may range from 12 to 18 months." />
        </Content>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  background-color: #f8f8f8;
  padding: 80px 0;
  @media (max-width: 480px) {
    padding: 40px 0;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div``;

const Points = styled.div`
  &.flex {
    display: flex;
    flex-wrap: wrap;
  }
  margin-bottom: 30px;
`;
const CoverPoint = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  &.flex {
    width: 50%;
  }
`;
const ICon = styled.div``;
const CoverTextPoint = styled.div`
  display: flex;
  gap: 5px;
`;
const TextPoint = styled.h4`
  font-size: 18px;
`;
const Span = styled.p`
  color: #2b2b2b;
`;
