import React from "react";
import styled from "styled-components";
import HeaderEdit from "../includes/HeaderEdit";
import Footer from "../includes/Footer";

import img1 from "../../../../assets/images/about/img-1.png";
import img2 from "../../../../assets/images/about/img-2.png";
import bg from "../../../../assets/images/about/bg.png";
import sketch from "../../../../assets/images/about/bg-2.svg";
import places from "../../../../assets/images/about/places.png";

function About() {
  const arr = [
    {
      name: `Great Universities Make Top Scholars`,
      body: `Our consultants help you to choose the right course and right university and accurate country as per your wish. Through our proper assistance you can achieve your goal easily.`,
    },
    {
      name: `Makes The Foundation Strong`,
      body: `We provide the best ever coaching with our well qualified tutors, who will assist you to get excellent scores in IELTS, OET, HAAD ,DHA, MOH, PTE, PROMETRIC(Saudi, Qatar, Oman) and German Language examinations.`,
    },
    {
      name: `Walks With You To Help Realise Your Dream`,
      body: `As you are in the initial steps of your dream, let us make our steps upward. For achieving any big goal we need to face thorns in life. We are here to destroy all these thorns and guide you to your future.`,
    },
    {
      name: `Get Ready To Fly`,
      body: `We will provides you visa assistance, and be there as an aid for your international travel.`,
    },
    {
      name: `Fear To New Country, Don’t Worry`,
      body: `Our consultants help you to choose the right course and right university and accurate country as per your wish. Through our proper assistance you can achieve your goal easily.`,
    },
    {
      name: `Achieve Your Goal With Us`,
      body: `This International Helpdesk will assist you, once you land in your destination, for accommodation, in finding part time jobs or even for suggestions at various phases of your study.`,
    },
  ];

  return (
    <Section>
      <HeaderEdit backgroundCl={"#00AEC0"} />
      <Container>
        <AboutContainer>
          <Wrapper className="wrapper">
            <AboutInfo>
              <Left>
                <Heading>Who We Are</Heading>
                <Para>
                  Medcity International Overseas Corporation (MIOC), a
                  subsidiary of Medcity International Academy, promises each of
                  its clients a holistic approach in ensuring the client’s
                  arrival to their dream destination of study or migration. MIOC
                  is a promise to your dreams to spread your wings and fly.
                  Started in 2016, MIOC has catered to the needs of over 5,000
                  students with prominent achievements being admission to MBBS
                  courses in Ukraine. MIOC apart from its overseas programme,
                  also assists its clients in admissions at home universities
                  and colleges. The biggest strength of MIOC is the huge student
                  base that Medcity International Academy has garnered over the
                  years with its beginnings in 2011. We help students study in
                  English speaking countries. Our ultimate motive is to connect
                  students with the right programme at the right university and
                  the right country. We provide you free, expert advice and
                  information on colleges, universities, courses, exams,
                  admission, student visa, and application process to study
                  overseas. MIAK's students settled across the globe will ensure
                  a network of support to all prospective clients of MIOC.
                </Para>
              </Left>
              <Right>
                <img src={img1} alt="" />
              </Right>
            </AboutInfo>
          </Wrapper>
        </AboutContainer>

        <AboutContainer className="bg-wht">
          <Wrapper className="wrapper">
            <AboutInfo className="flex-col">
              <Left>
                <img src={img2} alt="" />
              </Left>
              <Right>
                <Para className="txt-bl">
                  Over the years, Medcity has grown to be the best institute to
                  provide guidance for overseas study as well as training with
                  the help of our amazing faculties for examinations like IELTS,
                  OET, MOH, DHA, TOEFL, PTE, German Language and so on. Medcity
                  is the best coaching center that caters the needs of
                  professionals in medical science, IT, Engineering,
                  hospitality, aviation, travel and tourism, hotel management
                  and communication.
                </Para>
                <Para className="txt-bl">
                  In this rapidly developing and evolving world, thousands of
                  professionals, especially students with specialization in
                  various areas, are moving from India to many other developed
                  or fast developing nations. This has now become the “brain
                  bank” of our great nation. Our aim is to guide you with the
                  best possible assistance in terms of your overseas study dream
                  and training in OET, IELTS and other examinations to make your
                  dream a reality.
                </Para>
                <Para className="txt-bl">
                  Come, learn, grow and develop with Medcity and get enlightened
                  with the unique experience in your journey towards the future.
                </Para>
              </Right>
            </AboutInfo>
          </Wrapper>
        </AboutContainer>

        <WhatWeDo>
          <Wrapper className="wrapper what-we-do">
            <Content>
              <H1>What We Do</H1>
              <P>
                Medcity International Overseas Corporation (MIOC) is a unique
                venture which provides the best assistance in higher study
                programmes, migration and work purpose. Since 2016 we have been
                providing assistance in finding the top colleges and
                universities around the globe. Management, medical, engineering,
                arts, hospitality in any relevant field we ensure the right
                university and proper place considering all your requirements.
                We make an end to end contact with you and the university and
                update you with all the necessary information. Our sister
                concern Medcity International Academy (MIA) will provide you the
                best ever training for any English proficiency test like
                IELTS,OET,PTE or other qualifying examinations like MOH,HAAD,
                DHA, German language , Prometric (Saudi, Qatar, Oman) NCLEXRN,
                and so on. So from the initial stage we will be with you which
                is our speciality. We have an International Helpdesk with the
                network of our former students who are settled in various
                countries abroad.
              </P>
            </Content>
          </Wrapper>
        </WhatWeDo>

        <Features>
          <Wrapper className="wrapper features">
            {arr.map((item) => (
              <FeatureBox>
                <FeatureBoxTitle>{item.name}</FeatureBoxTitle>
                <FeatureBoxPara>{item.body}</FeatureBoxPara>
              </FeatureBox>
            ))}
          </Wrapper>
        </Features>

        <WhyUs>
          <Wrapper className="wrapper why-us">
            <LeftSide>
              <Title>Why Choose Us?</Title>
              <Body>
                Medcity International Overseas Corporation (MIOC) falls back and
                takes strength from its sister concern, Medcity International
                Academy (MIA) which has been training students since 2012. MIA
                is still the most preferred training centre by students for
                IELTS, PTE or any other competitive or skill test. This trust
                that the students have given over the years, transcends to the
                services offered by MIOC.This is just one reason to choose us to
                help make your dream of studying in a foreign university a
                reality.
              </Body>
              <Body>
                The well-informed and friendly counsellors, ready to help you
                sort through and finalise your course and university, all at
                completely no fee charged, should definitely be another reason
                to consider.
              </Body>
              <Body>
                If not these, then the testimonials of many happy, successful
                students who are now settled in different parts of the world
                should convince you to choose US over any other consultants.
              </Body>
              <Body>
                In addition, our International Help Desk comprising of the
                students of MIA, will assist you in comfortably settling in your
                choice of destination. Be it the UK, the USA, Australia or
                Canada, our student network is there to assist in all immediate
                needs of finding an accommodation, choosing the right
                neighbourhood, getting access to health, grocery and other
                facilities, and on top of it, to not feel lonely in a new place
                you are to reside for the years ahead.
              </Body>
              <Body>
                So what are you waiting for? <br />{" "}
                <a href="">Book an appointment</a> with us today.
              </Body>
            </LeftSide>
            <RightSide>
              <img src={places} alt="" />
            </RightSide>
          </Wrapper>
        </WhyUs>
      </Container>
      <FixedImg>
        <img src={bg} alt="" />
      </FixedImg>
      <Footer />
    </Section>
  );
}

export default About;

const Section = styled.section``;

const Container = styled.div`
  padding-top: 100px;

  @media (max-width: 1425px) {
    padding-top: 90px;
  }

  @media (max-width: 1200px) {
    padding-top: 80px;
  }

  @media (max-width: 950px) {
    padding-top: 73px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  &.what-we-do {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
  }

  &.features {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.why-us {
    display: flex;
    gap: 30px;
  }

  @media (max-width: 1080px) {
    &.what-we-do {
      justify-content: center;
    }

    &.why-us {
      flex-direction: column-reverse;
    }
  }
`;

const AboutInfo = styled.div`
  display: flex;
  padding: 80px 0;
  gap: 100px;
  justify-content: space-evenly;
  width: 100%;

  @media (max-width: 1080px) {
    flex-direction: column-reverse;
    gap: 70px;

    &.flex-col {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 580px) {
    gap: 25px;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 50%;

  img {
    width: 97%;
  }

  @media (max-width: 1080px) {
    width: 80%;
    margin: 0 auto;
  }

  @media (max-width: 580px) {
    width: 90%;
  }
`;

const Right = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  img {
    position: absolute;
    top: 25%;
    right: 0;
    width: 97%;
  }

  @media (max-width: 1150px) {
    img {
      top: 0;
    }
  }

  @media (max-width: 1080px) {
    width: 80%;
    margin: 0 auto;

    img {
      position: unset;
    }
  }

  @media (max-width: 580px) {
    width: 90%;
  }
`;

const Heading = styled.h1`
  color: #fff;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (max-width: 1080px) {
    text-align: center;
  }

  @media (max-width: 580px) {
    font-size: 32px;
  }
`;

const Para = styled.p`
  color: #fff;
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  line-height: 22px;
  width: 60ch;
  font-family: "ubuntu-regular";

  &.txt-bl {
    color: black;
  }

  @media (min-width: 1900px) {
    width: 70ch;
  }

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 1080px) {
    width: 100%;
    text-align: justify;
  }

  @media screen and (max-width: 580px) {
    font-size: 15px;
    word-break: break-all;
  }
`;

const AboutContainer = styled.div`
  background-color: #00aec0;

  &.bg-wht {
    background-color: #ffff;
  }
`;

const FixedImg = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  inset: 0;
  z-index: -5;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

const WhatWeDo = styled.div`
  /* height: 75vh; */
  width: 100%;
  padding: 60px 0 80px;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
`;

const Content = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  background: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(5px);
  width: 45%;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (max-width: 1080px) {
    width: 75%;
  }

  @media (max-width: 580px) {
    width: 100%;
    padding: 20px;
  }
`;

const H1 = styled.h1`
  color: #fff;
  text-align: center;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-family: "ubuntu-bold";

  @media screen and (max-width: 580px) {
    font-size: 32px;
  }
`;

const P = styled.p`
  color: #fff;
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  line-height: 130%; /* 20.8px */

  @media screen and (max-width: 580px) {
    font-size: 15px;
    word-break: break-all;
  }
`;

const Features = styled.div`
  background-image: url(${sketch});
  background-repeat: repeat;
  /* height: 50vh; */
  padding: 80px 0;
  background-color: #f8f8f8;
  background-size: 300px;
`;

const FeatureBox = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 30%;
  background-color: #fff;
  min-height: 170px;
  align-self: stretch;

  @media (max-width: 1080px) {
    width: 40%;
  }

  @media (max-width: 800px) {
    width: 80%;
    margin: 0 auto;
  }

  @media (max-width: 580px) {
    width: 100%;
  }
`;

const FeatureBoxTitle = styled.div`
  color: #147d92;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.44px;
`;

const FeatureBoxPara = styled.div`
  color: #2b2b2b;
  text-align: justify;
  font-size: 16.5px;
  line-height: 22px;
  font-family: "ubuntu-regular";

  @media (max-width: 1080px) {
    text-align: center;
  }

  @media (max-width: 580px) {
    font-size: 15px;
  }
`;

const WhyUs = styled.div`
  padding: 80px 0;
  background-color: #fff;
`;

const LeftSide = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 1080px) {
    width: 80%;
    margin: 0 auto;
  }

  @media (max-width: 580px) {
    width: 90%;
  }
`;

const RightSide = styled.div`
  display: flex;
  @media (max-width: 1250px) {
    width: 40%;
  }

  @media (max-width: 1180px) {
    width: 30%;
  }

  @media (max-width: 1080px) {
    width: 80%;
    margin: 0 auto;
    justify-content: center;
  }

  @media (max-width: 580px) {
    width: 90%;
  }

  img {
    object-fit: contain;
    width: 100%;
  }
`;

const Title = styled.h1`
  color: #1f93b0;
  font-size: 35px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-bold";

  @media (max-width: 1080px) {
    text-align: center;
  }

  @media (max-width: 580px) {
    font-size: 32px;
  }
`;

const Body = styled.p`
  color: #2b2b2b;
  text-align: justify;
  font-size: 16.5px;
  line-height: 22px;
  font-family: "ubuntu-regular";
  width: 60ch;

  br {
    display: none;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }

  @media screen and (max-width: 580px) {
    font-size: 15px;
    word-break: break-all;
    br {
      display: block;
    }
  }

  a {
    color: #0d2c7b;
  }
`;
