import axios from "axios";

//DEMO SERVER
// export const accountsConfig = axios.create({
//   baseURL: "https://api-medcity.accoladesweb.com/api/v1/",
// });

//LIVE SERVER
export const accountsConfig = axios.create({
  baseURL: "https://backend.medcityoverseas.com/api/v1/",
});

// Local Host
// export const accountsConfig = axios.create({
//   baseURL: "http://127.0.0.1:8000/api/v1/",
// });


