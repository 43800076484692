import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import Select from "react-select";

import DownArrow from "../../assets/images/icons/down-arrow.svg";
import UpArrow from "../../assets/images/icons/up-arrow.svg";
import Checked from "../../assets/images/icons/checked-arrow.svg";
import CloseBtnImg from "../../assets/images/icons/close-btn.svg";
import leftArrow from "../../assets/images/icons/left-arrow.svg";
import upArrow from "../../assets/images/icons/uparrow.svg";
import downArrow from "../../assets/images/icons/downarrow.svg";
import plus from "../../assets/images/icons/plus.svg";
import minus from "../../assets/images/jobs/icons/minus.svg";


export default function FilterModal({
  isModal,
  setModal,

  courseOption,
  DurationOption,
  intakeOption,
  yearOption,

  country,
  handleCountryChange,
  course,
  handleStudyAreaChange,
  year,
  handleYearChange,
  duration,
  handleDurationChange,
  esl,
  handleEslChange,

  intake,
  handleIntakeChange,

  disciplineArea,
  allDisciplineArea,
  handlediscipleAreaItemChange,
  programs,
  programsList,
  AddProgramToArray,
  requirementsList,
  AddRequirementsToArray,


  expanded,
  showMore,
  handleExpand,
  countryOption,
  handleShowMore,
  selected,
  clear
}) {
  const form = useRef(null);
  const clearAll = () =>{
    form.current.reset();
    clear()
  };

  return (
    <>
      <MainContainer>
        {isModal ? <Overlay onClick={() => setModal(false)}></Overlay> : ""}
        <BackContainer style={{ transform: isModal && "scale(1,1)" }}>
          <Modal>
            <Container>
              <TopBar>
                <Arrow onClick={()=>setModal(false)}>
                  <img src={leftArrow} alt="" />
                </Arrow>
                Filters
              </TopBar>
              <Section className="section" ref={form}>
              <FilterOptions>
                  {/* Country */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(1)}>
                        <FilterName>Country</FilterName>
                        <ArrowImg onClick={() => handleExpand(1)}>
                          <img
                            src={expanded === 1 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 1 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {countryOption &&
                              showMore !== 1 &&
                              countryOption?.length > 5 &&
                              countryOption?.map((item, indx) => {
                                if (indx < 5 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="country"
                                        value={item.id}
                                        onChange={(e) =>
                                          handleCountryChange(e.target.value)
                                        }
                                        checked={parseInt(country) === parseInt(item.id)}
                                      />
                                      <DropDownItemName>
                                        {item.value}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(1)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {countryOption &&
                              (countryOption?.length < 5 || showMore === 1) &&
                              countryOption?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="country"
                                    value={item.id}
                                    onChange={(e) =>
                                      handleCountryChange(e.target.value)
                                    }
                                    checked={parseInt(country) === parseInt(item.id)}
                                  />
                                  <DropDownItemName>
                                    {item.value}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}
                            {showMore === 1 && (
                              <ShowMore onClick={() => handleShowMore(1)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* Year */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(2)}>
                        <FilterName>Year</FilterName>
                        <ArrowImg onClick={() => handleExpand(2)}>
                          <img
                            src={expanded === 2 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 2 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {yearOption &&
                              showMore !== 2 &&
                              yearOption?.length > 5 &&
                              yearOption?.map((item, indx) => {
                                if (indx < 5 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="year"
                                        value={item.value}
                                        onChange={(e) =>
                                          handleYearChange(e.target.value)
                                        }
                                      />
                                      <DropDownItemName>
                                        {item.value}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(2)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {yearOption &&
                              (yearOption?.length < 5 || showMore === 2) &&
                              yearOption?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="year"
                                    value={item.value}
                                    onChange={(e) =>
                                      handleYearChange(e.target.value)
                                    }
                                  />
                                  <DropDownItemName>
                                    {item.value}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}
                            {showMore === 2 && (
                              <ShowMore onClick={() => handleShowMore(2)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* Intake */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(3)}>
                        <FilterName>Intake</FilterName>
                        <ArrowImg onClick={() => handleExpand(3)}>
                          <img
                            src={expanded === 3 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 3 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {intakeOption &&
                              showMore !== 3 &&
                              intakeOption?.length > 5 &&
                              intakeOption?.map((item, indx) => {
                                if (indx < 5 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="intake"
                                        value={item.value}
                                        onChange={(e) =>
                                          handleIntakeChange(e.target.value)
                                        }
                                        checked={ String(intake) === String(item.value)}
                                      />
                                      <DropDownItemName>
                                        {item.label}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(3)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {intakeOption &&
                              (intakeOption?.length < 5 || showMore === 3) &&
                              intakeOption?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="intake"
                                    value={item.value}
                                    onChange={(e) =>
                                      handleIntakeChange(e.target.value)
                                    }
                                    checked={ String(intake) === String(item.value)}
                                  />
                                  <DropDownItemName>
                                    {item.label}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}
                            {showMore === 3 && (
                              <ShowMore onClick={() => handleShowMore(3)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* Duration */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(4)}>
                        <FilterName>Duration</FilterName>
                        <ArrowImg onClick={() => handleExpand(4)}>
                          <img
                            src={expanded === 4 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 4 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {DurationOption &&
                              showMore !== 4 &&
                              DurationOption?.length > 5 &&
                              DurationOption?.map((item, indx) => {
                                if (indx < 5 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="duration"
                                        value={item.value}
                                        onChange={(e) =>
                                          handleDurationChange(e.target.value)
                                        }
                                        checked={ String(duration) === String(item.value)}
                                      />
                                      <DropDownItemName>
                                        {item.value}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(4)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {DurationOption &&
                              (DurationOption?.length < 5 || showMore === 4) &&
                              DurationOption?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="duration"
                                    value={item.value}
                                    onChange={(e) =>
                                      handleDurationChange(e.target.value)
                                    }
                                    checked={ String(duration) === String(item.value)}
                                  />
                                  <DropDownItemName>
                                    {item.value}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}
                            {showMore === 4 && (
                              <ShowMore onClick={() => handleShowMore(4)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* Study Area */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(5)}>
                        <FilterName>Study Area</FilterName>
                        <ArrowImg onClick={() => handleExpand(5)}>
                          <img
                            src={expanded === 5 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 5 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {courseOption &&
                              showMore !== 5 &&
                              courseOption?.length > 5 &&
                              courseOption?.map((item, indx) => {
                                if (indx < 5 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="course"
                                        value={item.value}
                                        onChange={(e) =>
                                          handleStudyAreaChange(e.target.value)
                                        }
                                        checked={ String(course) === String(item.value)}
                                      />
                                      <DropDownItemName>
                                        {item.value}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(5)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {courseOption &&
                              (courseOption?.length <= 5 || showMore === 5) &&
                              courseOption?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="course"
                                    value={item.id}
                                    onChange={(e) =>
                                      handleStudyAreaChange(e.target.value)
                                    }
                                    checked={ String(course) === String(item.value)}
                                  />
                                  <DropDownItemName>
                                    {item.value}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}

                            {showMore === 5 && (
                              <ShowMore onClick={() => handleShowMore(5)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* Discipline Area */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(6)}>
                        <FilterName>Discipline Area</FilterName>
                        <ArrowImg onClick={() => handleExpand(6)}>
                          <img
                            src={expanded === 6 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 6 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {disciplineArea &&
                              showMore !== 6 &&
                              disciplineArea?.length > 6 &&
                              disciplineArea?.map((item, indx) => {
                                if (indx < 6 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="disciplinearea"
                                        value={item.value}
                                        onChange={() =>
                                          handlediscipleAreaItemChange(
                                            item.value
                                          )
                                        }
                                        checked={
                                          String(selected) ===
                                          String(item.value)
                                        }
                                      />
                                      <DropDownItemName>
                                        {item.value}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(6)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {disciplineArea &&
                              (disciplineArea?.length <= 6 || showMore === 6) &&
                              disciplineArea?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="disciplinearea"
                                    value={item.id}
                                    onChange={() =>
                                      handlediscipleAreaItemChange(item.value)
                                    }
                                    checked={
                                      String(selected) === String(item.value)
                                    }
                                  />
                                  <DropDownItemName>
                                    {item.name}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}

                            {showMore === 6 && (
                              <ShowMore onClick={() => handleShowMore(6)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}

                            {/* List All disciplineArea */}
                            {disciplineArea?.length === 0 &&
                              showMore !== 11 &&
                              allDisciplineArea?.length > 11 &&
                              allDisciplineArea?.map((item, indx) => {
                                if (indx < 5 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="disciplinearea"
                                        value={item.name}
                                        onChange={() =>
                                          handlediscipleAreaItemChange(
                                            item.name
                                          )
                                        }
                                        checked={
                                          String(selected) === String(item.name)
                                        }
                                      />
                                      <DropDownItemName>
                                        {item.name}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore
                                      onClick={() => handleShowMore(11)}
                                    >
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {disciplineArea?.length === 0 &&
                              (allDisciplineArea?.length <= 6 ||
                                showMore === 11) &&
                              allDisciplineArea?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="disciplinearea"
                                    value={item.name}
                                    onChange={() =>
                                      handlediscipleAreaItemChange(item.name)
                                    }
                                    checked={
                                      String(selected) === String(item.name)
                                    }
                                  />
                                  <DropDownItemName>
                                    {item.name}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}

                            {showMore === 11 && (
                              <ShowMore onClick={() => handleShowMore(11)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* ESL/ELP Available */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(7)}>
                        <FilterName>ESL/ELP Available</FilterName>
                        <ArrowImg onClick={() => handleExpand(7)}>
                          <img
                            src={expanded === 7 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 7 && (
                        <DropdownContainer>
                          <DropDownItems>
                            <DropDownItem>
                              <Radio type="radio"
                               name="esl" 
                               value={'yes'} 
                               onChange={(e)=>handleEslChange(e.target.value)} 
                               checked={ String(esl) === String('yes')}
                               />
                              <DropDownItemName>YES</DropDownItemName>
                            </DropDownItem>
                            <DropDownItem>
                              <Radio type="radio"
                               name="esl" value={'no'} 
                               onChange={(e)=>handleEslChange(e.target.value)}
                               checked={ String(esl) === String('no')}
                              />
                              <DropDownItemName>NO</DropDownItemName>
                            </DropDownItem>
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* Programs */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(8)}>
                        <FilterName>Programs</FilterName>
                        <ArrowImg onClick={() => handleExpand(8)}>
                          <img
                            src={expanded === 8 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 8 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {programsList &&
                              showMore !== 8 &&
                              programsList?.length > 8 &&
                              programsList?.map((item, indx) => {
                                if (indx < 8 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="program"
                                        value={item.id}
                                        onChange={()=>AddProgramToArray(item.id)}
                                        checked={programs.includes(item.id) ? true : false}
                                      />
                                      <DropDownItemName>
                                        {item.name}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(8)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {programsList &&
                              (programsList?.length <= 8 || showMore === 8) &&
                              programsList?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="program"
                                    value={item.id}
                                    onChange={()=>AddProgramToArray(item.id)}
                                    checked={programs.includes(item.id) ? true : false}
                                  />
                                  <DropDownItemName>
                                    {item.name}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}

                            {showMore === 8 && (
                              <ShowMore onClick={() => handleShowMore(8)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* Requirements */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(9)}>
                        <FilterName>Requirements</FilterName>
                        <ArrowImg onClick={() => handleExpand(9)}>
                          <img
                            src={expanded === 9 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 9 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {requirementsList &&
                              showMore !== 9 &&
                              requirementsList?.length > 9 &&
                              requirementsList?.map((item, indx) => {
                                if (indx < 9 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="program"
                                        value={item.id}
                                        onChange={()=>AddRequirementsToArray(item.id)}
                                        checked={programs.includes(item.id) ? true : false}
                                      />
                                      <DropDownItemName>
                                        {item.name}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(9)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {requirementsList &&
                              (requirementsList?.length <= 9 ||
                                showMore === 9) &&
                              requirementsList?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="program"
                                    value={item.id}
                                    onChange={()=>AddRequirementsToArray(item.id)}
                                  />
                                  <DropDownItemName>
                                    {item.name}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}

                            {showMore === 9 && (
                              <ShowMore onClick={() => handleShowMore(9)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>
                </FilterOptions>
              </Section>
              <BottomBtns>
                  <ClearAll
                   onClick={()=>clearAll()}
                  >
                    Clear all filters</ClearAll>
                  <Apply
                  onClick={()=>setModal(false)}
                  >Apply</Apply>
              </BottomBtns>
            </Container>
          </Modal>
        </BackContainer>
      </MainContainer>
    </>
  );
}
const BackContainer = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  margin: 0 auto;
  right: 0;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
`;
const Overlay = styled.div`
  background: rgb(0 0 0 / 67%);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0px;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  margin: 0 auto;
  position: absolute;
  transition: 0.5s;
  z-index: 101;
  border-radius: 5px;
  height: 100vh;
  width: 100vw;
  /* background: #fff; */
`;
const MainContainer = styled.div``;
const Button = styled.div`
  background-color: #00b0c2;
  color: #fff;
  width: 150px;
  height: 35px;
  margin: 0 auto;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;

const Container = styled.div`
  position: relative;
  background-color: #fff;
  height: 100%;
`;
const InputCards = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  @media all and (max-width: 1080px) {
    flex-wrap: wrap;
  }
`;
const CloseBar = styled.div`
  background: #f5f4f5;
  position: absolute;
  padding: 10px;
  right: 20px;
  top: 20px;
  border-radius: 8px;
  cursor: pointer;
`;

// const Select = styled.select`
//   border: 1px solid #006492;
//   height: 40px;
//   background-color: #fff;
//   width: 250px;
//   padding-left: 20px;
//   color: #575657;
//   font-family: "ubuntu-regular";
//   outline: none;
//   @media all and (max-width: 1440px) {
//     width: 200px;
//   }
// `;
const LabelSelect = styled.h5`
  color: #575657;
  font-family: "ubuntu-light";
  margin-bottom: 5px;
`;
const SelectedCourse = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 10px;
  margin-bottom: 20px;
  @media all and (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
const FilteredCard = styled.div`
  display: flex;
  background-color: #006492;
  color: #fff;
  height: 35px;
  padding: 0 20px;
  /* width: 120px; */
  gap: 20px;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 480px) {
    height: 30px;
    font-size: 13px;
    padding: 0 15px;
  }
`;
const FilteredObject = styled.div``;
const Close = styled.div`
  color: #00b0c2;
  cursor: pointer;
`;
const Multiselect = styled.div`
  display: flex;
  cursor: pointer;
  border: 1px solid #006492;
  color: hsl(0, 0%, 50%);
  height: 38px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
`;
const Drop = styled.div`
  border-left: 1px solid #cdcaca;
  padding-left: 10px;
  font-size: 20px;
  color: #a8a7a7;
`;
const SelectDropDown = styled.div`
  position: absolute;
  background-color: #f5f4f5;
  padding: 20px;
  z-index: ${({ type }) => (type ? "1" : "-1")};
  opacity: ${({ type }) => (type ? "1" : "0")};
  width: 100%;
  box-sizing: border-box;
`;
const CoverSelect = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;
const SelectItem = styled.div`
  color: #006492;
`;
const Plus = styled.div`
  color: #006492;
  background-color: #fff;
  border-radius: 50%;
  width: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
`;

const Section = styled.form`
  height: 77vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
`;

const FilterOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  /* border-bottom: 1px solid black; */
  padding-bottom: 25px;
`;

const Cover = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #d9d9d9;
`;

const ProgramesDropdown = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px solid black;
  padding-bottom: 25px;
`;

const RequirementsDropdown = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 15px;
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media all and (max-width: 1080px) {
    width: 95%;
  }
`;
const DropDown = styled.div`
  color: #006492;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const DropdownTop = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const DropDownItem = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;
`;

const FilterName = styled.span`
  color: #565757;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const TopBar = styled.div`
  width: 100%;
  padding: 15px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  z-index: 100;
  position: relative;
`;

const Arrow = styled.div`
  margin-right: 21px;
`;

const BottomBtns = styled.div`
  display: flex;
  gap: 14px;
  width:100%;
  margin: 0 auto;
  align-items: center;
  height: 10vh;
  background: #FFF;
  padding: 0 15px;
  box-sizing:border-box;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
`;

const ClearAll = styled.div`
  border: 1px solid #909090;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  flex: 1 0 0;
  color: #909090;
`;

const Apply = styled.div`
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  flex: 1 0 0;
  background-color: #00aec0;
  color:#fff;
`;

const ShowMore = styled.div`
  display: flex;
  gap: 10px;
  margin: 5px 0;
  cursor: pointer;

  span {
    color: #00aec0;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
`;

const DropDownItemName = styled.p`
  color: #565757;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const ArrowImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 100%;
    display: block;
    height: auto;
  }
`;

const DropDownItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Radio = styled.input`
  width: 13px;
  height: 13px;
`;