import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { StoreContext } from "../../../context/Store";

import downArrow from "../../../../assets/images/careers/icons/down_arrow.svg";
import pdf from "../../../../assets/images/careers/icons/pdf.svg";
import ind from "../../../../assets/images/course/modal/icon/ind.png";
import { accountsConfig } from "../../../../axiosConfig";
import { SET_BASIC_DATA, UPDATE_PHASE } from "../../../context/types";
import ButtonLoader from "../../../general/loader/ButtonLoader";
import AuthModal from "../../AuthModal";

const initialFormData = {
  name: "",
  gender: "male",
  phone_code: "+91",
  email: "",
  city: "",
  course: "",
};

function Phase1({ courseData, setModal, setLoginModal }) {
  const { state, dispatch } = useContext(StoreContext);
  const [countryList, setcountryList] = useState([]);
  const [country, setcountry] = useState(0);
  const [error, seterror] = useState(0);
  const [loader, setloader] = useState(false);
  const formRef = useRef();
  const btnRef = useRef();

  //fetch countries to get the country phone code
  useEffect(() => {
    accountsConfig.get("study/get-countries/").then((response) => {
      const { data, StatusCode } = response.data.app_data;
      if (StatusCode === 6000) {
        setcountryList(data);
      } else {
        setcountryList([]);
      }
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setloader(true);
    const formData = new FormData(formRef.current);
    formData.append("course", courseData?.id);
    formData.append("course_name", courseData?.course_name);
    formData.append("program", courseData?.programme);

    const data = {};
    for (const [key, value] of formData.entries()) {
      if (key === "country") {
        data[key] = countryList[country]?.id;
      } else {
        data[key] = value;
      }
    }

    accountsConfig
      .post("accounts/create-user-account/", data)
      .then((response) => {
        const { data: resData, StatusCode } = response.data.app_data;
        setloader(false);

        if (StatusCode === 6000) {
          data.code = countryList[country]?.web_code;
          data.college = courseData?.university_name;
          data.profileId = resData?.profile_id

          dispatch({ type: SET_BASIC_DATA, payload: data });
          // intialy it was going to the otp submit page now it changed
          // dispatch({ type: UPDATE_PHASE, payload: 2 });

          dispatch({ type: UPDATE_PHASE, payload: 3 });
        } else {
          if (resData?.message === "Invalid phone number.") {
            seterror(1);
            resetError();
          }

          if (resData?.message === "") {
            seterror(2);
            resetError();
          }
        }
      });
  };

  const handleBtnClick = () => {
    btnRef.current.click();
  };

  const resetError = () => {
    setTimeout(() => {
      seterror("");
    }, 3000);
  };

  const openLoginModal = () => {
    setModal(false);
    setLoginModal(true);
  };

  return (
    <>
      {error === 2 && (
        <Error>Account is already registered . Login to continue</Error>
      )}
      <InputsDiv ref={formRef} onSubmit={handleSubmit}>
        <InputCover className="name">
          <Label>
            Name<span>*</span>
          </Label>
          <Input placeholder="Your name" type="text" name="name" required />
        </InputCover>

        <InputCover className="gender">
          <Label htmlFor="select-id" className="gender-select">
            Gender <span>*</span>
          </Label>
          <Select name="gender" required>
            <Option value={"male"}>Male</Option>
            <Option value={"female"}>Female</Option>
            <Option value={"other"}>Other</Option>
          </Select>
        </InputCover>

        <InputWrapper className="phone">
          <Box>
            <img src={countryList[country]?.flag} alt="" />
            <select
              name="country"
              onChange={(e) => setcountry(e.target.selectedIndex)}
            >
              {countryList?.map((item, index) => {
                if (index === 0) {
                  return (
                    <Option selected value={item?.phone_code}>
                      {item?.phone_code}
                    </Option>
                  );
                } else {
                  return (
                    <Option value={item?.phone_code}>{item?.phone_code}</Option>
                  );
                }
              })}
            </select>
          </Box>
          <InputCover className="w-70">
            <Label>
              {error === 1 ? <span>Invalid phone number</span> : "Phone Number"}
              {error !== 1 && <span>*</span>}
            </Label>
            <Input
              placeholder="Your number"
              type="number"
              name="phone"
              required
            />
          </InputCover>
        </InputWrapper>

        <InputCover className="email">
          <Label htmlFor="email">
            Email<span>*</span>
          </Label>
          <Input
            id="email"
            placeholder="Your email address "
            name="email"
            type="email"
            required
          />
        </InputCover>

        <InputCover className="city">
          <Label htmlFor="city">
            City You Live In<span>*</span>
          </Label>
          <Input
            id="city"
            placeholder="Enter your city"
            name="city"
            type="text"
            required
          />
        </InputCover>

        <InputCover className="courses">
          <Label htmlFor="courses">
            Course Interested In<span>*</span>
          </Label>
          <Input
            id="courses"
            placeholder="Enter Your course"
            name="course-name"
            type="text"
            value={courseData?.course_name}
            disabled
            required
          />
        </InputCover>

        <HiddedBtn ref={btnRef}></HiddedBtn>
      </InputsDiv>
      <Container>
        {/* <Info>
          Already registered?{" "}
          <span onClick={() => openLoginModal()}>Click here to login</span>
        </Info> */}
        {loader ? (
          <SubmiBtn>
            <ButtonLoader />
          </SubmiBtn>
        ) : (
          <SubmiBtn onClick={handleBtnClick}>Submit</SubmiBtn>
        )}
      </Container>
    </>
  );
}

export default Phase1;

const InputsDiv = styled.form`
  display: grid;
  gap: 24px;
  grid-template-areas:
    "name gender"
    "phone email"
    "city course";

  @media screen and (max-width: 768px) {
    grid-template-areas:
      "name gender"
      "phone phone"
      "email email"
      "city course";
  }

  @media screen and (max-width: 480px) {
    grid-template-areas:
      "name name"
      "gender gender"
      "phone phone"
      "email email"
      "city city"
      "course course";
  }
`;

const InputCover = styled.div`
  border: 1.5px solid #cfd3d4;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  background-color: #fff;

  &.w-70 {
    width: 70%;
  }

  &.name {
    grid-area: name;
  }

  &.email {
    grid-area: email;
  }

  &.phone {
    grid-area: phone;
  }

  &.gender {
    grid-area: gender;
  }

  &.city {
    grid-area: city;
  }

  &.courses {
    grid-area: course;
  }

  @media screen and (max-width: 480px) {
    height: 60px;
  }

  @media screen and (max-width: 380px) {
    &.w-70 {
      width: 60%;
    }
  }
`;

const Heading = styled.div`
  color: #565757;
  font-size: 21px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-medium";
`;

const Label = styled.label`
  color: #00aec0;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  position: relative;
  cursor: pointer;

  span {
    color: red;
  }

  &.role-select {
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 0;
      background-image: url(${downArrow});
      background-size: contain;
      width: 25px;
      height: 30px;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  &.cv {
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 0;
      background-image: url(${pdf});
      background-size: contain;
      background-repeat: no-repeat;
      width: 25px;
      height: 30px;
      cursor: pointer;
    }
  }

  &.cv-label {
    color: #abafb1;
    width: max-content;
    border-bottom: 1px solid #abafb1;
    cursor: pointer;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  gap: 5px;

  &.phone {
    grid-area: phone;
  }
`;

const HiddedBtn = styled.button`
  display: none;
`;

const Input = styled.input`
  border: none;
  outline: none;
  padding: 0;
  &::placeholder {
    color: #abafb1;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    font-family: "ubuntu-regular";
    padding: 0;
  }
`;

const Select = styled.select`
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #abafb1;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-regular";
  position: absolute;
  left: 0;
  right: 0;
  padding-left: 15px;
  padding-top: 25px;
  background-color: transparent;

  option:hover {
    background-color: red;
  }
`;

const Option = styled.option`
  color: #000000;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-regular";
  background-color: #ffffff;
  padding: 20px !important;
`;

const FileInput = styled.input`
  display: none !important;
`;

const SubmiBtn = styled.button`
  width: 20%;
  background-color: rgba(0, 174, 192, 1);
  color: #fff;
  text-align: center;
  font-size: 16px;
  line-height: normal;
  border: none;
  outline: none;
  padding: 14px 10px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 50%;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

const Box = styled.div`
  width: 30%;
  border: 1.5px solid #cfd3d4;
  display: flex;
  gap: 5px;
  box-sizing: border-box;
  padding: 5px;

  img {
    width: 30px;
    object-fit: contain;
    height: auto;
  }

  select {
    width: 100%;
    border: none;
    outline: none;
  }

  @media screen and (max-width: 380px) {
    width: 40%;
  }
`;

const Container = styled.div`
  margin-top: 35px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Info = styled.p`
  font-size: 16px;
  color: #000;

  span {
    color: rgba(0, 174, 192, 1);
    cursor: pointer;
  }
`;

const Error = styled.p`
  padding-bottom: 8px;
  color: red;
  font-size: 14px;

  @media screen and (max-width: 580px) {
    text-align: center;
  }
`;
