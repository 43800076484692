import React from "react";
import styled from "styled-components";

export default function Title({ label }) {
  return <Text>{label} </Text>;
}

const Text = styled.h2`
  color: #00aec0;
  font-size: 30px;
  margin-bottom: 20px;
  @media (max-width: 480px) {
    font-size: 24px;
  }
`;
