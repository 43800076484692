import React from "react";

//packages
import styled from "styled-components";

//imports
import Title from "./titles/Title";
import SubTitle from "./titles/SubTitle";
import SpaceText from "./titles/SpaceText";
import { entry_program_canada } from "../../../../../general/commonArrays";

//images
import bluepoint from "../../../../../../assets/images/migrate/bluepoint.png";

export default function EntryProgram() {
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Title label="Canada Express Entry Program" />
          <SubTitle label="Overview:" />
          <Description>
            <SpaceText /> The Canada Express Entry program is a point-based
            system managed by Immigration, Refugees and Citizenship Canada
            (IRCC) to select skilled immigrants for permanent residence.
          </Description>
          <SubTitle label="Key Points:" />
          <Points>
            {entry_program_canada.map((obj, index) => (
              <CoverPoint key={index}>
                <ICon>
                  <img src={bluepoint} alt="circle" />
                </ICon>
                <CoverTextPoint>
                  <Span>{obj.title} </Span>
                </CoverTextPoint>
              </CoverPoint>
            ))}
          </Points>
          <Conclusion>
            <SubTitle label="Conclusion:" />
            <Description>
              <SpaceText /> The Canada Express Entry Program is an efficient and
              straightforward pathway for skilled workers to secure permanent
              residence in Canada. For personalised guidance, consult our
              immigration experts.
            </Description>
          </Conclusion>
        </Content>
      </Wrapper>
    </Container>
  );
}
const Container = styled.div`
  padding: 80px 0;
  background-color: #f8f8f8;
  @media (max-width: 480px) {
    padding: 40px 0;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const Description = styled.p`
  color: #2b2b2b;
  line-height: 26px;
  margin-bottom: 20px;
`;
const Conclusion = styled.div``;
const Points = styled.div`
  &.flex {
    display: flex;
    flex-wrap: wrap;
  }
  margin-bottom: 20px;
`;
const CoverPoint = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  &.flex {
    width: 50%;
  }
`;
const ICon = styled.div``;
const CoverTextPoint = styled.div`
  display: flex;
  gap: 5px;
`;
const TextPoint = styled.h4`
  font-size: 18px;
`;
const Span = styled.p``;
