import React from "react";
import styled from "styled-components";

import cap from "../../../../../assets/images/destination/icons/cap.svg";
import dot from "../../../../../assets/images/destination/icons/dot.svg";

function CourseContent({ data }) {
  return (
    <Section>
      {data?.map((item) => (
        <Item>
          <Inner>
            <Icon>
              <img src={cap} alt="" />
            </Icon>
            <Value>{item?.title}</Value>
          </Inner>
          {/* <Inner className="inside">
            <Item className="inside">
              <Icon>
                <img src={dot} alt="" />
              </Icon>
              <Value>3 years in England, Wales and Northern Ireland</Value>
            </Item>
          </Inner> */}
        </Item>
      ))}
    </Section>
  );
}

export default CourseContent;

const Section = styled.div`
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap:10px;

  @media screen and (max-width: 768px) {
    padding: 12px 10px;
  }
`;

const Item = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;

  &.inside {
    flex-direction: row;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
`;

const Value = styled.div``;

const Inner = styled.div`
  display: flex;
  gap: 10px;

  &.inside {
    padding-left: 20px;
  }
`;
