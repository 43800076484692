import React from "react";

//packages
import styled from "styled-components";

//imports
import Title from "./titles/Title";
import SpaceText from "./titles/SpaceText";
import SubTitle from "./titles/SubTitle";
import { introduction_migrate } from "../../../../../general/commonArrays";

//images
import bluepoint from "../../../../../../assets/images/migrate/bluepoint.png";

export default function PnpOverview() {
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Title label="Provincial Nominee Program (PNP) Overview" />
          <Description>
            <SpaceText /> The Provincial Nominee Program (PNP) is a Canadian
            immigration pathway managed by provincial and federal governments.
            Established in 1998, it addresses specific labor and demographic
            needs of provinces. Candidates receiving provincial nominations gain
            600 additional Comprehensive Ranking System (CRS) points in the
            Express Entry system, enhancing their chances for permanent
            residence.
          </Description>
          <Subject>
            <Sub>
              <SubTitle label="How PNP Works:" />
              <Description>
                Each province has unique immigration categories aligned with
                Express Entry. The selection process varies, often involving
                draws based on occupation demand. Choosing the right PNP is
                crucial for success, and candidates must meet specific
                eligibility criteria.
              </Description>
            </Sub>
            <Sub>
              <SubTitle label="Application Process:" />
              <Description>
                To migrate through PNP, candidates need a provincial nomination,
                obtained through applying to the chosen province's PNP.
                Application processes differ among PNPs, with some using
                Expression of Interest (EOI) systems. Express Entry-aligned PNPs
                lead to invitations for permanent residence, while non-Express
                Entry streams require direct application.
              </Description>
            </Sub>
            <Sub>
              <SubTitle label="Eligibility and Benefits:" />
              <Description>
                PNP eligibility varies, providing an alternative for those with
                insufficient CRS points. Express Entry-aligned nominees gain an
                extra 600 CRS points, significantly improving their chances.
                Each province, including territories, has its own PNP, each with
                unique criteria.
              </Description>
            </Sub>
          </Subject>
          <Spec>
            <Cover>
              <SubTitle label="List of PNPs:" />
              <Points>
                {introduction_migrate.map((obj, index) => (
                  <CoverPoint key={index}>
                    <ICon>
                      <img src={bluepoint} alt="circle" />
                    </ICon>
                    <CoverTextPoint>
                      <Span>{obj.sub_title} </Span>
                    </CoverTextPoint>
                  </CoverPoint>
                ))}
              </Points>
            </Cover>
            <Cover>
              <SubTitle label="PNP Specifics:" />
              <Points>
                {introduction_migrate.map((obj, index) => (
                  <CoverPoint key={index}>
                    <ICon>
                      <img src={bluepoint} alt="circle" />
                    </ICon>
                    <CoverTextPoint>
                      <Span>{obj.sub_title} </Span>
                    </CoverTextPoint>
                  </CoverPoint>
                ))}
              </Points>
            </Cover>
          </Spec>
        </Content>
      </Wrapper>
    </Container>
  );
}
const Container = styled.div`
  padding: 80px 0;
  @media (max-width: 480px) {
    padding: 40px 0;
  }
`;
const Content = styled.div``;
const Wrapper = styled.div``;
const Description = styled.p`
  color: #2b2b2b;
  line-height: 26px;
  margin-bottom: 20px;
`;
const Subject = styled.div``;
const Sub = styled.div``;
const Spec = styled.div`
  display: flex;
  @media (max-width: 480px) {
    display: block;
  }
`;
const Cover = styled.div`
  width: 50%;
  @media (max-width: 480px) {
    width: 100%;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const Points = styled.div`
  &.flex {
    display: flex;
    flex-wrap: wrap;
  }
`;
const CoverPoint = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  &.flex {
    width: 50%;
  }
  @media (max-width: 480px) {
    align-items: flex-start;
  }
`;
const ICon = styled.div``;
const CoverTextPoint = styled.div`
  display: flex;
  gap: 5px;
`;
const TextPoint = styled.h4`
  font-size: 18px;
`;
const Span = styled.p`
  color: #2b2b2b;
  @media (max-width: 480px) {
    line-height: 26px;
  }
`;
