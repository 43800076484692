import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import styled from "styled-components";
import search from "../../../../../assets/images/courseSearch/icons/searchIcon.svg";
import university from "../../../../../assets/images/courselisting/university.png";
import bg from "../../../../../assets/images/courseSearch/bg.jpg";
import HeaderEdit from "../../includes/HeaderEdit";
import { accountsConfig } from "../../../../../axiosConfig";
import $ from "jquery";
import Nodata from "../../includes/nodata/Nodata";
import FilterModal from "../../../../modals/FilterModal";
import Footer from "../../includes/Footer";
import Checked from "../../../../../assets/images/icons/checked-arrow.svg";
import rightArrow from "../../../../../assets/images/icons/rightArrow.svg";
import upArrow from "../../../../../assets/images/icons/uparrow.svg";
import downArrow from "../../../../../assets/images/icons/downarrow.svg";
import plus from "../../../../../assets/images/icons/plus.svg";
import arrow from "../../../../../assets/images/courseSearch/icons/arrow.svg";
import filterIcon from "../../../../../assets/images/icons/filter.svg";
import CreateAccountModal from "../../../../modals/CreateAccountModal";
import CourseApplyModal from "../../../../modals/CourseApplyModal";
import minus from "../../../../../assets/images/jobs/icons/minus.svg";
import AuthModal from "../../../../modals/AuthModal";
import SectionLoader from "../../../../general/loader/SectionLoader";

// icons
import calendarIcon from '../../../../../assets/icons/calendar.svg'
import study_areaIcon from '../../../../../assets/icons/study_area.svg'
import disciplineIcon from '../../../../../assets/icons/discipline.svg'
import programIcon from '../../../../../assets/icons/program.svg'
import timeIcon from '../../../../../assets/icons/time.svg'
import docsIcon from '../../../../../assets/icons/docs.svg'
import langIcon from '../../../../../assets/icons/lang.svg'

function ExploreCourse() {
  //string
  const [country, setCountry] = useState("");
  const [countryOption, setCountryOption] = useState("");
  const [DurationOption, setDurationOption] = useState("");
  const [duration, setDuration] = useState("");
  const [esl, setEsl] = useState("");
  const [course, setCourse] = useState("");
  const [courseOption, setCourseOption] = useState("");
  const [year, setYear] = useState("");
  const [disciplineArea, setDisciplineArea] = useState([]);
  const [allDisciplineArea, setAllDisciplineArea] = useState([]);

  const [yearOption, setYearOption] = useState("");
  const [intake, setIntake] = useState("");
  const [searchedItems, setSearchedItems] = useState("");
  const [intakeOption, setIntakeOption] = useState("");
  const [requirements, setRequirements] = useState("");
  const [requirementsOption, setRequirementsOption] = useState("");
  const [getProgrammId, setProgrammId] = useState("");
  const [isPlus, setPlus] = useState("");
  const [programName, setProgramName] = useState("");
  const [expanded, setexpanded] = useState(1);
  const [showMore, setshowMore] = useState("");
  // Disicipline Area
  const [selected, setselected] = useState("");

  const [programs, setPrograms] = useState("");

  //arrays
  const [getProgrammes, setProgrammes] = useState([]);
  const [getCourses, setCourses] = useState([]);
  const [getCountryList, setCountryList] = useState([]);
  const [requirementsList, setRequirementList] = useState([]);
  const [getCourseList, setCourseList] = useState([]);
  const [programsList, setProgramsList] = useState([]);

  // Login Modal
  const [loginModal, setLoginModal] = useState(false);

  //bolean
  const [isProgramme, setProgrammFilter] = useState(false);
  const [isClick, setClick] = useState(false);
  const [isFilterModal, setFilterModal] = useState(false);
  const [isDropDown, setDropDown] = useState(false);

  const [paginationData, setpaginationData] = useState({});
  const [page, setpage] = useState(1);
  const [loading, setloading] = useState(true);

  const formRef = useRef(null);

  const targetDivRef = useRef(null);
  const stickBtnRef = useRef(null);

  const location = useLocation();

  // check is course id present in the location path
  const state = useLocation().state;
  const courseId = state?.courseId;

  // course search from header
  const searchParams = new URLSearchParams(location.search);
  const searchValue = searchParams.get("search");
  const programValue = searchParams.get("program");
  const countryValue = searchParams.get("country");
  const intakeValue = searchParams.get("intake");
  const StudyAreaValue = searchParams.get("study_area");
  
  const navigate = useNavigate();

  const controllerRef = useRef();

  useEffect(()=>{
    if (StudyAreaValue){
      setCourse(StudyAreaValue)
      setexpanded(5)
      setshowMore(5)
    }
  },[StudyAreaValue])

  useEffect(() => {
    if (courseId) {
      setCourse(courseId);
      handleExpand(5);
    }
  }, [courseId]);

  useEffect(()=>{
    if (intakeValue){
      setIntake(intakeValue)
      setshowMore(3)
      setexpanded(3)
    }
  },[intakeValue])

  useEffect(()=>{
    if (countryValue){
      setCountry(parseInt(countryValue))
      setshowMore(1)
    }
  },[countryValue])

  const duration_list = [
    { value: "1", label: "0-1" },
    { value: "2", label: "1-2" },
    { value: "3", label: "2-3" },
    { value: "4", label: "3-4" },
    { value: "5", label: "4-5" },
    { value: "6", label: "5-6" },
    { value: "7", label: "6-10" },
  ];

  const esl_list = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  const featuresList = [
    ["Year", "year"],
    ["In Take", "intake"],
    ["Duration", "duration"],
    ["Study Area", "course"],
    ["Discipline Area", "name"],
    ["ESL/ELP Available", "esl"],
    ["Program", "programme"],
    ["Requirements", "requirements"],
  ];

  const intake_list = [
    { value: "january", label: "January" },
    { value: "february", label: "February" },
    { value: "march", label: "March" },
    { value: "april", label: "April" },
    { value: "may", label: "May" },
    { value: "june", label: "June" },
    { value: "july", label: "July" },
    { value: "august", label: "August" },
    { value: "september", label: "September" },
    { value: "october", label: "October" },
    { value: "november", label: "November" },
    { value: "december", label: "December" },
  ];

  const requirements_list = [
    { value: "ielts", label: "IELTS" },
    { value: "ielts", label: "test" },
    { value: "ielts", label: "test1" },
    { value: "ielts", label: "test2" },
    { value: "ielts", label: "test3" },
  ];

  useEffect(() => {
    if (countryOption?.length === 0) {
      fetchOptions();
    }
    if (courseOption?.length === 0) {
      fetchCourse();
    }
    if (yearOption?.length === 0) {
      fetchYear();
    }
    if (intakeOption?.length === 0) {
      fetchIntake();
    }
    if (requirementsOption?.length === 0) {
      fetchRequirements();
    }
    if (DurationOption?.length === 0) {
      fetchDuration();
    }
  }, [
    getCountryList,
    courseOption,
    yearOption,
    intakeOption,
    requirementsOption,
  ]);

  // setting the filter nav as sticky in mobile version
  useEffect(() => {
    if (window.innerWidth <= 580) {
      const checkDivPosition = () => {
        const targetDiv = targetDivRef.current;
        if (!targetDiv) return;

        const rect = targetDiv.getBoundingClientRect();

        // Check if the top of the div is at the top of the viewport
        const isAtTop = rect.top <= 75;

        // Check if the top of the div is at the top of the viewport
        const notIsAtTop = rect.top >= 150;

        // Check if the bottom of the div is at the top of the viewport
        const isAtBottom = rect.bottom <= 0;

        // Check if the bottom of the div is at the top of the viewport
        const isNotAtBottom = rect.bottom >= 0;

        if (stickBtnRef.current) {
          if (notIsAtTop) {
            stickBtnRef.current.classList.remove("fixed");
          }

          if (isAtTop) {
            stickBtnRef.current.classList.add("fixed");
          }

          if (isAtBottom) {
            stickBtnRef.current.classList.add("d-none");
          }
          if (isNotAtBottom) {
            stickBtnRef.current.classList.remove("d-none");
          }
        }
      };

      // Call the function on scroll
      const handleScroll = () => {
        checkDivPosition();
      };

      // Attach scroll event listener
      window.addEventListener("scroll", handleScroll);

      // Initial check when the component mounts
      checkDivPosition();

      // Cleanup: Remove the event listener when the component unmounts
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  // ---------------handleCountryChange ----------------
  const handleCountryChange = (option) => {
    if (option === country) {
      setCountry("");
    } else {
      setCountry(option);
    }
  };

  // ---------------handleYearChange ----------------
  const handleYearChange = (option) => {
    if (option === year) {
      setYear("");
    } else {
      setYear(option);
    }
  };

  // ---------------handleInTakeChange ----------------
  const handleIntakeChange = (option) => {
    if (option === intake) {
      setIntake("");
    } else {
      setIntake(option);
    }
  };

  // --------------- handleDurationChange ----------------
  const handleDurationChange = (option) => {
    if (option === duration) {
      setDuration("");
    } else {
      setDuration(option);
    }
  };

  // --------------- handleStudyAreaChange ----------------
  const handleStudyAreaChange = (option) => {
  
    if (option === course) {
      setCourse("");
    } else {
      setCourse(option);
    }
  };

  // --------------- handleEslChange ----------------
  const handleEslChange = (option) => {
    if (option === esl) {
      setEsl("");
    } else {
      setEsl(option);
    }
  };

  // --------------- handlediscipleAreaItemChange ----------------
  const handlediscipleAreaItemChange = (option) => {
    if (option === selected) {
      setselected("");
    } else {
      setselected(option);
    }
  };

  // handleshow more in filter
  const handleShowMore = (id) => {
    if (showMore === id) {
      setshowMore("");
    } else {
      setshowMore(id);
    }
  };

  // handleExpand
  const handleExpand = (id) => {
    if (id === expanded) {
      setexpanded(0);
    } else {
      setexpanded(id);
    }
  };

  // ----------country---------------
  const fetchOptions = () => {
    const indexToRemove = 8;
    const countryOptions = getCountryList
      ?.filter((item) => item.name !== "Other")
      .map((item) => ({
        value: item.name,
        label: item.name,
        id: item.id,
      }));
    setCountryOption(countryOptions);
  };

  // ----------Course---------------
  const fetchCourse = () => {
    const courseOptions = getCourseList?.map((item) => ({
      value: item.name,
      label: item.name,
      id: item.id,
    }));
    setCourseOption(courseOptions);
  };

  // ----------year---------------
  const fetchYear = () => {
    const currentYear = new Date().getFullYear();

    // Calculate the next 10 years
    const nextTenYears = [];
    for (let i = 0; i < 11; i++) {
      nextTenYears.push(currentYear + i);
    }
    const YearOptions = nextTenYears?.map((item) => ({
      value: item,
      label: item,
    }));
    setYearOption(YearOptions);
  };

  // ----------duartion---------------
  const fetchDuration = () => {
    const DurationOptions = duration_list?.map((item) => ({
      value: item.value,
      label: item.label,
      id: item.id,
    }));
    setDurationOption(DurationOptions);
  };

  // ----------intake---------------
  const fetchIntake = () => {
    const IntakeOptions = intake_list?.map((item) => ({
      value: item.value,
      label: item.label,
      id: item.id,
    }));
    setIntakeOption(IntakeOptions);
  };

  // ----------requirements---------------
  const fetchRequirements = () => {
    const requirementOptions = requirements_list?.map((item) => ({
      value: item.value,
      label: item.label,
      id: item.id,
    }));
    setRequirementsOption(requirementOptions);
  };

  // ----------intake---------------
  const fetchDisciplineArea = () => {
    const IntakeOptions = intake_list?.map((item) => ({
      value: item.value,
      label: item.label,
      id: item.id,
    }));
    setIntakeOption(IntakeOptions);
  };

  // ----------addingProgrameToArray---------------
  const AddProgramToArray = (item) => {
    if (programs === item) {
      setPrograms("");
    } else {
      setPrograms(item);
    }
  };
  // ----------AddRequirementsToArray---------------
  const AddRequirementsToArray = (item) => {
    if (requirements.includes(item)) {
      setRequirements((prev) => prev.filter((obj) => obj !== item));
    } else {
      setRequirements([...requirements, item]);
    }
  };

  const optionsCountry = countryOption;
  const optionsCourse = courseOption;
  const optionsYear = yearOption;
  const optionsIntake = intakeOption;
  const optionsRequirements = requirementsOption;
  const optionsDuration = DurationOption;

  useEffect(() => {
    if (isProgramme) {
      $("html").addClass("modal-enabled");
    } else {
      $("html").removeClass("modal-enabled");
    }
  }, [isProgramme]);
  // ----------Api's-------------------------

  //courses filter list api
  useEffect(() => {
    accountsConfig.get("study/get-programme/", {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setProgrammes(Data);
      } else {
      }
    });
  }, []);

  //programmes filter course list api
  useEffect(() => {
    getProgrammId &&
      accountsConfig
        .get(`study/get-sub-courses/${getProgrammId}/`, {})
        .then((res) => {
          const StatusCode = res.data.app_data.StatusCode;
          const Data = res.data.app_data.data;
          if (StatusCode == 6000) {
            setCourses(Data);
          } else {
            setCourses([]);
          }
        });
  }, [getProgrammId]);

  //get countries
  useEffect(() => {
    accountsConfig.get(`study/get-countries/`, {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setCountryList(Data);
      } else {
        setCountryList([]);
      }
    });
  }, [country]);

  //get courses
  useEffect(() => {
    accountsConfig.get(`study/get-courses/`, {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setCourseList(Data);
      } else {
        setCourseList([]);
      }
    });
  }, []);

  //get discipleArea Data
  useEffect(() => {
    if (course) {
      accountsConfig
        .get(`study/get-sub-course-of-course/${course}`, {})
        .then((res) => {
          const StatusCode = res.data.app_data.StatusCode;
          const Data = res.data.app_data.data;
          if (StatusCode == 6000) {
            
            setDisciplineArea(Data);
          } else {
            setDisciplineArea([]);
          }
        });
    } else {
      setDisciplineArea([]);
    }
  }, [course]);

  //get requirements
  useEffect(() => {
    accountsConfig.get(`study/list-requirements/`, {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setRequirementList(Data);
      } else {
        setRequirementList([]);
      }
    });
  }, []);

  //get programs
  useEffect(() => {
    accountsConfig.get(`study/get-programme/`, {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        
        setProgramsList(Data);
      } else {
        setProgramsList([]);
      }
    });
  }, []);

  //get all discipleArea
  useEffect(() => {
    accountsConfig.get(`study/sub-courses/`, {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setAllDisciplineArea(Data);
      } else {
        setAllDisciplineArea([]);
      }
    });
  }, []);

  //clear filter
  const clearFunction = () => {
    setCountry(null);
    setCourse(null);
    setYear(null);
    setIntake(null);
    // setRequirements(null);
  };

  const uniqueArray = [...new Set(isPlus)];
  const uniqueNameArray = [...new Set(requirements)];

  //courses filter list api
  useEffect(() => {
    assignHandler();
  },[
    country,
    course,
    year,
    intake,
    requirements,
    selected,
    programs,
    esl,
    page,
    searchedItems,
  ]);

  // course search from header
  useEffect(() => {
    if (programValue) {
      setPrograms(programValue);
      handleExpand(8);
    }

    if (searchValue) {
      setSearchedItems(searchValue);
    }
  }, [searchValue, programValue]);

  // -----------------Enquiry-----------------------
  const formData = new FormData();
  if (country) {
    formData.append("country_id", country);
  }
  if (course) {
    formData.append("course", course);
  }
  if (duration) {
    formData.append("year", year);
  }
  if (intake) {
    formData.append("intake", intake);
  }
  if (selected) {
    formData.append("sub_course", selected);
  }
  if (esl) {
    formData.append("esl_value", esl);
  }
  if (requirements.length > 0) {
    requirements.map((item) => {
      formData.append("requirements", item);
    });
  }
  if (programs) {
    formData.append("programmes", programs);
  }
  if (searchedItems) {
    formData.append("q", searchedItems);
  }

  const assignHandler = (e) => {
    setloading(true);
    if(controllerRef.current){
       controllerRef.current.abort()
    }
    
    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal

    accountsConfig
      .post("study/filter-sub-courses/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          page: page,
        },
        signal:signal
      })
      .then((response) => {
        const data = response.data.app_data.data;
        const pagination_data = response.data.app_data.pagination_data;
        setloading(false);
        if (response.data.app_data.StatusCode === 6000) {
          setCourses(data);
          setProgramName("");
          setProgrammId("");
          setpaginationData(pagination_data);
        } else {
          setCourses([]);
        }
      })
      .catch((error) => {});
  };

  const addItem = (item) => {
    setPlus([...isPlus, item.id]);
    setRequirements([...requirements, item]);
  };

  // clear all applied filters
  const clearAll = () => {
    formRef.current.reset();
    setCountry("");
    setCourse("");
    setYear("");
    setIntake("");
    setRequirements("");
    setselected("");
    setPrograms("");
    setEsl("");
  };

  // set page for pagination
  const setPageForPagination = (pageNum) => {
    setpage(pageNum);
  };

  //----------OutsideClick----------------
  function useOutsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropDown(false);
          // setIsProfile(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);

  const messageRef = useRef();
  const scrollToBottom = () => {
    messageRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [isClick]);

  return (
    <Container>
      <HeaderEdit backgroundCl={"transparent"} />
      <Content>
        <Banner>
          <BannerInner>
            <Heading>Explore Your Global Education Opportunities</Heading>
            <SubHeading>Explore Your Global Education Opportunities</SubHeading>
            <SearchBar>
              <CoverIcon
                onClick={(e) => {
                  scrollToBottom(e);
                  setClick(!isClick);
                }}
              >
                <Icon>
                  <img src={search} alt="search-icon" />
                </Icon>
              </CoverIcon>
              <SearchInput
                type="text"
                placeholder="Search your course or university..."
                value={searchedItems}
                onChange={(e) => setSearchedItems(e.target.value)}
              />
            </SearchBar>
          </BannerInner>
        </Banner>
        <Showing>
          <Wrapper>
            <CourseSection>
              <SelectCourse ref={messageRef}>
                <TopBar>
                  <TopBarTitle>Filter</TopBarTitle>
                  <TopBarTitle className="clear" onClick={() => clearAll()}>
                    Clear
                  </TopBarTitle>
                </TopBar>
                <FilterOptions ref={formRef}>
                  {/* Country */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(1)}>
                        <FilterName>Country</FilterName>
                        <ArrowImg onClick={() => handleExpand(1)}>
                          <img
                            src={expanded === 1 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 1 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {countryOption &&
                              showMore !== 1 &&
                              countryOption?.length > 5 &&
                              countryOption?.map((item, indx) => {
                                if (indx < 5 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="country"
                                        value={item.id}
                                        onChange={(e) =>
                                          handleCountryChange(e.target.value)
                                        }
                                        checked={
                                          parseInt(country) ===
                                          parseInt(item.id)
                                        }
                                      />
                                      <DropDownItemName>
                                        {item.value}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(1)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {countryOption &&
                              (countryOption?.length < 5 || showMore === 1) &&
                              countryOption?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="country"
                                    value={item.id}
                                    onChange={(e) =>
                                      handleCountryChange(e.target.value)
                                    }
                                    checked={
                                      parseInt(country) === parseInt(item.id)
                                    }
                                  />
                                  <DropDownItemName>
                                    {item.value}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}
                            {showMore === 1 && (
                              <ShowMore onClick={() => handleShowMore(1)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* Year */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(2)}>
                        <FilterName>Year</FilterName>
                        <ArrowImg onClick={() => handleExpand(2)}>
                          <img
                            src={expanded === 2 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 2 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {yearOption &&
                              showMore !== 2 &&
                              yearOption?.length > 5 &&
                              yearOption?.map((item, indx) => {
                                if (indx < 5 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="year"
                                        value={item.value}
                                        onChange={(e) =>
                                          handleYearChange(e.target.value)
                                        }
                                      />
                                      <DropDownItemName>
                                        {item.value}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(2)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {yearOption &&
                              (yearOption?.length < 5 || showMore === 2) &&
                              yearOption?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="year"
                                    value={item.value}
                                    onChange={(e) =>
                                      handleYearChange(e.target.value)
                                    }
                                  />
                                  <DropDownItemName>
                                    {item.value}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}
                            {showMore === 2 && (
                              <ShowMore onClick={() => handleShowMore(2)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* Intake */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(3)}>
                        <FilterName>Intake</FilterName>
                        <ArrowImg onClick={() => handleExpand(3)}>
                          <img
                            src={expanded === 3 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 3 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {intakeOption &&
                              showMore !== 3 &&
                              intakeOption?.length > 5 &&
                              intakeOption?.map((item, indx) => {
                                if (indx < 5 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="intake"
                                        value={item.value}
                                        onChange={(e) =>
                                          handleIntakeChange(e.target.value)
                                        }
                                        checked={
                                          String(intake) === String(item.value)
                                        }
                                      />
                                      <DropDownItemName>
                                        {item.label}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(3)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {intakeOption &&
                              (intakeOption?.length < 5 || showMore === 3) &&
                              intakeOption?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="intake"
                                    value={item.value}
                                    onChange={(e) =>
                                      handleIntakeChange(e.target.value)
                                    }
                                    checked={
                                      String(intake) === String(item.value)
                                    }
                                  />
                                  <DropDownItemName>
                                    {item.label}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}
                            {showMore === 3 && (
                              <ShowMore onClick={() => handleShowMore(3)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* Duration */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(4)}>
                        <FilterName>Duration</FilterName>
                        <ArrowImg onClick={() => handleExpand(4)}>
                          <img
                            src={expanded === 4 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 4 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {DurationOption &&
                              showMore !== 4 &&
                              DurationOption?.length > 5 &&
                              DurationOption?.map((item, indx) => {
                                if (indx < 5 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="duration"
                                        value={item.value}
                                        onChange={(e) =>
                                          handleDurationChange(e.target.value)
                                        }
                                        checked={
                                          String(duration) ===
                                          String(item.value)
                                        }
                                      />
                                      <DropDownItemName>
                                        {item.value}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(4)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {DurationOption &&
                              (DurationOption?.length < 5 || showMore === 4) &&
                              DurationOption?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="duration"
                                    value={item.value}
                                    onChange={(e) =>
                                      handleDurationChange(e.target.value)
                                    }
                                    checked={
                                      String(duration) === String(item.value)
                                    }
                                  />
                                  <DropDownItemName>
                                    {item.value}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}
                            {showMore === 4 && (
                              <ShowMore onClick={() => handleShowMore(4)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* Study Area */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(5)}>
                        <FilterName>Study Area</FilterName>
                        <ArrowImg onClick={() => handleExpand(5)}>
                          <img
                            src={expanded === 5 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 5 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {courseOption &&
                              showMore !== 5 &&
                              courseOption?.length > 5 &&
                              courseOption?.map((item, indx) => {
                                if (indx < 5 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="course"
                                        value={item.id}
                                        onChange={(e) =>
                                          handleStudyAreaChange(e.target.value)
                                        }
                                        checked={course === item.id}
                                      />
                                      <DropDownItemName>
                                        {item.value}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(5)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {courseOption &&
                              (courseOption?.length <= 5 || showMore === 5) &&
                              courseOption?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="course"
                                    value={item.id}
                                    onChange={(e) =>
                                      handleStudyAreaChange(e.target.value)
                                    }
                                    checked={course === item.id}
                                  />
                                  <DropDownItemName>
                                    {item.value}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}

                            {showMore === 5 && (
                              <ShowMore onClick={() => handleShowMore(5)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* Discipline Area */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(6)}>
                        <FilterName>Discipline Area</FilterName>
                        <ArrowImg onClick={() => handleExpand(6)}>
                          <img
                            src={expanded === 6 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 6 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {disciplineArea &&
                              showMore !== 6 &&
                              disciplineArea?.length > 6 &&
                              disciplineArea?.map((item, indx) => {
                                if (indx < 6 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="disciplinearea"
                                        value={item.value}
                                        onChange={() =>
                                          handlediscipleAreaItemChange(
                                            item.value
                                          )
                                        }
                                        checked={
                                          String(selected) ===
                                          String(item.value)
                                        }
                                      />
                                      <DropDownItemName>
                                        {item.value}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(6)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {disciplineArea &&
                              (disciplineArea?.length <= 6 || showMore === 6) &&
                              disciplineArea?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="disciplinearea"
                                    value={item.id}
                                    onChange={() =>
                                      handlediscipleAreaItemChange(item.value)
                                    }
                                    checked={
                                      String(selected) === String(item.value)
                                    }
                                  />
                                  <DropDownItemName>
                                    {item.name}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}

                            {showMore === 6 && (
                              <ShowMore onClick={() => handleShowMore(6)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}

                            {/* List All disciplineArea */}
                            {disciplineArea?.length === 0 &&
                              showMore !== 11 &&
                              allDisciplineArea?.length > 11 &&
                              allDisciplineArea?.map((item, indx) => {
                                if (indx < 5 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="disciplinearea"
                                        value={item.name}
                                        onChange={() =>
                                          handlediscipleAreaItemChange(
                                            item.name
                                          )
                                        }
                                        checked={
                                          String(selected) === String(item.name)
                                        }
                                      />
                                      <DropDownItemName>
                                        {item.name}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore
                                      onClick={() => handleShowMore(11)}
                                    >
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {disciplineArea?.length === 0 &&
                              (allDisciplineArea?.length <= 6 ||
                                showMore === 11) &&
                              allDisciplineArea?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="disciplinearea"
                                    value={item.name}
                                    onChange={() =>
                                      handlediscipleAreaItemChange(item.name)
                                    }
                                    checked={
                                      String(selected) === String(item.name)
                                    }
                                  />
                                  <DropDownItemName>
                                    {item.name}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}

                            {showMore === 11 && (
                              <ShowMore onClick={() => handleShowMore(11)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* ESL/ELP Available */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(7)}>
                        <FilterName>ESL/ELP Available</FilterName>
                        <ArrowImg onClick={() => handleExpand(7)}>
                          <img
                            src={expanded === 7 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 7 && (
                        <DropdownContainer>
                          <DropDownItems>
                            <DropDownItem>
                              <Radio
                                type="radio"
                                name="esl"
                                value={"yes"}
                                onChange={(e) =>
                                  handleEslChange(e.target.value)
                                }
                                checked={String(esl) === String("yes")}
                              />
                              <DropDownItemName>YES</DropDownItemName>
                            </DropDownItem>
                            <DropDownItem>
                              <Radio
                                type="radio"
                                name="esl"
                                value={"no"}
                                onChange={(e) =>
                                  handleEslChange(e.target.value)
                                }
                                checked={String(esl) === String("no")}
                              />
                              <DropDownItemName>NO</DropDownItemName>
                            </DropDownItem>
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* Programs */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(8)}>
                        <FilterName>Programs</FilterName>
                        <ArrowImg onClick={() => handleExpand(8)}>
                          <img
                            src={expanded === 8 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 8 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {programsList &&
                              showMore !== 8 &&
                              programsList?.length > 8 &&
                              programsList?.map((item, indx) => {
                                if (indx < 8 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="program"
                                        value={item.id}
                                        onChange={() =>
                                          AddProgramToArray(item.id)
                                        }
                                        checked={programs === item?.id}
                                      />
                                      <DropDownItemName>
                                        {item.name}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(8)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {programsList &&
                              (programsList?.length <= 8 || showMore === 8) &&
                              programsList?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="program"
                                    value={item.id}
                                    onChange={() => AddProgramToArray(item.id)}
                                    checked={
                                      programs.includes(item.id) ? true : false
                                    }
                                  />
                                  <DropDownItemName>
                                    {item.name}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}

                            {showMore === 8 && (
                              <ShowMore onClick={() => handleShowMore(8)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>

                  {/* Requirements */}
                  <Cover>
                    <DropDown>
                      <DropdownTop onClick={() => handleExpand(9)}>
                        <FilterName>Requirements</FilterName>
                        <ArrowImg onClick={() => handleExpand(9)}>
                          <img
                            src={expanded === 9 ? upArrow : downArrow}
                            alt=""
                          />
                        </ArrowImg>
                      </DropdownTop>
                      {expanded === 9 && (
                        <DropdownContainer>
                          <DropDownItems>
                            {/* Listing option if the len < 5 + show more text */}
                            {requirementsList &&
                              showMore !== 9 &&
                              requirementsList?.length > 9 &&
                              requirementsList?.map((item, indx) => {
                                if (indx < 9 && indx !== 4) {
                                  return (
                                    <DropDownItem>
                                      <Radio
                                        type="radio"
                                        name="program"
                                        value={item.id}
                                        onChange={() =>
                                          AddRequirementsToArray(item.id)
                                        }
                                        checked={
                                          programs.includes(item.id)
                                            ? true
                                            : false
                                        }
                                      />
                                      <DropDownItemName>
                                        {item.name}
                                      </DropDownItemName>
                                    </DropDownItem>
                                  );
                                } else if (indx === 4) {
                                  return (
                                    <ShowMore onClick={() => handleShowMore(9)}>
                                      <img src={plus}></img>
                                      <span>Show More</span>
                                    </ShowMore>
                                  );
                                }
                              })}

                            {/* Listing all option and checking the showmore is active  */}
                            {requirementsList &&
                              (requirementsList?.length <= 9 ||
                                showMore === 9) &&
                              requirementsList?.map((item, indx) => (
                                <DropDownItem>
                                  <Radio
                                    type="radio"
                                    name="program"
                                    value={item.id}
                                    onChange={() =>
                                      AddRequirementsToArray(item.id)
                                    }
                                  />
                                  <DropDownItemName>
                                    {item.name}
                                  </DropDownItemName>
                                </DropDownItem>
                              ))}

                            {showMore === 9 && (
                              <ShowMore onClick={() => handleShowMore(9)}>
                                <img src={minus}></img>
                                <span>Show Less</span>
                              </ShowMore>
                            )}
                          </DropDownItems>
                        </DropdownContainer>
                      )}
                    </DropDown>
                  </Cover>
                </FilterOptions>
              </SelectCourse>

              <MobileFilter ref={stickBtnRef}>
                <Filter onClick={() => setFilterModal(true)}>
                  <Arrow>
                    <img src={filterIcon} alt="" />
                  </Arrow>
                  Filter
                </Filter>
              </MobileFilter>

              <ShowingCourses ref={targetDivRef}>
                {searchedItems && (
                  <Head>
                    <SearchedItem>
                      {`results for "${searchedItems}"`}
                    </SearchedItem>
                    <FoundItemCount>
                      ({getCourses?.length} Programs)
                    </FoundItemCount>
                  </Head>
                )}

                <Cards type={getCourses?.length}>
                  {loading ? (
                    <SectionLoader />
                  ) : getCourses.length == 0 ? (
                    <Nodata />
                  ) : (
                    getCourses?.map((x, index) => (
                      <Card>
                        <UniversityInfo>
                          <LogoImg>
                            <img src={x?.image?x?.image:university} alt="" />
                          </LogoImg>
                          <InfoDiv>
                            <Department>{x.course_name}</Department>
                            <NameInfo>{x.university_name}</NameInfo>
                            <CountryInfo>
                              <span>Country : </span>
                              {x.country}
                            </CountryInfo>
                          </InfoDiv>
                        </UniversityInfo>

                        <Features>
                          <List>
                            <SingleItem>
                              {/* <Dot /> */}
                              <img className="calender" src={calendarIcon} alt="" />
                              <FeatureName>
                                <span> Year: </span>
                                {x?.year}
                              </FeatureName>
                            </SingleItem>
                            <SingleItem>
                              {/* <Dot /> */}
                              <img className="calender" src={calendarIcon} alt="" />

                              <FeatureName>
                                <span>In Take: </span>
                                {x?.intake}
                              </FeatureName>
                            </SingleItem>
                            <SingleItem>
                              {/* <Dot /> */}
                                <img className="study_area" src={study_areaIcon} alt="" />
                              <FeatureName>
                                <span>Branch: </span>
                                {x?.course_name}
                              </FeatureName>
                            </SingleItem>
                            <SingleItem>
                              {/* <Dot /> */}
                                <img className="time" src={timeIcon} alt="" />
                              <FeatureName>
                                <span>Duration: </span>
                                {x?.duration}
                              </FeatureName>
                            </SingleItem>
                            <SingleItem>
                                <img className="disciplin" src={disciplineIcon} alt="" />
                              <FeatureName>
                                <span>Course: </span>
                                {x?.name}
                              </FeatureName>
                            </SingleItem>
                            <SingleItem>
                              {/* <Dot /> */}
                                <img className="lang" src={langIcon} alt="" />
                              <FeatureName>
                                <span>ESL/ELP Available: </span>
                                {x?.esl}
                              </FeatureName>
                            </SingleItem>

                            <SingleItem>
                              {/* <Dot /> */}
                                <img className="program" src={programIcon} alt="" />
                              <FeatureName>
                                <span>Program: </span>
                                {x?.programme}
                              </FeatureName>
                            </SingleItem>

                            <SingleItem>
                              {/* <Dot /> */}
                                <img className="docs" src={docsIcon} alt="" />
                              <FeatureName>
                                <span>Requirements: </span>
                                {x?.requirements
                                  ? x?.requirements.map((it, indx) => {
                                      if (x?.requirements?.length > 2) {
                                        if (indx <= 2) {
                                          if (indx === 2) {
                                            return `...`;
                                          } else {
                                            return `${it},`;
                                          }
                                        }
                                      } else {
                                        if (
                                          indx !==
                                          x?.requirements?.length - 1
                                        ) {
                                          return `${it},`;
                                        } else {
                                          return `${it}`;
                                        }
                                      }
                                    })
                                  : "None"}
                              </FeatureName>
                            </SingleItem>
                          </List>
                        </Features>

                        <EnquireBtns>
                          {/* <EnquireBtn>Enquire Now</EnquireBtn> */}
                          <ViewMoreDetails
                            onClick={() => navigate(`/course/${x?.id}`)}
                          >
                            View Course
                            <span>
                              <img src={rightArrow} alt="" />
                            </span>
                          </ViewMoreDetails>
                        </EnquireBtns>
                      </Card>
                    ))
                  )}
                </Cards>
                <Paginator>
                  <PaginatorInner>
                    {paginationData?.has_previous_page && (
                      <LeftArr
                        onClick={() =>
                          setPageForPagination(paginationData?.current_page - 1)
                        }
                      >
                        <img src={arrow} alt="" />
                      </LeftArr>
                    )}
                    {paginationData?.current_page - 1 > 0 && (
                      <Page
                        onClick={() =>
                          setPageForPagination(paginationData?.current_page - 1)
                        }
                      >
                        {paginationData?.current_page - 1}
                      </Page>
                    )}
                    <Page className="active">
                      {paginationData?.current_page}
                    </Page>
                    {paginationData?.current_page + 1 <=
                      paginationData?.total_pages && (
                      <Page
                        onClick={() =>
                          setPageForPagination(paginationData?.current_page + 1)
                        }
                      >
                        {paginationData?.current_page + 1}
                      </Page>
                    )}
                    {paginationData?.has_next_page && <Page>..</Page>}
                    {paginationData?.has_next_page && (
                      <RightArr
                        onClick={() =>
                          setPageForPagination(paginationData?.current_page + 1)
                        }
                      >
                        <img src={arrow} alt="" />
                      </RightArr>
                    )}
                  </PaginatorInner>
                </Paginator>
              </ShowingCourses>
            </CourseSection>
          </Wrapper>
        </Showing>
      </Content>

      <FilterModal
        optionsCountry={optionsCountry}
        optionsCourse={optionsCourse}
        optionsYear={optionsYear}
        optionsIntake={optionsIntake}
        optionsRequirements={optionsRequirements}
        optionsDuration={optionsDuration}
        isModal={isFilterModal}
        esl_list={esl_list}
        country={country}
        year={year}
        course={course}
        intake={intake}
        duration={duration}
        esl={esl}
        handleCountryChange={handleCountryChange}
        handleYearChange={handleYearChange}
        handleStudyAreaChange={handleStudyAreaChange}
        handleIntakeChange={handleIntakeChange}
        handleDurationChange={handleDurationChange}
        handleEslChange={handleEslChange}
        handlediscipleAreaItemChange={handlediscipleAreaItemChange}
        requirements={requirements}
        setRequirements={setRequirements}
        setModal={setFilterModal}
        wrapperRef={wrapperRef}
        addItem={addItem}
        disciplineArea={disciplineArea}
        allDisciplineArea={allDisciplineArea}
        programs={programs}
        programsList={programsList}
        AddProgramToArray={AddProgramToArray}
        requirementsList={requirementsList}
        AddRequirementsToArray={AddRequirementsToArray}
        expanded={expanded}
        handleExpand={handleExpand}
        showMore={showMore}
        countryOption={countryOption}
        handleShowMore={handleShowMore}
        selected={selected}
        courseOption={courseOption}
        DurationOption={DurationOption}
        intakeOption={intakeOption}
        yearOption={yearOption}
        clear={clearAll}
      />

      <Footer />
    </Container>
  );
}

export default ExploreCourse;

const Container = styled.div`
  /* position: relative; */
  background-color: #f8f8f8;
`;
const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const Content = styled.div``;
const Banner = styled.div`
  background: url(${bg});
  height: 550px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  @media all and (max-width: 768px) {
    height: 475px;
  }
  @media all and (max-width: 580px) {
    height: 375px;
  }
`;
const SearchBar = styled.div`
  display: flex;
  width: 50%;

  @media all and (max-width: 1200px) {
    width: 60%;
  }

  @media all and (max-width: 900px) {
    width: 75%;
  }
  @media all and (max-width: 580px) {
    width: 100%;
    height: 50px;
  }
`;
const SearchInput = styled.input`
  border: none;
  width: 100%;
  outline: none;
  &::placeholder {
    color: #c1c1c1;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-family: "ubuntu-regular";

    @media (max-width: 580px) {
      padding-left: 10px;
    }
  }
`;
const Icon = styled.div`
  width: 80%;
  height: 80%;
  transition: 0.4s ease;
  color: #575657;
  &.active {
    transition: 0.4s ease;
    color: #006492;
  }
  img {
    display: block;
    width: 100%;
    margin: 0 auto;
    object-fit: cover;
    width: auto;
  }
`;
const Showing = styled.div`
  padding: 0 0;
  @media all and (max-width: 480px) {
    padding: 0;
    padding-bottom: 50px;
    padding-top: 20px;
  }
`;
const FilterSection = styled.div`
  border-bottom: 1px solid #575657;
  padding-bottom: 30px;
  @media all and (max-width: 480px) {
    display: none;
  }
`;
const InputCards = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  @media all and (max-width: 1080px) {
    flex-wrap: wrap;
  }
`;
const Cover = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #d9d9d9;
`;
// const Select = styled.select`
//   border: 1px solid #006492;
//   height: 40px;
//   background-color: #fff;
//   width: 250px;
//   padding-left: 20px;
//   color: #575657;
//   font-family: "ubuntu-regular";
//   outline: none;
//   @media all and (max-width: 1440px) {
//     width: 200px;
//   }
// `;
const Option = styled.option``;
const Line = styled.div`
  height: 25px;
  background-color: #006492;
  width: 1px;
`;
const FilterName = styled.span`
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const IconBar = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 7px;
  right: 2px;
  background: #ffff;
  width: 40px;
`;
const DropDown = styled.div`
  color: #006492;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const DropdownTop = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const CourseSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 50px;
  margin-top: 50px;
  @media all and (max-width: 1780px) {
    gap: 30px;
  }
  @media all and (max-width: 1080px) {
    flex-wrap: wrap;
  }

  @media (max-width: 580px) {
    margin-top: 0;
    /* position: relative; */
    /* height: 150vh;
    overflow-y: scroll;
    overflow-x: hidden; */

    /* &::-webkit-scrollbar {
      width: 0;
    } */
  }
`;
const SelectCourse = styled.div`
  padding: 10px;
  width: 16%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media all and (max-width: 1080px) {
    width: 100%;
  }
  @media all and (max-width: 480px) {
    display: none;
  }
`;
const Title = styled.h1`
  color: #006492;
  font-size: 26px;
  margin-bottom: 20px;
`;
const Items = styled.div``;
const Item = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  border-bottom: 1px solid #575657;
  padding-bottom: 15px;
  margin-bottom: 20px;
  &:last-child {
    border-bottom: none;
  }
  transition: 0.4s ease;
  &.active {
    border-bottom: 2px solid #006492;
    transition: 0.4s ease;
  }
`;
const Stream = styled.div`
  transition: 0.4s ease;
  color: #575657;
  &.active {
    transition: 0.4s ease;
    color: #006492;
  }
`;
const ShowingCourses = styled.div`
  width: 80%;
  margin-bottom: 25px;
  @media all and (max-width: 1080px) {
    width: 100%;
  }
`;
const Head = styled.div`
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: #fff;
  box-sizing: border-box;

  &::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 5px;
    background-color: #00aec0;
    left: 0;
  }
`;

const FoundItemCount = styled.div`
  color: #8a8a8a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

const SearchedItem = styled.div`
  color: #696969;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

const Available = styled.div`
  display: flex;
  gap: 20px;
`;
const Country = styled.div`
  border: 1px solid #006492;
  padding: 6px 22px;
`;
const Cards = styled.div`
  /* display: grid;
  grid-template-columns: ${({ type }) =>
    type == 0 ? "1fr" : "1fr 1fr 1fr"}; */
  display: grid;
  grid-template-columns: "1fr";
  margin-top: 20px;
  gap: 20px;
  @media all and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
const Card = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 20px 15px;
  display: flex;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
    row-gap: 20px;
  }

  @media (max-width: 800px) {
    padding: 15px 0;
  }

  @media all and (max-width: 768px) {
    width: 100%;
  }

  @media all and (max-width: 675px) {
    flex-direction: column;
  }
`;
const TopSection = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
`;
const Logo = styled.div`
  border: 1px solid #006492;
  display: inline-block;
`;
const CourseName = styled.div``;
const CourseTitle = styled.div`
  margin-bottom: 5px;
  color: #575657;
`;
const UniversityName = styled.h2`
  font-family: "ubuntu-medium";
  margin-bottom: 5px;
  color: #006492;
  letter-spacing: 1px;
  font-size: 16px;
`;
const CountryAvailable = styled.div`
  display: flex;
  align-items: baseline;
`;
const Span = styled.span`
  color: #575657;
  font-size: 13px;
`;
const Coun = styled.div`
  color: #575657;
  font-family: "ubuntu-medium";
  font-size: 15px;
  margin-left: 5px;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Details = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  @media all and (max-width: 768px) {
    gap: 10px;
  }
  @media all and (max-width: 400px) {
    grid: none;
  }
`;
const DetailCard = styled.div`
  display: flex;
  padding: 10px;

  gap: 10px;
  background-color: #fff;
  @media all and (max-width: 400px) {
    /* flex-direction: column; */
    justify-content: space-between;
    padding: 10px 20px;
  }
`;
const LabelSelect = styled.h5`
  color: #575657;
  font-family: "ubuntu-light";
  margin-bottom: 5px;
`;
const Label = styled.h5`
  color: #575657;
  font-size: 12px;
  font-family: "ubuntu-light";
`;
const Button = styled.div`
  background-color: #00b0c2;
  color: #fff;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  @media all and (max-width: 400px) {
    height: 30px;
  }
`;
const Value = styled.div`
  color: #006492;
  font-size: 14px;
  text-transform: capitalize;
  font-family: "ubuntu-medium";
  margin-left: 5px;
`;
const CoverIcon = styled.div`
  height: 55px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
  @media all and (max-width: 768px) {
    height: 40px;
  }

  @media all and (max-width: 580px) {
    height: 100%;
  }
  img {
    height: 100%;
    width: auto;
    object-fit: cover;
  }
`;

const SelectedCourse = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 10px;
  @media all and (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const FilteredCard = styled.div`
  display: flex;
  background-color: #006492;
  color: #fff;
  height: 35px;
  padding: 0 20px;
  /* width: 120px; */
  gap: 20px;
  justify-content: center;
  align-items: center;
`;
const FilteredObject = styled.div``;
const Close = styled.div`
  color: #00b0c2;
  cursor: pointer;
`;
const MobileFilter = styled.div`
  display: none;
  @media all and (max-width: 480px) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    width: 100%;
    padding: 0 20px;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    position: sticky;
    top: 10px;
    z-index: 3;
  }

  &.fixed {
    position: fixed;
    width: 90%;
    top: 90px;
  }

  &.d-none {
    display: none;
  }
`;
const Arrow = styled.div`
  color: #00b0c2;
  font-size: 18px;
  margin-right: 8px;
`;
const Lines = styled.div`
  background: #868686;
  width: 1px;
  height: 30px;
`;
const Filter = styled.div`
  font-size: 15px;
  width: 150px;
  display: flex;
  color: #575657;
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CoverItems = styled.div`
  padding: 0 40px;
  margin-top: 20px;
`;
const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.201);
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgb(0 0 0 / 54%);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
`;
const ProgrammFilter = styled.div`
  display: none;
  @media all and (max-width: 768px) {
    position: fixed;
    bottom: -1000px;
    background-color: #fff;
    width: 100%;
    box-sizing: border-box;
    &.active {
      bottom: 0;
      display: block;
    }
  }
`;
const TopBarFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f4f5;
  padding: 20px 40px;
`;
const TitleBar = styled.div``;
const CloseBar = styled.div`
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
`;
const Multiselect = styled.div`
  display: flex;
  cursor: pointer;
  border: 1px solid #006492;
  color: hsl(0, 0%, 50%);
  height: 38px;
  width: 200px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
`;
const Drop = styled.div`
  border-left: 1px solid #cdcaca;
  padding-left: 10px;
  font-size: 20px;
  color: #a8a7a7;
`;
const SelectDropDown = styled.div`
  position: absolute;
  background-color: #f5f4f5;
  padding: 20px;
  z-index: ${({ type }) => (type ? "1" : "-1")};
  opacity: ${({ type }) => (type ? "1" : "0")};
  width: 100%;
  box-sizing: border-box;
`;
const CoverSelect = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;
const SelectItem = styled.div`
  color: #006492;
`;
const Plus = styled.div`
  color: #006492;
  background-color: #fff;
  border-radius: 50%;
  width: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
`;
const FilterButton = styled.div`
  background: #00b0c2;
  border: none;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  padding: 5px 20px;
  border-radius: 4px;
  display: inline-block;
  margin-left: auto;
`;

const FilterOptions = styled.form`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 25px;
`;

const ProgramesDropdown = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px solid black;
  padding-bottom: 25px;
`;

const RequirementsDropdown = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 15px;
`;

const CountryList = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

const CountryName = styled.div`
  padding: 15px 25px;
  border: 1px solid var(--darkblue);
  font-family: "ubuntu-medium";

  &.active {
    background-color: var(--darkblue);
    color: #fff;
  }
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90%;
  padding: 0 8px;
`;

const DropdownInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

const Name = styled.span`
  font-size: 18px;
  font-family: "ubuntu-medium";
  color: var(--darkblue);
`;

const Indicator = styled.div`
  img {
    width: 20px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DropDownItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const DropDownItem = styled.div`
  display: flex;
  width: 100%;
  gap: 5px;
  align-items: center;
`;

const DropDownItemName = styled.p`
  color: #565757;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const ItemName = styled.div`
  font-size: 15px;
  font-family: "ubuntu-regular";
  color: #5f5f5f;
  line-height: 20px;
`;

const Checkbox = styled.div`
  position: relative;
  input {
    opacity: 0;
  }

  label {
    position: absolute;
    width: 18px;
    height: 18px;
    inset: 0;
    border: 1px solid var(--darkblue);

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url(${Checked});
      background-size: cover; /* Adjust as needed */
      background-position: center; /* Adjust as needed */
      display: none;
      z-index: 5;
    }
  }

  input:checked + label::after {
    display: block;
  }
  input:checked + label {
    border: none;
  }
`;

const SelectContainer = styled.div`
  position: relative;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
  }
`;

const CustomDropdown = styled.div`
  background-color: #fff;
  padding: 15px;
  position: absolute;
  height: 250px;
  width: 375px;
  top: 0;
  z-index: 5;
  box-shadow: 2px -2px 8px -3px rgba(162, 162, 162, 0.75);
  -webkit-box-shadow: 2px -2px 8px -3px rgba(162, 162, 162, 0.75);
  -moz-box-shadow: 2px -2px 8px -3px rgba(162, 162, 162, 0.75);
  position: relative;
`;

const CloseBtn = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;

  img {
    width: 20px;
    display: block;
  }
`;

const Layer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 92%;
  margin: 0 auto;
  overflow: hidden;
  height: 100%;
`;

const CourseInfo = styled.div`
  color: var(--darkblue);
  font-size: 16px;
  font-family: "ubuntu-regular";
  border-bottom: 1px solid black;
  padding: 10px 0 15px;
`;

const OptionList = styled.div`
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &::-webkit-scrollbar {
    width: 4px; /* Width of the scrollbar thumb */
  }

  &::-webkit-scrollbar-track {
    background-color: #bdbdbdb6;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--darkblue);
    opacity: 0.7;
    width: 1px; /* Width of the scrollbar track */
  }
`;

const ListItem = styled.div`
  font-family: "ubuntu-regular";
  font-size: 16px;
  cursor: pointer;
  padding: 4px;

  &.active {
    background-color: var(--darkblue);
    color: #fff;
  }
`;

const UniversityInfo = styled.div`
  flex: 1.5;
  display: flex;
  border-right: 1px solid #d9d9d9;

  @media (max-width: 1200px) {
    width: 44%;
    flex: unset;
  }

  @media (max-width: 800px) {
    width: 40%;
  }

  @media (max-width: 675px) {
    width: 100%;
    border-right: none;
  }
`;

const Features = styled.div`
  flex: 2;
  border-right: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;

  @media (max-width: 1200px) {
    width: 44%;
    flex: unset;
    border: none;
  }

  @media (max-width: 800px) {
    padding: 0 10px;
    width: 56%;
  }

  @media (max-width: 675px) {
    width: 100%;
  }
`;

const EnquireBtns = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 1200px) {
    width: 90%;
    flex: unset;
    flex-direction: row;
    justify-content: flex-end;
    /* border-top: 1px solid #d9d9d9; */
    padding: 10px 0;
  }
`;

const LogoImg = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 90px;
    height: 90px;
  }
`;

const InfoDiv = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
`;

const Department = styled.div`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  @media (max-width: 675px) {
    text-align: center;
  }
`;

const NameInfo = styled.div`
  color: #3f3f3f;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  font-family: "ubuntu-medium";

  @media (max-width: 675px) {
    text-align: center;
  }
`;

const CountryInfo = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  span {
    color: #00aec0;
  }

  @media (max-width: 675px) {
    text-align: center;
  }
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 5px;

  @media (max-width: 675px) {
    width: 90%;
    margin: 0 auto;
  }

  @media (max-width: 550px) {
    width: 100%;
    margin: 0 auto;
  }
`;

const SingleItem = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 48%;

  @media (max-width: 675px) {
    justify-content: center;
  }
  img{
    width: 14px;
  }
 .calender{
    width: 20px;
    margin-left: -3px;
  }
  .docs{
    width: 12px;
  }
  .disciplin{
    margin-left: -2px;
    width: 16px;
  }
  /*  .study_area{
    width: 15px;
  }
  .time{
    width: 15px;
  }
  .disciplin{
    width: 15px;
  }
  .lang{
    width: 20px;
  }
  .program{
    width: 15px;
  }
  .docs{
    width: 12px;
  } */
`;

const Dot = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border-color: #00aec0;
  border: 1px solid #00aec0;
`;

const FeatureName = styled.p`
  color: #909090;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  width: 100%;
  span {
    color: #565757;
    font-family: "ubuntu-regular";
  }

  @media (max-width: 675px) {
    width: 90%;
  }
`;

const EnquireBtn = styled.div`
  color: #00aec0;
  padding: 8px 0;
  border-bottom: 1px solid #00aec0;
  cursor: pointer;
`;

const ViewMoreDetails = styled.button`
  background-color: #00aec0;
  border: none;
  outline: none;
  padding: 8px 16px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
`;
const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  padding: 5px 10px;
  background-color: #00aec0;
  border-radius: 4px;
`;

const TopBarTitle = styled.div`
  color: var(--medcity-a-6-c-1-d-web-app-dove-gray, #696969);
  font-size: 16px;
  color: white;
  /* font-style: normal; */
  font-family: 'ubuntu-bold';
  /* font-weight: 400; */
  /* line-height: normal; */

  &.clear {
    cursor: pointer;
  }
`;

const Radio = styled.input`
  width: 13px;
  height: 13px;
`;

const ArrowImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 100%;
    display: block;
    height: auto;
  }
`;

const ShowMore = styled.div`
  display: flex;
  gap: 10px;
  margin: 5px 0;
  cursor: pointer;

  span {
    color: #00aec0;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
`;

const BannerInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 85%;
  margin: 0 auto;
  margin-top: 5%;

  @media (max-width: 580px) {
    width: 80%;
    margin-top: 20%;
    gap: 20px;
  }

  @media (max-width: 425px) {
    width: 90%;
  }
`;

const Heading = styled.h2`
  color: #fff;
  font-size: 39px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 768px) {
    font-size: 32px;
  }

  @media (max-width: 580px) {
    font-size: 24px;
  }
`;

const SubHeading = styled.p`
  color: #f8f8f8;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
`;

const Paginator = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PaginatorInner = styled.div`
  display: flex;
  gap: 10px;
  margin: 30px auto;
`;

const LeftArr = styled.div`
  width: 23px;
  height: 23px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #fff;
  border: 0.5px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    display: block;
    width: auto;
    object-fit: contain;
  }
`;

const RightArr = styled.div`
  width: 23px;
  height: 23px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #fff;
  border: 0.5px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    display: block;
    width: auto;
    object-fit: contain;
    transform: rotate(180deg);
  }
`;

const Page = styled.div`
  width: 23px;
  height: 23px;
  flex-shrink: 0;
  border-radius: 50%;
  color: #00aec0;
  background-color: #fff;
  border: 0.5px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.active {
    background-color: #00aec0;
    color: white;
  }
`;
