import React, { useEffect, useState } from "react";
import Spotlight from "../includes/Spotlight";
import Migrate from "../includes/Migrate";
import Courses from "../includes/Courses";
import Destinations from "../includes/Destinations";
import Institutes from "../includes/Institutes";
import Form from "../includes/Form";
import Events from "../includes/Events";
import Footer from "../includes/Footer";
import Header from "../includes/Header";
import { Fade } from "react-reveal";
import HeaderEdit from "../includes/HeaderEdit";
import MapDiv from "../includes/MapDiv";
import EnquiryForm from "../../../modals/EnquiryForm";
import { Outlet } from "react-router-dom";
import FeaturesSection from "../includes/Home/FeaturesSection";
import SearchBar from "../includes/SearchBar";
import DreamDestinations from "../includes/DreamDestinations";
import WhyMedcity from "../includes/WhyMedcity";
import Testimonials from "../includes/Testimonials";

function LandingPage() {
  const [isModal, setModal] = useState(true);
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setModal(true);
  //   }, 5000);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);
  return (
    <>
      <HeaderEdit backgroundCl={'transparent'} />
      <Spotlight />
      <Fade bottom>
      <SearchBar />
      </Fade>
      <Fade bottom>
        <Migrate />
      </Fade>
      {/* <Fade bottom> */}
      <FeaturesSection />
      {/* </Fade> */}
      <Fade bottom>
        <Form />
      </Fade>
      <Fade bottom>
        <DreamDestinations />
      </Fade>
      <Fade bottom>
        <WhyMedcity />
      </Fade>
      <Fade bottom>
        <Testimonials />
      </Fade>
      <Fade bottom >
        <Events />
      </Fade>
      <Fade bottom>
        <Footer />
      </Fade>
      {/* <EnquiryForm isModal={isModal} setModal={setModal} /> */}
      <Outlet />
    </>
  );
}

export default LandingPage;
