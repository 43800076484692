import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { accountsConfig } from '../../../../axiosConfig';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Link } from 'react-router-dom';
import arrow_botton from '../../../../assets/icons/arrow_botton-white.svg'
function Testimonials() {
  const [getTestimonials, setTestimonials] = useState([]);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  useEffect(() => {
    accountsConfig.get("study/list-testimonial/").then((res) => {
      const data = res.data.app_data.data;
      if (res.data.app_data.StatusCode === 6000) {
        setTestimonials(data);
      } else {
        setTestimonials([]);
      }
    });
  }, []);
  return (
    <Section>
      <Wrapper className="wrapper">
        <Title>Testimonials</Title>
        <Swiper
          spaceBetween={20}
          slidesPerView={4}
          breakpoints={{
            1024: {
              slidesPerView: 4,
            },
            767: {
              slidesPerView: 2,
            },
            200: {
              slidesPerView: 1,
            },
          }}
        >
        {getTestimonials.map((testi, index) => (
           <SwiperSlide key={index}>
          <Card key={index}>
           {testi?.video&& <video controls playsInline 
            poster={isIOS ? testi?.thumbnail : undefined}>
              <source src={testi.video} type="video/mp4" />
              <source src={testi.video} type="video/webm" />
              <source src={testi.video} type="video/ogg" />
              Your browser does not support the video tag.
            </video>}
          </Card>
          </SwiperSlide>
        ))}
        </Swiper>
        <ViewAll to={'/testimonials'}> <p><span>View All</span> <img src={arrow_botton} alt="" /></p></ViewAll>
      </Wrapper>
    </Section>
  )
}

export default Testimonials

const Section = styled.div`
  background-color: #f8f8f8;
  padding: 50px 0px;
  overflow-x: hidden;
  @media (max-width: 767px) {
    padding: 30px 0px;
  }
  background-color: #1C1F2A;
`;

const Wrapper = styled.div`
  .swiper-container {
    padding-bottom: 20px;
  }
`;

const Title = styled.h1`
  color: white;
  padding-bottom: 30px;
  @media (max-width: 767px) {
    font-size: 20px;
  }
`;

const Card = styled.div`
  border-radius: 20px;
  video{
    width: 100%;
    height: 500px;
    border-radius: 20px;
  }
`;

const ViewAll = styled(Link)`
  margin-top: 30px;
  text-align: center;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  p{
    font-family: 'ubuntu-bold';
    color: #1f93b0;
    cursor: pointer;
    border: 1px solid #1f93b0;
    padding: 10px 20px;
    border-radius: 20px;
  }
  img{
    width: 13px;
  }
`