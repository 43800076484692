import React, { useState } from "react";
import styled from "styled-components";

function CustomSelect({ placeholder, values,value,setvalue }) {
  // const [value, setvalue] = useState(placeholder);
  const [clicked, setclicked] = useState(false);

  const handleClickedChange = () => {
    setclicked(!clicked);
  };

  const handleClick = (item) => {
    if (item === value) {
      setvalue(placeholder);
    } else {
      setvalue(item);
    }
    handleClickedChange()
  };

  const values_list = values
  
  return (
    <Wrapper>
      <SelectedValue onClick={handleClickedChange}>{value || placeholder}</SelectedValue>
      {clicked && (
        <DropDown>
          <DropDownWrapper>
            {values_list.map((item) => (
              <Value onClick={()=>handleClick(item)} className={value === item && 'active'}>{item}</Value>
            ))}
          </DropDownWrapper>
        </DropDown>
      )}
    </Wrapper>
  );
}

export default CustomSelect;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
`;

const SelectedValue = styled.div`
  cursor: pointer;
  min-height: 20px;
  color: #abafb1;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-regular";
  padding: 0;
`;

const DropDown = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
`;

const DropDownWrapper = styled.div`
  max-height: 100px;
  overflow-x: hidden;
  position: relative;
  z-index: 2;
  background-color: #fcfcfc;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const Value = styled.div`
  cursor: pointer;
  font-size: 14px;
  padding: 3px;
  box-sizing: border-box;

  &:hover {
    background-color: #f6f6f6;
  }

  &.active {
    background-color: #f6f6f6;
  }
`;
