import { styled } from "styled-components";
import HeaderEdit from "../includes/HeaderEdit";
import Footer from "../includes/Footer";
import Swal from "sweetalert2";

import bg from "../../../../assets/images/Book/bg.png";
import downArrow from "../../../../assets/images/careers/icons/down_arrow.svg";
import pdf from "../../../../assets/images/careers/icons/pdf.svg";
import { useEffect, useRef, useState } from "react";
import { accountsConfig } from "../../../../axiosConfig";
import ButtonLoader from "../../../general/loader/ButtonLoader";

function BookAppointment() {
  const [branches, setbranches] = useState([]);
  const [loader, setloader] = useState(false);
  const formRef = useRef();

  const timeSlots = [
    "9-10am",
    "10-11am",
    "11-12pm",
    "12-1pm",
    "1-2pm",
    "2-3pm",
    "3-4pm",
    "4-5pm",
  ];

  useEffect(() => {
    accountsConfig.get("general/list-our-branch/").then((res) => {
      const { data, StatusCode } = res.data.app_data;

      if (StatusCode === 6000) {
        setbranches(data);
      } else {
        setbranches([]);
      }
    });
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);

    setloader(true);

    accountsConfig.post("general/book-appoinment/", formData).then((res) => {
      const { data, StatusCode } = res.data.app_data;
      setloader(false);
      if (StatusCode === 6000) {
        showSuccessAlert();
        e.target.reset();
      } else {
        showErrorAlert();
      }
    });
  };

  //sweet alert for success modal
  const showSuccessAlert = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Success",
      showConfirmButton: false,
      html: "<p>Submitted successfully</p>",
      timer: 2500,
      iconColor: "#1eb300",
    });
  };

  //sweet alert for error modal
  const showErrorAlert = () => {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Something went wrong",
      showConfirmButton: false,
      html: "<p>Something went wrong</p>",
      timer: 2500,
      iconColor: "#ff0000",
    });
  };

  return (
    <Section>
      <HeaderEdit backgroundCl={"rgba(0, 174, 192, 1)"} />
      <Container>
        <Banner></Banner>
        <Wrapper className="wrapper">
          <FormDiv>
            <Title className="fs-35">Book An Appointment</Title>
            <Form onSubmit={(e) => handleFormSubmit(e)} ref={formRef}>
              <InputsDiv>
                <InputCover className="name">
                  <Label>Name</Label>
                  <Input
                    placeholder="Your name"
                    type="text"
                    name="name"
                    required
                  />
                </InputCover>

                <InputCover className="phone">
                  <Label>Phone Number</Label>
                  <Input
                    placeholder="Your number"
                    type="number"
                    name="phone"
                    required
                  />
                </InputCover>

                <InputCover className="email">
                  <Label htmlFor="email">Email</Label>
                  <Input
                    id="email"
                    placeholder="Email"
                    name="email"
                    type="email"
                    required
                  />
                </InputCover>

                <InputCover className="place">
                  <Label htmlFor="place">Place</Label>
                  <Input
                    id="place"
                    placeholder="Your city"
                    name="place"
                    type="text"
                    required
                  />
                </InputCover>

                <InputCover className="purpose">
                  <Label htmlFor="purpose">Purpose</Label>
                  <Input
                    id="purpose"
                    placeholder="Your purpose"
                    name="purpose"
                    type="text"
                    required
                  />
                </InputCover>

                <InputCover className="time-slot">
                  <Label htmlFor="time-slot" className="role-select">
                    Time slot
                  </Label>
                  <Select name="time_slot" required>
                    <Option selected disabled>
                      Select a slot
                    </Option>
                    {timeSlots.map((item) => (
                      <Option value={item}>{item}</Option>
                    ))}
                  </Select>
                </InputCover>

                <InputCover className="branch">
                  <Label
                    htmlFor="branch"
                    className="branch"
                    className="role-select"
                  >
                    Branch
                  </Label>
                  <Select name="branch" required>
                    <Option selected disabled>
                      Select your nearest branch
                    </Option>
                    {branches.map((item) => (
                      <Option value={item?.id}>{item?.name}</Option>
                    ))}
                  </Select>
                </InputCover>

                <InputCover className="message">
                  <Label>Message</Label>
                  <TextFeild
                    placeholder="Message"
                    cols={2}
                    rows={5}
                    name="message"
                  />
                </InputCover>
              </InputsDiv>
              {loader ? (
                <SubmiBtn type="button">
                  <ButtonLoader />
                </SubmiBtn>
              ) : (
                <SubmiBtn>Submit</SubmiBtn>
              )}
            </Form>
          </FormDiv>
        </Wrapper>
      </Container>
      <Footer />
    </Section>
  );
}

export default BookAppointment;

const Section = styled.div``;

const Container = styled.div`
  padding: 60px 0px;
  background-color: rgba(248, 248, 248, 1);
`;

const Wrapper = styled.div`
  background-color: rgba(248, 248, 248, 1);

  @media screen and (max-width: 580px) {
    width: 95%;
  }
`;

const Banner = styled.div`
  width: 100%;
  height: 50vh;
  background-image: url(${bg});
  background-size: cover;
  max-height: 400px;
`;

const FormDiv = styled.div`
  margin-top: -100px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  background-color: #fff;
  padding: 20px;

  @media screen and (max-width: 768px) {
    padding: 20px 0px;
    gap: 30px;
  }

  @media screen and (max-width: 580px) {
    gap: 15px;
  }
`;

const Form = styled.form`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 25px;

  @media screen and (max-width: 400px) {
    padding: 25px 15px;
  }
`;

const InputsDiv = styled.div`
  display: grid;
  gap: 24px;
  grid-template-areas:
    "name phone"
    "email place"
    "branch time-slot"
    "purpose  purpose"
    "message  message";

  @media screen and (max-width: 580px) {
    grid-template-areas:
      "name name"
      "phone phone"
      "email email"
      "place place"
      "branch branch"
      "time-slot time-slot"
      "purpose  purpose"
      "message  message";
  }
`;

const InputCover = styled.div`
  border: 1.5px solid #cbcbcb;
  border-radius: 4px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  min-height: 60px;

  &.name {
    grid-area: name;
  }

  &.email {
    grid-area: email;
  }

  &.phone {
    grid-area: phone;
  }

  &.place {
    grid-area: place;
  }

  &.purpose {
    grid-area: purpose;
  }

  &.branch {
    grid-area: branch;
  }

  &.time-slot {
    grid-area: time-slot;
  }

  &.message {
    grid-area: message;
  }
`;

const Heading = styled.div`
  color: #565757;
  font-size: 21px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-medium";
`;

const Label = styled.label`
  color: #5e6366;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  position: relative;
  cursor: pointer;

  &.role-select {
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 0;
      background-image: url(${downArrow});
      background-size: contain;
      width: 25px;
      height: 30px;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  &.cv {
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 0;
      background-image: url(${pdf});
      background-size: contain;
      background-repeat: no-repeat;
      width: 25px;
      height: 30px;
      cursor: pointer;
    }
  }

  &.cv-label {
    color: #abafb1;
    width: max-content;
    border-bottom: 1px solid #abafb1;
    cursor: pointer;
  }
`;
const Input = styled.input`
  border: none;
  outline: none;
  padding: 0;
  &::placeholder {
    color: #abafb1;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    font-family: "ubuntu-regular";
    padding: 0;
  }
`;

const Select = styled.select`
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #abafb1;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-regular";
  position: absolute;
  left: 0;
  right: 0;
  padding-left: 15px;
  padding-top: 25px;
  background-color: transparent;

  option:hover {
    background-color: red;
  }
`;

const Option = styled.option`
  color: #000000;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-regular";
  background-color: #ffffff;
  padding: 20px !important;
`;

const FileInput = styled.input`
  display: none !important;
`;

const SubmiBtn = styled.button`
  width: 20%;
  background-color: #00aec0;
  color: #fff;
  text-align: center;
  font-size: 18px;
  line-height: normal;
  border: none;
  outline: none;
  padding: 14px 10px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 50%;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

const Title = styled.h3`
  text-align: center;
  font-size: 50px;
  font-style: normal;
  line-height: normal;
  color: #1897b2;

  &.fs-35 {
    font-size: 35px;
  }

  @media screen and (max-width: 580px) {
    &.fs-35 {
      font-size: 31px;
    }
  }

  /* @media screen and (max-width: 400px) {
    &.fs-35 {
      font-size: 27px;
    }
  } */
`;

const TextFeild = styled.textarea`
  border: none;
  outline: none;
  padding: 0;
  resize: vertical;
  &::placeholder {
    color: #abafb1;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    font-family: "ubuntu-regular";
    padding: 0;
  }
`;
