import React from "react";
import styled from "styled-components";

export default function SubTitle({ label }) {
  return <Text>{label} </Text>;
}

const Text = styled.h4`
  color: #2b2b2b;
  margin-bottom: 10px;
`;
