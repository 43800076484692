import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import logo from "../../../../assets/images/header/logo.png";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Fade } from "react-reveal";
import { accountsConfig } from "./../../../../axiosConfig";

import arrow from "../../../../assets/images/header/arrow.svg";
import flag from "../../../../assets/images/header/flag.svg";
import search from "../../../../assets/images/icons/search.svg";

import MobileNav from "./MobileNav";
import { StoreContext } from "../../../context/Store";
import { USER_LOGOUT } from "../../../context/types";
import AuthModal from "../../../modals/AuthModal";
import CourseSearchModal from "../../../modals/CourseSearchModal";

function HeaderEdit({ backgroundCl }) {
  const { state, dispatch } = useContext(StoreContext);
  const [isActive, setActive] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [isNav, setNav] = useState(false);
  const [isProfile, setProfile] = useState(false);
  const [studyNav, setStudyNav] = useState(false);
  const [migrate, setMigrate] = useState(false);
  const [hover, setHover] = useState(false);
  const [isLogin, setLogin] = useState(false);
  const [destinations, setDestinations] = useState([]);
  const [showOverlay, setshowOverlay] = useState(false);
  const [courseSearch, setCourseSearch] = useState(false);

  const [backgroundColor, setBackgroundColor] = useState(backgroundCl);

  const path = window.location.pathname;

  const navigate = useNavigate();

  useEffect(() => {
   if(path === '/'){
    setshowOverlay(true);
   }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      // const isVisible = prevScrollPos > currentScrollPos;

      // setPrevScrollPos(currentScrollPos);
      // setIsVisible(isVisible);

      if (currentScrollPos > 75) {
        setBackgroundColor("var(--headerColor)");

        if (backgroundCl === "transparent" && path === '/') {
          setshowOverlay(false);
        }
      } else {
        setBackgroundColor(backgroundCl);

        if (backgroundCl === "transparent" && path === '/') {
          setshowOverlay(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    accountsConfig.get("destinations/get-destinations/").then((response) => {
      const { StatusCode, data } = response.data.app_data;

      if (StatusCode === 6000) {
        setDestinations(data);
      } else {
        setDestinations([]);
      }
    });
  }, []);

  const handleMouseOver = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const handleNavToggle = () => {
    setStudyNav(false);
    setNav(!isNav);
    setMigrate(false)
  };

  const handleStudyNavToggle = () => {
    setNav(false);
    setStudyNav(!studyNav);
    setMigrate(false)
  };
  const MigrateToogle=()=>{
    setMigrate(!migrate)
    setNav(false);
    setStudyNav(false);
  }

  const handleOverLayClick = () => {
    if (studyNav) {
      setStudyNav(false);
    }

    if (isNav) {
      setNav(false);
    }

    if (isProfile) {
      setProfile(false);
    }
  };

  const handleLogout = () => {
    dispatch({ type: USER_LOGOUT, payload: null });
  };

  return (
    <>
      <Container style={{ backgroundColor }} type={path}>
      <Gradient show={showOverlay}></Gradient>
        <Wrapper>
          <Content>
            <LogoSection to="/">
              <img src={logo} alt="" />
            </LogoSection>
            {/* <SearchBar onClick={() => setCourseSearch(true)}>
              <img src={search} alt="" /> <Text>Search courses, jobs, etc</Text>
            </SearchBar> */}
            <ContentSection>
              <CoverSection>
                <NavSection className={isNav && "active"}>
                  <Nav to="/">
                    <span>Home</span>
                  </Nav>
                  <Nav onClick={() => handleStudyNavToggle()}>
                    <span>Study</span>
                    <DropContainer>
                      <i class="ri-arrow-down-s-fill"></i>
                    </DropContainer>
                  </Nav>
                  <Nav to="/find-a-course">
                    <span>Find a Course</span>
                  </Nav>
                  {studyNav && (
                    <DropDown className="study_drop">
                      <Overlay onClick={handleOverLayClick} />
                      <DropdownLeft className={hover && "active"}>
                        {/* <DropDownItem
                          onClick={() => navigate("/find-a-course")}
                        >
                          Find a course
                        </DropDownItem> */}
                        <DropDownItem
                          onMouseOver={handleMouseOver}
                          onMouseLeave={handleMouseLeave}
                          className={`drop ${hover && "hovered"}`}
                        >
                          Destinations
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.843931 9.48691C1.152 9.79668 1.65149 9.79668 1.95956 9.48691L8.42226 2.98865L8.42226 7.37949C8.42226 7.81756 8.77545 8.1727 9.21113 8.17269C9.64681 8.1727 10 7.81756 10 7.37949L10 1.07367C10 0.635596 9.64681 0.280463 9.21113 0.280463L2.9399 0.280468C2.50422 0.280468 2.15103 0.6356 2.15103 1.07368C2.15103 1.51175 2.50422 1.86689 2.9399 1.86689L7.30664 1.86688L0.843931 8.36515C0.535859 8.67491 0.535859 9.17715 0.843931 9.48691Z"
                              fill="#696969"
                            />
                          </svg>
                        </DropDownItem>
                        <DropDownItem onClick={() => navigate("/study-abroad")}>
                          Study Abroad
                        </DropDownItem>
                      </DropdownLeft>
                      <DropdownRight
                        onMouseOver={handleMouseOver}
                        onMouseLeave={handleMouseLeave}
                        className={hover && "active"}
                      >
                        <Title>Choose Your Destination</Title>
                        <Box>
                          {destinations.map((item) => (
                            <Item
                              key={item?.id}
                              onClick={() =>
                                navigate(`/destination/${item?.id}`)
                              }
                            >
                              <Icon className="country_img">
                                <img src={item?.country_image} alt="" />
                              </Icon>
                              <span>{item?.country_name}</span>
                              <Icon className="svg">
                                <svg
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0.843931 9.48691C1.152 9.79668 1.65149 9.79668 1.95956 9.48691L8.42226 2.98865L8.42226 7.37949C8.42226 7.81756 8.77545 8.1727 9.21113 8.17269C9.64681 8.1727 10 7.81756 10 7.37949L10 1.07367C10 0.635596 9.64681 0.280463 9.21113 0.280463L2.9399 0.280468C2.50422 0.280468 2.15103 0.6356 2.15103 1.07368C2.15103 1.51175 2.50422 1.86689 2.9399 1.86689L7.30664 1.86688L0.843931 8.36515C0.535859 8.67491 0.535859 9.17715 0.843931 9.48691Z"
                                    fill="#696969"
                                  />
                                </svg>
                              </Icon>
                            </Item>
                          ))}
                        </Box>
                        <Bottom>
                          Didn’t find your dream destination? Proceed without
                          choosing a country and{" "}
                          <a href="/find-a-course">explore courses now</a>
                        </Bottom>
                      </DropdownRight>
                    </DropDown>
                  )}
                  <Nav to="/jobs">
                    <span>Work</span>
                  </Nav>
                  <Nav onClick={()=>MigrateToogle()}>
                    <span>Migrate</span>
                    <DropContainer className={`${migrate ? "active":""}`}>
                      <i class="ri-arrow-down-s-fill"></i>
                    </DropContainer>
                    </Nav>
                   
                    {migrate && (
                    <DropDown className="migrate">
                      <Overlay onClick={handleOverLayClick} />
                      <DropdownLeft className={hover && "active"}>
                        <DropDownItem onClick={() => navigate("/why-to-canada")}>
                        Migrate to Canada
                        </DropDownItem>
                        <DropDownItem onClick={() => navigate("/why-to-australia")}>
                        Migrate to Australia
                        </DropDownItem>
                      </DropdownLeft>
                    </DropDown>
                  )}
                 
                </NavSection>
                <Arrow onClick={() => handleNavToggle()}>
                  <i class="ri-menu-line"></i>
                  {isNav && (
                    <DropDown className="drop">
                      <Overlay onClick={handleOverLayClick} />
                      <Fade top delay={300}>
                        <Nav className="dropdown first" to="/blogs">
                          <span className="dropdown">Blog</span>
                        </Nav>
                      </Fade>
                      <Fade top delay={220}>
                        <Nav className="dropdown" to="/webinars">
                          <span className="dropdown">Webinars</span>
                        </Nav>
                      </Fade>

                      <Fade top delay={200}>
                        <Nav className="dropdown" to={"/about"}>
                          <span className="dropdown">About Us</span>
                        </Nav>
                      </Fade>
                      <Fade top delay={100}>
                        <Nav className="dropdown" to="/testimonials">
                          <span className="dropdown">Testimonials</span>
                        </Nav>
                      </Fade>
                      <Fade top delay={100}>
                        <Nav className="dropdown" to={"/careers"}>
                          <span className="dropdown">Careers</span>
                        </Nav>
                      </Fade>
                      <Fade top delay={90}>
                        <Nav className="dropdown" to={"/loan-assistance"}>
                          <span className="dropdown">Loan Assistance</span>
                        </Nav>
                      </Fade>
                      <Fade top delay={80}>
                        <Nav className="dropdown last" to={"/contact"}>
                          <span className="dropdown">Contact Us</span>
                        </Nav>
                      </Fade>
                    </DropDown>
                  )}
                </Arrow>
              </CoverSection>
              <CoverRight className={isNav && "active"}>
                {/* <LoginButton>
                  <Button>
                    <i class="ri-phone-fill"></i>
                  </Button>
                  <Button onClick={() => setProfile(!isProfile)}>
                    <i class="ri-user-line"></i>
                  </Button>
                  {isProfile && (
                    <DropDown className="drop_profile">
                      <Overlay onClick={handleOverLayClick} />
                      {state?.is_LoggedIn ? (
                        <Nav
                          className="dropdown"
                          onClick={() => handleLogout()}
                        >
                          <span className="dropdown">Logout</span>
                        </Nav>
                      ) : (
                        <Nav
                          className="dropdown"
                          onClick={() => setLogin(true)}
                        >
                          <span className="dropdown">Login</span>
                        </Nav>
                      )}
                    </DropDown>
                  )}
                </LoginButton> */}
                <Book onClick={() => navigate("/book-appointment")}>
                  <i class="ri-arrow-right-s-fill"></i>
                  <span>Book an Appointment</span>
                </Book>
              </CoverRight>
              <Hamburger onClick={() => setActive(!isActive)}>
                <i class="ri-menu-3-line"></i>
              </Hamburger>
              <HamburgerDrop type="">
                <Nav to={"/about"}>
                  <span>About Us</span>
                  {/* <DropContainer>
                    <i class="ri-arrow-down-s-fill"></i>
                  </DropContainer> */}
                </Nav>
                <Nav>
                  <span>Destinations</span>
                  <DropContainer>
                    <i class="ri-arrow-down-s-fill"></i>
                  </DropContainer>
                </Nav>
                <Nav>
                  <span>Study Abroad</span>
                  <DropContainer>
                    <i class="ri-arrow-down-s-fill"></i>
                  </DropContainer>
                </Nav>
                <Nav to="/find-a-course">
                  <span>Find a Course</span>
                </Nav>
                <Nav to="/testimonials">
                  <span>Testimonials</span>
                </Nav>
                <Nav to="/webinars">
                  <span>Webinars</span>
                </Nav>
                <Nav to="/blogs">
                  <span>Blog</span>
                </Nav>
                <Nav to={"/careers"}>
                  <span>Careers</span>
                </Nav>
                <Nav to={"/jobs"}>
                  <span>Jobs</span>
                </Nav>
                <Nav className="dropdown" to={"/loan-assistance"}>
                  <span>Loan Assistance</span>
                </Nav>
                <Nav to={"/contact"}>
                  <span>Contact Us</span>
                </Nav>
              </HamburgerDrop>
              <MobileNav
                destinations={destinations}
                modal={isActive}
                setmodal={setActive}
              />
            </ContentSection>
          </Content>
        </Wrapper>
      </Container>
      <Outlet />
      <AuthModal modal={isLogin} setModal={setLogin} type={"Login"} />
      <CourseSearchModal modal={courseSearch} setModal={setCourseSearch} />
    </>
  );
}

export default HeaderEdit;

const Container = styled.div`
  padding: 20px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  z-index: 5;
`;

const CoverRight = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  transform: 0.4s ease;
  @media all and (max-width: 1780px) {
    /* max-width: ${({ type }) => (type ? "0" : "100%")}; */
    /* overflow: hidden; */
    /* opacity: ${({ type }) => (type ? "0" : "1")}; */
    transform: 0.4s ease;
  }
`;
const Arrow = styled.div`
  /* margin-left: 10px; */
  font-size: 22px;
  color: #fff;
  position: relative;
  margin-left: 20px;
  cursor: pointer;
`;
const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  position: relative;
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;
const LogoSection = styled(Link)`
  width: 20%;
  img {
    width: 100%;
    display: block;
    max-width:300px;
  }
  @media all and (max-width: 1080px) {
    img{
      max-width:250px;
    }
  }
  @media all and (max-width: 768px) {
    width: 50%;
  }
  @media all and (max-width: 580px) {
    img{
      max-width:175px;
    }
  }

  @media all and (max-width: 480px) {
    width: 74%;
  }

`;
const ContentSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;

  @media all and (max-width: 1150px) {
    width:auto;
  }
`;
const CoverSection = styled.div`
  margin-right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 1400px) {
    gap: 15px;
  }

  @media all and (max-width: 1280px) {
    margin-right: 15px;
  }
  @media all and (max-width: 1150px) {
    display: none;
  }
`;
const Contact = styled.div`
  display: flex;
  justify-content: flex-end;
  /* border-bottom: 3px solid #fff; */
  padding-bottom: 10px;
  @media all and (max-width: 1360px) {
    align-items: center;
  }
`;

const ATag = styled.a`
  font-family: "ubuntu-light";
  margin-right: 20px;
  color: #fff;
  font-family: "ubuntu-medium";
  font-size: 18px;
  span {
    margin-right: 10px;
  }
  i {
    margin-right: 5px;
    color: #00b1c3;
    /* border: 1px solid #cacaca; */
    border-radius: 50%;
    padding: 4px;
    background: #fff;
  }
`;
const NavSection = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  gap: 30px;
  /* max-width: 540px; */
  /* flex-wrap: wrap; */
  transition: 0.8s ease;
  /* overflow: hidden; */
  /* margin-top: 10px; */
  &.active {
    transition: 0.8s ease;
    max-width: 900px;
  }

  @media all and (max-width: 1440px) {
    max-width: 520px;
  }

  @media all and (max-width: 1250px) {
    gap: 15px;
  }
`;
const Nav = styled(Link)`
  margin-right: 20px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  font-family: "ubuntu-light";
  /* transition: 0.3s ease; */
  display: flex;
  span{
    white-space: nowrap;
  }

  &:hover {
    /* transition:all 0.3s ease; */
    color: #00b1c3;

    &.dropdown {
      background-color: rgba(0, 174, 192, 1);

      span {
        color: #fff;
      }
    }
  }

  &.dropdown {
    margin-bottom: 50px;
    border: 1px solid #e6e5e5;
    background-color: #fff;
    padding: 10px 12px;
    width: 170px;
    text-align: right;
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;
    margin: 0;
    span {
      color: #424242;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &.study_nav {
    position: relative;
  }

  &.first {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  &.last {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  span {
    font-family: "ubuntu-medium";
    font-size: 18px;
    color: #fff;

    &.dropdown {
      font-family: "ubuntu-light";
      color: rgba(86, 87, 87, 1);
      font-size: 16px;
    }

    @media all and (max-width: 1470px) {
      font-size: 15px;
    }
  }

  @media all and (max-width: 1360px) {
    margin-bottom: 10px;
  }
  @media all and (max-width: 1080px) {
    margin-bottom: 30px;
    margin-right: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const DropContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    i {
      transform: rotate(180deg);
    }
  }
`;
const DropDown = styled.div`
  position: absolute;
  /* height: 100px; */
  /* background-color: #fff; */
  border-radius: 5px;
  right: 0;
  top: 50px;
  color: #000 !important;
  transition: transform 0.4s ease;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  &.drop {
    border-radius: 4px;
  }

  &.study_drop {
    top: 70px;
    display: flex;
    flex-direction: row;
    right: 150px;
    gap: 0;
    align-items: flex-start;
  }

  &.migrate {
    top: 70px;
    display: flex;
    flex-direction: row;
    right: 250px;
    gap: 0;
    align-items: flex-start;
  }
  

  &.drop_profile {
    top: 70px;
    display: flex;
    flex-direction: row;
    right: 0;
    gap: 0;
    align-items: flex-start;
  }

  @media screen and (min-width: 2000px) {
    &.study_drop {
      top: 85px;
    }
  }

  @media screen and (max-width: 1400px) {
    &.study_drop {
      right: 25px;
    }
  }

  @media screen and (max-width: 1200px) {
    &.study_drop {
      right: 0px;
    }
  }
`;
const HamburgerDrop = styled.div`
  display: none;

  @media all and (max-width: 1080px) {
    display: ${({ type }) => (type ? "flex" : "none")};
    z-index: ${({ type }) => (type ? 1 : -1)};
    opacity: ${({ type }) => (type ? 1 : 0)};
    position: absolute;
    z-index: ${({ type }) => (type ? 1 : -1)};
    width: 100%;
    transition: 0.4s ease;
    top: 55px;
    right: -30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.32);
    padding: 30px;
  }
`;
const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  @media all and (max-width: 1150px) {
    display: block;
    margin-left: 50px;
    font-size: 30px;
  }
  @media all and (max-width: 480px) {
    margin-left: 10px;
  }
`;
const Button = styled.div`
  color: #fff;
  font-size: 24px;
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 1440px) {
    font-size: 18px;
    width: 30px;
    height: 30px;
  }
`;
const LoginButton = styled.div`
  /* width: 20%; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  @media all and (max-width: 1080px) {
    margin-bottom: 0px;
    /* display: none; */
  }
`;
const Book = styled.div`
  width: 200px;
  cursor: pointer;
  height: 40px;
  border: 2px solid #ffffff;
  /* margin-top: 10px; */
  /* background: linear-gradient(
    to right,
    #21a9c1 0%,
    26.470589637756348%,
    #109bb5 52.941179275512695%,
    76.47058963775635%,
    #1f93b0 100%
  ); */
  /* background: #fff; */
  display: flex;
  font-family: "ubuntu-medium";
  font-size: 16px;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease;
  border-radius: 4px;
  margin-right: 20px;
  &:hover {
    transform: scale(1.04);
    transition: 0.4s ease;
    color: #fff;
    background: #00b1c3;
  }
  @media all and (max-width: 1440px) {
    height: 30px;
    width: 170px;
    font-size: 14px;
  }
  @media all and (max-width: 1360px) {
    width: 150px;
    font-size: 12px;
    margin-top: 0;
  }
  @media all and (max-width: 1080px) {
    margin-top: 0;
  }
  @media all and (max-width: 768px) {
    display: none;
  }
`;

const DropdownLeft = styled.div`
  box-sizing: border-box;
  background-color: rgba(248, 248, 248, 1);
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 175px;
  border-radius: 4px;
  padding: 20px 0px;

  &.active {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const DropdownRight = styled.div`
  box-sizing: border-box;
  padding: 20px;
  background-color: rgba(248, 248, 248, 1);
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  border-top-left-radius: 0;
  max-width: 450px;
  transition: visibitity 0.4s ease-in-out, opacity 0.11s ease-in-out;

  &.active {
    visibility: visible;
    opacity: 1;
  }
`;

const DropDownItem = styled.a`
  text-decoration: none;
  font-family: "ubuntu-light";
  font-size: 17px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;

  &.hovered {
    color: rgba(0, 174, 192, 1);

    svg {
      transform: rotate(45deg);
      path {
        fill: rgba(0, 174, 192, 1);
      }
    }
  }

  &:hover {
    color: rgba(0, 174, 192, 1);

    svg {
      transform: rotate(45deg);
      path {
        fill: rgba(0, 174, 192, 1);
      }
    }
  }
`;

const Title = styled.div`
  padding-bottom: 6px;
  border-bottom: 2px solid rgba(86, 87, 87, 1);
  width: 50%;
`;

const Box = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 30px;
  margin-top: 10px;
`;

const BoxItem = styled.div`
  display: flex;
  align-items: center;
  width: 45%;
  min-width: 150px;
  max-height: 45px;
  cursor: pointer;
  border-bottom: 2px solid rgba(166, 166, 166, 1);
  color: rgba(86, 87, 87, 1);

  span {
    width: 50%;
    text-align: center;
    font-size: 15px;
  }

  &:hover {
    color: rgba(0, 174, 192, 1);
    border-bottom: 2px solid rgba(0, 174, 192, 1);

    svg {
      transform: rotate(45deg);
      path {
        fill: rgba(0, 174, 192, 1);
      }
    }
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  width: 45%;
  min-width: 150px;
  max-height: 45px;
  cursor: pointer;
  border-bottom: 2px solid rgba(166, 166, 166, 1);
  color: rgba(86, 87, 87, 1);

  span {
    width: 50%;
    text-align: center;
    font-size: 15px;
  }

  &:hover {
    color: rgba(0, 174, 192, 1);
    border-bottom: 2px solid rgba(0, 174, 192, 1);

    svg {
      transform: rotate(45deg);
      path {
        fill: rgba(0, 174, 192, 1);
      }
    }
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 25%;

  &.svg {
    justify-content: flex-end;
  }

  /* &.country_img{
    height: 50px;
    width:50px;
    border-radius: 50%;
    overflow: hidden;
  } */

  img {
    max-width: 50px;
    width: 100%;
    object-fit: contain;
  }

  &.country_img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;

    img {
      object-fit: cover;
      max-width: 65px;
    }
  }
`;

const Bottom = styled.div`
  text-align: center;
  font-size: 15px;
  color: rgba(86, 87, 87, 1);

  a {
    color: rgba(0, 174, 192, 1);
  }
`;

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: -1;
`;

const SearchBar = styled.div`
  width: 275px;
  height: 42px;
  border-radius: 4px;
  border: 1.5px solid rgba(255, 255, 255, 1);
  box-sizing: border-box;
  padding: 10px 14px;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  @media all and (max-width: 1350px) {
    width: 250px;
  }
  @media all and (max-width: 1300px) {
    width: 215px;
  }

  @media all and (max-width: 1275px) {
    width: 200px;
  }

  @media all and (max-width: 1150px) {
    width: 275px;
  }

  @media all and (max-width: 650px) {
    width: 200px;
    height: 35px;
  }

  @media all and (max-width: 580px) {
    width: auto;
  }
`;

const Text = styled.p`
  padding: 0;
  margin: 0;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;

  @media screen and (max-width: 1300px) {
    width: 30ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media screen and (max-width: 580px) {
    display: none;
  }
`;

const Gradient = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  background: linear-gradient(
    180deg,
    rgb(0 0 0 / 37%) 0%,
    rgb(255 255 255 / 0%) 100%
  );
  display:${(props)=>props.show ? "block" : "none"};
`;
