import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import downArrow from "../../../../assets/images/careers/icons/down_arrow.svg";
import pdf from "../../../../assets/images/careers/icons/pdf.svg";
import date from "../../../../assets/images/course/modal/icon/date.svg";

import CustomSelect from "../../../utils/CustomSelect";
import { generateLast15Years } from "../../../general/functions";
import { StoreContext } from "../../../context/Store";
import { SET_PAST_EDUCATION_INFO, UPDATE_PHASE } from "../../../context/types";
import ButtonLoader from "../../../general/loader/ButtonLoader";

function Phase3() {
  const values = generateLast15Years();
  const { state, dispatch } = useContext(StoreContext);
  const [value1, setvalue1] = useState(values[2]);
  const [value2, setvalue2] = useState(values[0]);
  const [loader, setloader] = useState(false);
  const [form, setForm] = useState({});

  const formRef = useRef();
  const btnRef = useRef();

  useEffect(() => {
    if (Object.keys(state?.apply_details?.past_educational_info).length > 0) {
      setForm(state?.apply_details?.past_educational_info);
      setvalue1(state?.apply_details?.past_educational_info?.ten_passing_year);
      setvalue2(
        state?.apply_details?.past_educational_info?.twelve_passing_year
      );
    }
  },[]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setloader(true);
    const formData = new FormData(formRef.current);

    const data = {};
    for (const [key, value] of formData.entries()) {
      data[key] = value;
    }
    data.ten_passing_year = value1;
    data.twelve_passing_year = value2;

    dispatch({ type: SET_PAST_EDUCATION_INFO, payload: data });
    setloader(false);
    dispatch({ type: UPDATE_PHASE, payload: 5 });
  };

  const handleBtnClick = () => {
    btnRef.current.click();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleBack = () => {
    dispatch({ type: UPDATE_PHASE, payload: 3 });
  };

  return (
    <>
      <Section onSubmit={(e) => handleSubmit(e)} ref={formRef}>
        <Wrapper>
          <Info>10th Standard Details</Info>
          <InputsDiv>
            <InputCover className="board">
              <Label>
                Select 10th Board<span>*</span>
              </Label>
              <Input
                placeholder="Select a board"
                type="text"
                name="ten_board"
                value={form?.ten_board || ""}
                onChange={(e) => handleChange(e)}
                required
              />
            </InputCover>

            <InputCover className="calender">
              <Label>
                Select 10th Passing Year<span>*</span>
              </Label>
              <Icon>
                <img src={date} alt="" />
              </Icon>
              <CustomSelect
                values={values}
                value={value1}
                setvalue={setvalue1}
                placeholder={"Select year"}
              />
            </InputCover>

            <InputCover className="percentage">
              <Label>
                10th Percentage <span style={{ color: "gray" }}>(%)</span>
                <span>*</span>
              </Label>
              <Input
                placeholder="Percentage"
                type="number"
                name="ten_percentage"
                value={form?.ten_percentage || ""}
                onChange={(e) => handleChange(e)}
                required
              />
            </InputCover>

            <InputCover className="school_name">
              <Label htmlFor="school_name">
                10th School Name<span>*</span>
              </Label>
              <Input
                id="school_name"
                placeholder="School name"
                name="ten_school_name"
                type="text"
                value={form?.ten_school_name || ""}
                onChange={(e) => handleChange(e)}
                required
              />
            </InputCover>
          </InputsDiv>
        </Wrapper>

        <Wrapper className="second">
          <Info>12th Standard Details</Info>
          <InputsDiv className="twlth-info">
            <InputCover className="board">
              <Label>
                Select 12th Board<span>*</span>
              </Label>
              <Input
                placeholder="Select a board"
                type="text"
                name="twelve_board"
                value={form?.twelve_board || ""}
                onChange={(e) => handleChange(e)}
                required
              />
            </InputCover>

            <InputCover className="calender">
              <Label>
                Select 12th Passing Year<span>*</span>
              </Label>
              <Icon>
                <img src={date} alt="" />
              </Icon>
              <CustomSelect
                placeholder={"Select year"}
                values={values}
                value={value2}
                setvalue={setvalue2}
              />
            </InputCover>

            <InputCover className="percentage">
              <Label>
                12th Percentage <span style={{ color: "gray" }}>(%)</span>
                <span>*</span>
              </Label>
              <Input
                placeholder="Percentage"
                type="number"
                name="twelve_percentage"
                value={form?.twelve_percentage || ""}
                onChange={(e) => handleChange(e)}
                required
              />
            </InputCover>

            <InputCover className="school_name">
              <Label htmlFor="school_name">
                12th School Name<span>*</span>
              </Label>
              <Input
                id="school_name"
                placeholder="School name"
                name="twelve_school_name"
                value={form?.twelve_school_name || ""}
                onChange={(e) => handleChange(e)}
                type="text"
                required
              />
            </InputCover>

            <InputCover className="specialization">
              <Label htmlFor="specialization">
                12th Specialisation<span>*</span>
              </Label>
              <Input
                id="specialization"
                placeholder="Specialisation"
                name="twelve_specialisation"
                value={form?.twelve_specialisation || ""}
                onChange={(e) => handleChange(e)}
                type="text"
                required
              />
            </InputCover>
            <HiddedBtn ref={btnRef}></HiddedBtn>
          </InputsDiv>
        </Wrapper>
      </Section>
      <Container>
        <BackBtn onClick={handleBack}>Back</BackBtn>
        {loader ? (
          <SubmiBtn>
            <ButtonLoader />
          </SubmiBtn>
        ) : (
          <SubmiBtn onClick={handleBtnClick}>Submit</SubmiBtn>
        )}
      </Container>
    </>
  );
}

export default Phase3;

const Section = styled.form``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  &.second {
    margin-top: 15px;
  }
`;

const Info = styled.p`
  margin: 0;
  padding: 0;
  text-decoration: underline;
  color: rgba(86, 87, 87, 1);
  font-size: 13px;
`;

const InputsDiv = styled.div`
  display: grid;
  gap: 12px;
  grid-template-areas:
    "board calender percentage"
    "name  null null";

  &.twlth-info {
    grid-template-areas:
      "board calender percentage"
      "name  specialisation null";
  }

  @media screen and (max-width: 800px) {
    grid-template-areas:
      "board calender"
      "name  percentage";

    &.twlth-info {
      grid-template-areas:
        "board calender"
        "percentage name"
        "specialisation null";
    }
  }

  @media screen and (max-width: 490px) {
    grid-template-areas:
      "board board"
      "calender calender"
      "name name"
      "percentage  percentage";

    &.twlth-info {
      grid-template-areas:
        "board board"
        "calender calender"
        "name name"
        "percentage  percentage"
        "specialisation specialisation";
    }
  }
`;

const InputCover = styled.div`
  border: 1.5px solid #cfd3d4;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  background-color: #fff;

  &.w-70 {
    width: 70%;
  }

  &.board {
    grid-area: board;
  }

  &.calender {
    grid-area: calender;
  }

  &.percentage {
    grid-area: percentage;
  }

  &.school_name {
    grid-area: name;
  }

  &.specialization {
    grid-area: specialisation;
  }
`;

const Heading = styled.div`
  color: #565757;
  font-size: 21px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-medium";
`;

const Label = styled.label`
  color: #00aec0;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  position: relative;
  cursor: pointer;

  span {
    color: red;
  }

  &.role-select {
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 0;
      background-image: url(${downArrow});
      background-size: contain;
      width: 25px;
      height: 30px;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  &.cv {
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 0;
      background-image: url(${pdf});
      background-size: contain;
      background-repeat: no-repeat;
      width: 25px;
      height: 30px;
      cursor: pointer;
    }
  }

  &.cv-label {
    color: #abafb1;
    width: max-content;
    border-bottom: 1px solid #abafb1;
    cursor: pointer;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  gap: 5px;

  &.phone {
    grid-area: phone;
  }
`;

const Input = styled.input`
  border: none;
  outline: none;
  padding: 0;
  &::placeholder {
    color: #abafb1;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    font-family: "ubuntu-regular";
    padding: 0;
  }
`;

const Select = styled.select`
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #abafb1;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-regular";
  position: absolute;
  left: 0;
  right: 0;
  padding-left: 15px;
  padding-top: 25px;
  background-color: transparent;

  option:hover {
    background-color: red;
  }
`;

const Option = styled.option`
  color: #000000;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-regular";
  background-color: #ffffff;
  padding: 20px !important;
`;

const FileInput = styled.input`
  display: none !important;
`;

const HiddedBtn = styled.button`
  display: none;
`;

const SubmiBtn = styled.button`
  width: 20%;
  background-color: rgba(0, 174, 192, 1);
  color: #fff;
  text-align: center;
  font-size: 16px;
  line-height: normal;
  border: none;
  outline: none;
  padding: 14px 10px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 50%;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

const BackBtn = styled.button`
  width: 20%;
  background-color: #fff;
  color: rgba(0, 174, 192, 1);
  text-align: center;
  font-size: 16px;
  line-height: normal;
  border: 1px solid rgba(0, 174, 192, 1);
  outline: none;
  padding: 14px 10px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 50%;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

const Box = styled.div`
  width: 30%;
  border: 1.5px solid #cfd3d4;
  display: flex;
  gap: 5px;
  box-sizing: border-box;
  padding: 5px;

  img {
    width: 30px;
    object-fit: contain;
    height: auto;
  }

  select {
    width: 100%;
    border: none;
    outline: none;
  }
`;

const Container = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const Icon = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 15px;
  right: 20px;

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
`;
