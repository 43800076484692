import React, { useEffect, useState } from "react";
import HeaderEdit from "../../includes/HeaderEdit";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  TwitterShareButton,
  LinkedinShareButton,
  FacebookShareButton,
} from "react-share";

import Footer from "../../includes/Footer";
import rightArrow from "../../../../../assets/images/jobs/icons/right-arrow.svg";
import joblocation from "../../../../../assets/images/jobs/icons/job-location.svg";
import calendar from "../../../../../assets/images/jobs/icons/Calendar.svg";
import edu from "../../../../../assets/images/jobs/icons/education.svg";
import exp from "../../../../../assets/images/jobs/icons/exp.svg";
import fb from "../../../../../assets/images/jobs/icons/fb.svg";
import links from "../../../../../assets/images/jobs/icons/links.svg";
import stack from "../../../../../assets/images/jobs/icons/stack.svg";
import mail from "../../../../../assets/images/jobs/icons/mail.svg";
import timer from "../../../../../assets/images/jobs/icons/Timer.svg";
import x from "../../../../../assets/images/jobs/icons/x.svg";
import linkedin from "../../../../../assets/images/jobs/icons/linkedin.svg";
import JobApplyModal from "../../../../modals/JobApplyModal";
import { accountsConfig } from "../../../../../axiosConfig";
import { useParams,useNavigate } from "react-router-dom";

function JobDetail() {
  const [modal, setmodal] = useState(false);
  const [jobInfo, setjobInfo] = useState({});
  const [copied, setcopied] = useState(false);
  const url = window.location.href;
  const points = [
    "Great troubleshooting and analytical skills combined with the desire to tackle challenges head-on",
    "3+ years of experience in back-end development working either with multiple smaller projects simultaneously or large-scale applications",
    "Experience with HTML, JavaScript, CSS, PHP, Symphony and/or Laravel Working regularly with APIs and Web Services (REST, GrapthQL, SOAP, etc)",
    "Have experience/awareness in Agile application development, commercial off-the-shelf software, middleware, servers and storage, and database management",
    "Familiarity with version control and project management systems (e.g., Github, Jira)",
    "Great troubleshooting and analytical skills combined with the desire to tackle challenges head-on",
    "Ambitious and hungry to grow your career in a fast-growing agency",
  ];
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    accountsConfig.get(`study/single-jobs/${id}`).then((response) => {
      const { data, StatusCode } = response.data.app_data;
      if (StatusCode === 6000) {
        setjobInfo(data);
      } else {
        setjobInfo({});
      }
    });
  }, []);

  const handleCopy = () => {
    setcopied(true);
    setTimeout(() => {
      setcopied(false);
    }, 3000);
  };

  return (
    <>
      <Section>
        <HeaderEdit backgroundCl={"#00AEC0"} />
        <Container className="wrapper">
          <Title>Job Details</Title>
          <JobInfoBox>
            <Info>
              <JobName>{jobInfo?.jobs}</JobName>
              <OtherDetail>
                <Company>at {jobInfo?.name}</Company>
                <Type>{jobInfo?.type}</Type>
              </OtherDetail>
            </Info>
            <ApplyNow onClick={() => setmodal(true)}>
              Apply Now <img src={rightArrow} alt="" />
            </ApplyNow>
          </JobInfoBox>
          <DetailSection>
            <Left>
              <Box>
                <Heading>Job Description</Heading>
                <Desc
                  dangerouslySetInnerHTML={{ __html: jobInfo?.description }}
                />
              </Box>
              <Box>
                <Heading>Requirements</Heading>
                <Points>
                  {jobInfo?.requirements?.map((item) => (
                    <Point>{item?.requirement}</Point>
                  ))}
                </Points>
              </Box>
              <Box>
                <Heading>Desirable:</Heading>
                <Points>
                  {jobInfo?.desirables?.map((item) => (
                    <Point>{item?.desirable}</Point>
                  ))}
                </Points>
              </Box>
              <Box>
                <Heading>Benefits</Heading>
                <Points>
                  {jobInfo?.benefits?.map((item) => (
                    <Point>{item?.benefits}</Point>
                  ))}
                </Points>
              </Box>
            </Left>
            <Right>
              <Top>
                <InfoBox className="first">
                  <AnInfo>Salary (USD)</AnInfo>
                  <Salary>{jobInfo?.salary}</Salary>
                  <Span>Yearly salary</Span>
                </InfoBox>
                <InfoBox>
                  <Icon>
                    <img src={joblocation} alt="" />
                  </Icon>
                  <AnInfo>Job Location</AnInfo>
                  <Span>{jobInfo?.location}</Span>
                </InfoBox>
              </Top>
              <Bottom>
                <Heading>Job Overview</Heading>
                <OverviewContainer>
                  <IconBox>
                    <Icon className="job-icons">
                      <img src={calendar} alt="" />
                    </Icon>
                    <Div>
                      <Span>Job Posted:</Span>
                      <H6>{jobInfo?.date_added}</H6>
                    </Div>
                  </IconBox>
                  <IconBox>
                    <Icon className="job-icons">
                      <img src={timer} alt="" />
                    </Icon>
                    <Div>
                      <Span>Job expire in:</Span>
                      <H6>{jobInfo?.job_expiry_date}</H6>
                    </Div>
                  </IconBox>
                  <IconBox>
                    <Icon className="job-icons">
                      <img src={stack} alt="" />
                    </Icon>
                    <Div>
                      <Span>Job Level:</Span>
                      <H6>{jobInfo?.job_level}</H6>
                    </Div>
                  </IconBox>
                  <IconBox>
                    <Icon className="job-icons">
                      <img src={exp} alt="" />
                    </Icon>
                    <Div>
                      <Span>Experience</Span>
                      <H6>{jobInfo?.experience} years</H6>
                    </Div>
                  </IconBox>
                  <IconBox>
                    <Icon className="job-icons">
                      <img src={edu} alt="" />
                    </Icon>
                    <Div>
                      <Span>Education</Span>
                      <H6>{jobInfo?.qualifications}</H6>
                    </Div>
                  </IconBox>
                </OverviewContainer>
                <Line />
                <LinksContainer>
                  <Heading>Share this job:</Heading>
                  <LinkBox>
                    <CopyToClipboard text={url} onCopy={() => handleCopy()}>
                      <Copy>
                        <Icon className="link">
                          <img src={links} alt="" />
                        </Icon>
                        {copied ? "Copied" : "Copy Links"}
                      </Copy>
                    </CopyToClipboard>
                    <SMIcon>
                      <LinkedinShareButton url={url}>
                        <Icon className="sm">
                          <img src={linkedin} alt="" />
                        </Icon>
                      </LinkedinShareButton>
                    </SMIcon>
                    <SMIcon>
                      <FacebookShareButton url={url}>
                        <Icon className="sm">
                          <img src={fb} alt="" />
                        </Icon>
                      </FacebookShareButton>
                    </SMIcon>
                    <SMIcon>
                      <TwitterShareButton url={url}>
                        <Icon className="sm">
                          <img src={x} alt="" />
                        </Icon>
                      </TwitterShareButton>
                    </SMIcon>
                  </LinkBox>
                </LinksContainer>
              </Bottom>
            </Right>
          </DetailSection>
          {/* job share for mobile and tabs */}
          <LinksContainer className="sm-version">
            <Heading>Share this job:</Heading>
            <LinkBox>
              <CopyToClipboard text={url} onCopy={() => handleCopy()}>
                <Copy>
                  <Icon className="link">
                    <img src={links} alt="" />
                  </Icon>
                  {copied ? "Copied" : "Copy Links"}
                </Copy>
              </CopyToClipboard>
              <SMIcon>
                <LinkedinShareButton url={url}>
                  <Icon className="sm">
                    <img src={linkedin} alt="" />
                  </Icon>
                </LinkedinShareButton>
              </SMIcon>
              <SMIcon>
                <FacebookShareButton url={url}>
                  <Icon className="sm">
                    <img src={fb} alt="" />
                  </Icon>
                </FacebookShareButton>
              </SMIcon>
              <SMIcon>
                <TwitterShareButton url={url}>
                  <Icon className="sm">
                    <img src={x} alt="" />
                  </Icon>
                </TwitterShareButton>
              </SMIcon>
            </LinkBox>
          </LinksContainer>
          <ApplyNowSticky onClick={() => setmodal(true)}>
            Apply Now
          </ApplyNowSticky>
        </Container>
        <Separator />
        {jobInfo?.related_jobs?.length > 0 ? (
          <Container className="wrapper second">
            <Title2>Related Jobs</Title2>
            <JobsList>
              {jobInfo?.related_jobs?.map((item) => (
                <Card>
                  <Box1>
                    <Left1>
                      <JobTitle>
                        {item?.jobs} at
                        <span>{item?.name}</span>
                      </JobTitle>
                      <LocationInfo>
                        <span>Location :</span> {item?.location}
                      </LocationInfo>
                    </Left1>
                    <Right1>
                      <Type>{item?.type}</Type>
                    </Right1>
                  </Box1>
                  <Box1 className="row">
                    <Skills>
                      <span>Skills Needed :</span>
                      {item?.skills_needed}
                    </Skills>
                    <Experience>
                      <span>Experience Required :</span>
                      {item?.experience} years
                    </Experience>
                    <Qualification>
                      <span>Qualification :</span>
                      {item?.qualifications}
                    </Qualification>
                    <Specialization>
                      <span>Specialization :</span>
                      {item?.specialization}
                    </Specialization>
                  </Box1>
                  <Box1 className="last btn">
                    <ViewJobBtn onClick={() => navigate(`/job/${item?.id}`)}>
                      View Job <img src={rightArrow} alt="" />
                    </ViewJobBtn>
                  </Box1>
                </Card>
              ))}
            </JobsList>
          </Container>
        ) : null}
        <JobApplyModal jobId={id} modal={modal} setModal={setmodal} />
        <Footer />
      </Section>
    </>
  );
}

export default JobDetail;

const Section = styled.section`
  background-color: #f8f8f8;
  padding-top: 160px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 80px;

  &.second {
    padding: 80px 0;
    gap: 65px;
  }
`;

const Title = styled.h1`
  color: #1897b2;
  text-align: center;
  font-size: 45px;
  margin-bottom: 30px;

  @media screen and (max-width: 580px) {
    font-size: 32px;
    margin-bottom: 0;
  }
`;

const JobInfoBox = styled.div`
  padding: 30px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 580px) {
    padding: 15px;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const JobName = styled.h1`
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media screen and (max-width: 480px) {
    font-size: 28px;
  }

  @media screen and (max-width: 350px) {
    font-size: 24px;
  }
`;

const ApplyNow = styled.div`
  /* height: 100%; */
  background-color: #00aec0;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  padding: 8px 50px;
  align-items: center;
  gap: 7px;
  align-self: stretch;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    display: none;
  }

  img {
    width: 15px;
  }
`;

const OtherDetail = styled.div`
  display: flex;
  gap: 10px;

  @media screen and (max-width: 768px) {
    justify-content: space-between;
  }
  @media screen and (max-width: 380px) {
    flex-direction: column;
  }
`;

const DetailSection = styled.div`
  display: flex;
  gap: 65px;

  @media screen and (max-width: 1080px) {
    flex-direction: column-reverse;
    gap: 30px;
  }
`;

const Company = styled.p`
  color: #474c54;
  font-size: 16px;
`;

const Type = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  text-transform: capitalize;
  background-color: #e7f0fa;
  color: #00aec0;

  @media screen and (max-width: 380px) {
    max-width: 70px;
  }
`;

const Left = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 1080px) {
    width: 100%;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const Right = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (max-width: 1080px) {
    width: 100%;
  }
`;

const Heading = styled.h3`
  font-size: 17px;
  font-weight: unset;
  margin: 0;
  padding: 0;
  font-family: "ubuntu-medium";
`;

const Desc = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: unset;
  line-height: 22px;
  color: #2b2b2b;
  text-align: justify;
`;

const Points = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media screen and (max-width: 580px) {
    padding-left: 25px;
  }
`;

const Point = styled.li`
  font-size: 16px;
  font-style: normal;
  font-weight: unset;
  line-height: 22px;
  color: #2b2b2b;
  text-align: justify;
`;

const Top = styled.div`
  box-sizing: border-box;
  padding: 30px 15px;
  background-color: #fff;
  border: 2px solid #e7f0fa;
  display: flex;

  @media screen and (max-width: 425px) {
    padding: 15px;
  }

  @media screen and (max-width: 380px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Bottom = styled.div`
  display: flex;
  gap: 30px;
  box-sizing: border-box;
  padding: 30px;
  background-color: #fff;
  border: 2px solid #e7f0fa;
  flex-direction: column;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;

  width: 50%;

  &.first {
    border-right: 2px solid #e7f0fa;
  }

  @media screen and (max-width: 380px) {
    width: 80%;
    margin: 0 auto;
    padding: 8px 0;

    &.first {
      border-right: none;
      border-bottom: 2px solid #e7f0fa;
    }
  }
`;

const AnInfo = styled.h3`
  font-size: 17px;
  font-weight: unset;
  margin: 0;
  padding: 0;
  font-family: "ubuntu-medium";
`;

const Salary = styled.div`
  color: #00aec0;
`;

const Span = styled.p`
  font-family: "ubuntu-regular";
  margin: 0;
  padding: 0;
  color: #767f8c;
  font-size: 14px;
  font-weight: unset;
  font-style: normal;
  line-height: 22px;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;

  &.job-icons {
    justify-content: flex-start;
  }

  &.link {
    width: 24px;
    height: 24px;
  }

  &.sm {
    width: 20px;
    height: 20px;
    img {
      width: 20px;
      height: 20px;
    }
  }

  img {
    width: 30px;
    object-fit: contain;
  }
`;

const OverviewContainer = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
`;

const IconBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 40%;
`;

const H6 = styled.h6`
  font-size: 14px;
  font-weight: unset;
  margin: 0;
  padding: 0;
  color: black;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: #e7f0fa;

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  &.sm-version {
    display: none;
  }

  @media screen and (max-width: 1080px) {
    display: none;

    &.sm-version {
      display: flex;
    }
  }
`;

const LinkBox = styled.div`
  display: flex;
  gap: 10px;
`;

const Copy = styled.div`
  background-color: #e7f0fa;
  padding: 8px;
  display: flex;
  gap: 8px;
  color: #00aec0;
  font-size: 16px;
  font-style: normal;
  font-weight: unset;
  line-height: 22px;
  cursor: pointer;
  min-width: 130px;
`;

const SMIcon = styled.div`
  padding: 10px;
  background-color: #e7f0fa;
  cursor: pointer;
`;

const Separator = styled.div`
  width: 100%;
  background-color: #edeff5;
  height: 1.5px;
`;

const Title2 = styled.h1`
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const JobsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Card = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 15px 20px;
  display: flex;
  box-sizing: border-box;
  gap: 10px;
  flex-direction: column;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
    row-gap: 20px;
  }

  @media all and (max-width: 768px) {
    width: 100%;
  }

  @media all and (max-width: 675px) {
    flex-direction: column;
  }
`;

const Box1 = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  border-bottom: 1px solid #e7f0fa;
  padding: 10px 0;
  position: relative;

  &.row {
    flex-direction: row;
    gap: 15px;
  }

  &.btn {
    justify-content: flex-end;
    border: none;
    align-items: center;
    padding: 5px 0px 0px;
  }

  @media screen and (max-width: 850px) {
    &.row {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 650px) {
    &.row {
      column-gap: 0px;
    }
  }

  @media screen and (max-width: 480px) {
    &.btn {
      justify-content: center;
    }
  }

  @media screen and (max-width: 375px) {
    &.row {
      row-gap: 20px;
    }
  }
`;

const Left1 = styled.div`
  display: flex;
  width: 80%;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: 425px) {
    width: 100%;
  }
`;

const Right1 = styled.div`
  display: flex;
  width: 20%;
  justify-content: flex-end;

  @media screen and (max-width: 600px) {
    align-items: flex-end;
  }

  @media screen and (max-width: 425px) {
    width: 35%;
    position: absolute;
    bottom: 10px;
    right: 0px;
  }
`;

const JobTitle = styled.div`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  width: 100%;
  text-align: center;

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    text-align: left;
  }

  span {
    font-size: 18px;
    color: #565757;
    text-align: center;
    padding-left: 5px;

    @media screen and (max-width: 600px) {
      font-size: 17px;
      text-align: left;
      padding-left: 0px;
    }
  }
`;

const LocationInfo = styled.div`
  width: 100%;
  color: #909090;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #00aec0;
  }

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;

const Type1 = styled.div`
  padding: 2px 12px;
  font-size: 15px;
  text-transform: capitalize;
  background-color: rgba(231, 240, 250, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00aec0;
  height: 35px;
  box-sizing: border-box;

  @media screen and (max-width: 600px) {
    font-size: 13px;
    padding: 3px 6px;
    height: 20px;
    align-items: end;
  }
`;

const Skills = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: #909090;

  span {
    color: #565757;
  }

  @media screen and (max-width: 850px) {
    width: 45%;
    flex: unset;
  }
  @media screen and (max-width: 650px) {
    width: 50%;
  }
  @media screen and (max-width: 400px) {
    font-size: 13px;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }
`;

const Experience = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: #909090;

  @media screen and (max-width: 850px) {
    width: 45%;
    flex: unset;
  }

  @media screen and (max-width: 650px) {
    width: 50%;
  }

  @media screen and (max-width: 400px) {
    font-size: 13px;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }

  span {
    color: #565757;
  }
`;
const Qualification = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: #909090;

  @media screen and (max-width: 850px) {
    width: 45%;
    flex: unset;
  }

  @media screen and (max-width: 650px) {
    width: 50%;
  }

  @media screen and (max-width: 400px) {
    font-size: 13px;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }

  span {
    color: #565757;
  }
`;
const Specialization = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: #909090;

  @media screen and (max-width: 850px) {
    width: 45%;
    flex: unset;
  }

  @media screen and (max-width: 650px) {
    width: 50%;
  }

  @media screen and (max-width: 400px) {
    font-size: 13px;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }

  span {
    color: #565757;
  }
`;

const ViewJobBtn = styled.button`
  background-color: #00aec0;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 7px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    font-size: 13px;
    padding: 9px 18px;
  }
`;

const ApplyNowSticky = styled.div`
  position: fixed;
  text-align: center;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #00aec0;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 7px;
  align-self: stretch;
  cursor: pointer;
  z-index: 1;

  @media screen and (max-width: 768px) {
    display: flex;
  }

  @media screen and (max-width: 580px) {
    height: 45px;
  }
  @media screen and (max-width: 380px) {
    height: 40px;
  }
`;
