import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import HeaderEdit from "../includes/HeaderEdit";
import Footer from "../includes/Footer";

import bg from "../../../../assets/images/destination/bg.png";
import downarrow from "../../../../assets/images/destination/icons/downArrow.svg";
import PartimeContent from "../includes/destination/PartimeContent";
import CourseContent from "../includes/destination/CourseContent";
import IntakesContent from "../includes/destination/IntakesContent";
import Cost from "../includes/destination/Cost";
import Investment from "../includes/destination/Investment";
import AdmissionContent from "../includes/destination/AdmissionContent";
import DestinationProfileViewModal from "../../../modals/DestinationProfileViewModal";
import { useParams, useNavigate } from "react-router-dom";
import { accountsConfig } from "../../../../axiosConfig";

const para = `
The sovereign state of United Kingdom of Great Britain and Northern Ireland will take you through its vast rich history of both industrial revolution and modern parliamentary democracy. The country has become one of the most popular destinations when it comes to studying abroad with high quality education system, and job opportunities all over. Since the nation comprises the land of England, Wales, Scotland and North Ireland, it offers you with a perfect blend of multiple cultures. Its capital city London, is one of the largest financial centers in the world and has wide opportunities in arts, commerce, science and technology.
The nation has so much to offer to their international students with its institutions and universities consistently ranked amongst the best in the world. UK has 4 out of the top 10 universities in the world and it has some of the oldest Universities in any English speaking country. The world class education system and enumerable choice of courses with international recognition has always flooded students to the nation.
The sovereign state of United Kingdom of Great Britain and Northern Ireland will take you through its vast rich history of both industrial revolution and modern parliamentary democracy. The country has become one of the most popular destinations when it comes to studying abroad with high quality education system, and job opportunities all over. Since the nation comprises the land of England, Wales, Scotland and North Ireland, it offers you with a perfect blend of multiple cultures. Its capital city London, is one of the largest financial centers in the world and has wide opportunities in arts, commerce, science and technology.
The nation has so much to offer to their international students with its institutions and universities consistently ranked amongst the best in the world. UK has 4 out of the top 10 universities in the world and it has some of the oldest Universities in any English speaking country. The world class education system and enumerable choice of courses with international recognition has always flooded students to the nation.
The nation has so much to offer to their international students with its institutions and universities consistently ranked amongst the best in the world. UK has 4 out of the top 10 universities in the world and it has some of the oldest Universities in any English speaking country. The world class education system and enumerable choice of courses with international recognition has always flooded students to the nation.
`;

function Destination() {
  const [active, setactive] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [modal, setmodal] = useState(false);
  const [destinationData, setDestinationData] = useState(null);

  const navigate = useNavigate();

  const { id } = useParams();

  const tabs = [
    "Part Time",
    "Course Duration",
    "Cost",
    "Investment",
    "Admission Checklist",
    "Intakes & Deadlines",
  ];
  const tabData = [
    <PartimeContent data={destinationData?.part_time} />,
    <CourseContent data={destinationData?.course_durations} />,
    <Cost data={destinationData?.cost} />,
    <Investment data={destinationData?.investment} />,
    <AdmissionContent data={destinationData?.admission_check_list} />,
    <IntakesContent data={destinationData?.intakes_deadline} />,
  ];

  useEffect(() => {
    accountsConfig
      .get(`destinations/single-destinations/${id}`)
      .then((response) => {
        const { StatusCode, data } = response.data.app_data;

        if (StatusCode === 6000) {
          setDestinationData(data);
        } else {
          navigate('/')
          setDestinationData(null);
        }
      });

  },[id]);

  const toggleActive = () => {
    setactive(!active);
  };

  const handleModalopen = () => {
    setmodal(true);
  };

  const handleTabMobilechange = (id) => {
    if (activeTab === id) {
      setActiveTab(-1);
    } else {
      setActiveTab(id);
    }
  };

  return (
    <Section>
      <HeaderEdit backgroundCl={"transparent"} />
      <Container>
        <Banner bgimage={destinationData?.background_image}>
          <Wrapper className="wrapper">
            <TitleBox>
              <Title>Why Study in {destinationData?.country_name}?</Title>
              {/* <SubTitle>Study in the land of THE QUEEN</SubTitle> */}
            </TitleBox>
          </Wrapper>
        </Banner>
        <MoreInfo>
          <Wrapper className="wrapper">
            <Description>
              {destinationData?.description.length > 2250 ? (
                <ShowFull>
                  <p dangerouslySetInnerHTML={{__html: active ? destinationData?.description : destinationData?.description.slice(0, 2250) + "...."}}/>
                  <button style={{ textAlign: "right" }} onClick={toggleActive}>
                    {active ? "Read Less" : "Read More"}
                  </button>
                </ShowFull>
              ) : (
                <Show dangerouslySetInnerHTML={{__html:destinationData?.description}}/>
              )}
            </Description>
            <Description className="mobile">
              {para.length > 750 ? (
                <ShowFull>
                  <p>{active ? para : para.slice(0, 750) + "...."}</p>
                  <button style={{ textAlign: "right" }} onClick={toggleActive}>
                    {active ? "Read Less" : "Read More"}
                  </button>
                </ShowFull>
              ) : (
                <Show>{para}</Show>
              )}
            </Description>
            <TabContainer>
              <Tabs>
                {tabs?.map((item, i) => (
                  <Tab
                    onClick={() => setActiveTab(i)}
                    className={activeTab === i && "active"}
                  >
                    {item}
                  </Tab>
                ))}
              </Tabs>
              <TabContent>{tabData[activeTab]}</TabContent>
            </TabContainer>
            <TabContainer className="mobile">
              {tabs?.map((item, i) => (
                <AccodianContainer>
                  <Accodian onClick={() => handleTabMobilechange(i)}>
                    {item}
                    <Icon className={i === activeTab && "active"}>
                      <img src={downarrow} alt="" />
                    </Icon>
                  </Accodian>
                  {activeTab === i && (
                    <TabContent className="mobile">{tabData[i]}</TabContent>
                  )}
                </AccodianContainer>
              ))}
            </TabContainer>
          </Wrapper>
          <Wrapper className="wrapper">
            {/* <Profile onClick={handleModalopen}>
              Check {destinationData?.country_name} Candidate Profile
            </Profile> */}
             <Profile href='https://wa.me/+919645020503'>
             FREE LIVE Profile Assessment
            </Profile>
          </Wrapper>
        </MoreInfo>
        {/* <DestinationProfileViewModal data={destinationData} modal={modal} setModal={setmodal} /> */}
      </Container>
      <Footer />
    </Section>
  );
}

export default Destination;

const Section = styled.div``;

const Container = styled.div``;

const Wrapper = styled.div`
  position: relative;
  height: 100%;

  &.info-wrapper {
    background-color: rgba(21, 27, 50, 1);
  }
`;

const Banner = styled.div`
background-image: ${props => props.bgimage 
    ? `linear-gradient(rgba(24, 22, 22, 0.7), rgba(44, 40, 40, 0.7)), url(${props.bgimage})` 
    : `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('default-image-path')`
};

  height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  max-height: 560px;
`;

const TitleBox = styled.div`
  position: absolute;
  top: 40%;
  left: 0;
  padding: 30px;
  min-width: 40%;
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0.29);
  display: flex;
  gap: 12px;
  flex-direction: column;

  @media screen and (max-width: 580px) {
    top: 25%;
    padding: 25px;
    min-width: 100%;
  }
`;

const Title = styled.h3`
  color: white;
  font-size: 42px;

  @media screen and (max-width: 580px) {
    font-size: 31px;
    text-align:center;
  }
`;

const Tabs = styled.div`
  display: flex;
`;

const Tab = styled.div`
  padding: 12px 24px;
  border-right: 1px solid rgba(0, 174, 192, 1);
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.active {
    background-color: rgba(0, 174, 192, 1);
    color: #fff;
  }

  @media screen and (max-width: 1200px) {
    font-size: 15px;
    padding: 12px 10px;
  }
`;

const TabContent = styled.div`
  background-color: rgba(248, 248, 248, 1);
  padding: 15px 5px;

  &.mobile {
    padding: 8px 5px;
  }
`;

const Show = styled.p`
  color: #fff;
  line-height: 22px;
  font-family: "ubuntu-light";
  font-size: 16px;

  Button {
    cursor: pointer;
  }
`;

const ShowFull = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  p {
    color: #fff;
    line-height: 22px;
    font-family: "ubuntu-light";
    font-size: 16px;
  }

  button {
    cursor: pointer;
    outline: none;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 15px;
    font-style: normal;
    line-height: 22px;
  }
`;

const SubTitle = styled.p`
  color: white;
  font-size: 17px;
`;

const MoreInfo = styled.div`
  width: 100%;
  background-color: rgba(21, 27, 50, 1);
  padding-bottom: 60px;
`;

const Description = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  padding: 30px;
  position: relative;
  top: -215px;

  &.mobile {
    display: none;
  }

  @media screen and (max-width: 768px) {
    display: none;

    &.mobile {
      display: block;
    }
  }

  @media screen and (max-width: 580px) {
    padding: 20px;
    text-align: justify;
    word-break: break-all;
  }
`;

const TabContainer = styled.div`
  background-color: #fff;
  color: #000;
  position: relative;
  top: -100px;

  &.mobile {
    display: none;
    flex-direction: column;
    gap: 15px;
    background-color: transparent;
  }

  @media screen and (max-width: 768px) {
    display: none;

    &.mobile {
      display: flex;
    }
  }
`;

const Profile = styled.a`
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.108) 100%
  );
  cursor: pointer;
  border-radius: 10px;

  @media screen and (max-width: 768px) {
    padding: 18px 10px;
  }
`;

const AccodianContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const Accodian = styled.div`
  background: rgba(0, 174, 192, 1);
  padding: 10px 15px;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  justify-content: space-between;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 30px;
    transition: transform 0.2s ease-in-out;
  }

  &.active {
    img {
      transform: rotate(180deg);
    }
  }
`;
