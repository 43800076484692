import React, { useEffect, useRef, useState } from "react";
import {
  intake_months,
  step_by_step,
} from "../../../../../general/commonArrays";
import styled from "styled-components";
import curve from "../../../../../../assets/images/studyAbroad/curve.png";
import left from "../../../../../../assets/images/studyAbroad/leftarrow.png";
import right from "../../../../../../assets/images/studyAbroad/rightarrow.png";
import clip from "../../../../../../assets/images/studyAbroad/clip.png";
import bg from "../../../../../../assets/images/studyAbroad/grid.png";
import fade from "../../../../../../assets/images/studyAbroad/fade.png";
import Slider from "react-slick";
import { accountsConfig } from "../../../../../../axiosConfig";

export default function Timeline() {
  const [centeredIndex, setCenteredIndex] = useState(0);
  const [getData, setData] = useState([]);
  const [getTimeLineData, setTimeLineData] = useState([]);
  const [intakeId, setIntakeId] = useState("");
  const [timelineIndex, setTimelineIndex] = useState("");

  // reference for the slider navigation button
  const sliderRef = useRef(null);
  function findNextSlide(currentSlideIndex) {
    
    // Increment the current index to get the next slide index
    let nextSlideIndex = currentSlideIndex + 1;

    // If it exceeds the total number of slides, wrap around to the first slide
    if (nextSlideIndex >= getTimeLineData.length) {
      nextSlideIndex = 0;
    }

    return nextSlideIndex;
  }
  // slider settings
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    vertical: true,
    verticalSwiping: true,
    verticalScrolling: true,

    beforeChange: function (currentSlide, nextSlide) {
      // console.log("before change", currentSlide, nextSlide);
    },
    afterChange: (currentSlide) => {
      
      setCenteredIndex(currentSlide);
    },
  };

  const handleNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
      
    }
  };
  const handlePrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  // listing the intake list api
  useEffect(() => {
    accountsConfig.get("study/list-timeline/").then((res) => {
      const data = res.data.app_data.data;
      if (res.data.app_data.StatusCode == 6000) {
        setData(data);
        setIntakeId(data[0].id);
      } else {
        setData([]);
      }
    });
  }, []);

  //listing timeline data
  useEffect(() => {
    intakeId &&
      accountsConfig.get(`study/list-intake/${intakeId}`).then((res) => {
        const data = res.data.app_data.data;
        if (res.data.app_data.StatusCode == 6000) {
          setTimeLineData(data);
        } else {
          setTimeLineData([]);
        }
      });
  }, [intakeId]);

  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Title>Timeline </Title>
          <Desc>Lorem ipsum dolor sit amet</Desc>
          <Section>
            <Tabs>
              {getData.map((item, index) => (
                <Tab
                  key={index}
                  onClick={() => setIntakeId(item.id)}
                  className={intakeId == item.id && "active"}
                >
                  <Text>{item.title} </Text>
                  <Sub>age of funds: {item.age_of_funds} </Sub>
                  <Curve className={intakeId == item.id && "active"}>
                    {/* <img src={curve} alt="" /> */}
                  </Curve>
                </Tab>
              ))}
            </Tabs>
            <Calendar>
              <TopBar>
                <Clip1>
                  <img src={clip} alt="clip" />
                </Clip1>
                <Middle>
                  <Button onClick={() => handlePrevSlide()}>
                    <img src={left} alt="" />
                  </Button>
                  <Date>{getTimeLineData[centeredIndex]?.duration} </Date>
                  <Button onClick={() => handleNextSlide()}>
                    <img src={right} alt="" />
                  </Button>
                </Middle>
                <Clip2>
                  <img src={clip} alt="clip" />
                </Clip2>
              </TopBar>

              <Sheet>
                <CoverSheet>
                  <Slider {...settings} ref={sliderRef}>
                    {getTimeLineData.map((item, index) => (
                      <Cover>
                        <Round
                          className={centeredIndex === index ? "bold" : ""}
                        >
                          <Span
                            className={centeredIndex === index ? "bold" : ""}
                          >
                            {index + 1}
                          </Span>
                        </Round>
                        <Steps
                          key={index}
                          className={centeredIndex === index ? "bold" : ""}
                        >
                          {item.description}
                        </Steps>
                      </Cover>
                    ))}
                  </Slider>
                </CoverSheet>
              </Sheet>
            </Calendar>
          </Section>
        </Content>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  background-color: #f8f8f8;
  padding: 60px 0;
  @media (max-width: 480px) {
    padding: 0;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const Cover = styled.div`
  display: flex !important;
  align-items: center;
  position: relative;
  &::after {
    content: "";
    height: 100%;
    left: 16px;
    z-index: -1;
    width: 3px;
    background: #bcbcbc;
    position: absolute;
  }
`;
const Round = styled.div`
  left: -20px;
  background-color: #f1f1f1;
  transition: 0.4s ease;
  width: 35px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  height: 35px;
  top: 30%;
  &.bold {
    transition: 0.4s ease;
    background-color: #02afc13d;
  }
`;
const Span = styled.div`
  background-color: #fff;
  font-size: 14px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  transition: 0.4s ease;
  align-items: center;
  &.bold {
    background-color: #00aec0;
    color: #fff;
    transition: 0.4s ease;
  }
`;
const Title = styled.h1`
  color: var(--blue);
  text-align: center;
  margin-bottom: 10px;
`;
const Desc = styled.p`
  text-align: center;
`;
const Section = styled.div`
  margin-top: 50px;
  display: flex;
  gap: 30px;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
const Text = styled.h4`
  margin-bottom: 5px;
`;
const Tabs = styled.div`
  width: 30%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Tab = styled.div`
  background-color: #fff;
  position: relative;
  color: var(--blue);
  padding: 20px;
  margin-bottom: 40px;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  border-radius: 4px;
  box-shadow: 0px 100px 80px 0px #00000000;
  &.active {
    color: #fff;
  }
`;
const Sub = styled.p`
  font-size: 14px;
`;
const Curve = styled.div`
  position: absolute;
  right: 0;
  width: 20%;
  border-radius: 50% 0 0 50%;
  height: 100%;
  background-color: var(--blue);
  top: 0;
  transition: 0.4s ease;
  &.active {
    transition: 0.4s ease;
    width: 100%;
    z-index: -1;
    border-radius: unset;
  }
  @media (max-width: 768px) {
    border-radius: 20px 0 0 20px;
  }
`;
const Calendar = styled.div`
  width: 70%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const TopBar = styled.div`
  background: #02afc126;
  display: flex;
  justify-content: center;
  position: relative;
  padding: 20px 0;
`;
const Clip1 = styled.div`
  position: absolute;
  width: 25px;
  left: 50px;
  top: -10px;
  img {
    display: block;
    width: 100%;
  }
  @media (max-width: 768px) {
    left: 25px;
  }
`;
const Clip2 = styled.div`
  position: absolute;
  right: 50px;
  top: -10px;
  width: 25px;
  img {
    display: block;
    width: 100%;
  }
  @media (max-width: 768px) {
    right: 25px;
  }
`;
const Middle = styled.div`
  display: flex;
  justify-content: space-around;
  width: 70%;
`;
const Button = styled.div`
  cursor: pointer;
  width: 20px;
  display: flex;
  justify-content: center;
  img {
    display: block;
    width: 100%;
  }
`;
const Date = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
  @media (max-width: 480px) {
    width: 75%;
  }
`;
const Sheet = styled.div`
  background: url(${bg});
  background-color: #fff;
  padding: 0 20px;
  scroll-snap-type: y mandatory;
  height: 400px;
  background-size: cover;
  overflow: scroll;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const CoverSheet = styled.div`
  margin-left: 50px;
  @media (max-width: 480px) {
    margin-left: 10px;
  }
`;
const Steps = styled.div`
  position: relative;
  transition: 0.4s ease;
  padding: 40px 20px;
  color: #999999;
  width: 80%;
  &.bold {
    transition: 0.4s ease;
    color: #000;
    font-family: "ubuntu-medium";
  }
`;
