import React, { useEffect, useRef, useState } from 'react';
import HeaderEdit from '../includes/HeaderEdit';
import Footer from '../includes/Footer';
import styled from 'styled-components';
import SearchBar from '../includes/SearchBar';

import image1 from '../../../../assets/images/offers/image1.png';
import checkIcon from '../../../../assets/images/offers/check.svg';
import plusIcon from '../../../../assets/images/offers/plus.png';
import minusIcon from '../../../../assets/images/offers/minus.png';

import Slider from 'react-slick';
import { accountsConfig } from '../../../../axiosConfig';

function Offers() {
    const [images, setImages] = useState([])
    const [isOpen, setOpen] = useState(null);
    const QuickSearchRef = useRef(null)
    const serviceRef = useRef(null)
    const [isMobile, setIsMobile] = useState(false);

    const Highlight = [
        { id: 1, title: "End to End Services" },
        { id: 2, title: "Exclusive Offers for Our Students" },
        { id: 3, title: "100% Loan Assistance" },
        { id: 4, title: "Excellent Scholarship Options" },
        { id: 5, title: "Study Options Abroad Without IELTS" },
        { id: 6, title: "Course Fee Starting from 1.5 Lakh per Semester" },
        { id: 7, title: "Public Universities with Free Education" },
        { id: 8, title: "Pre-departure and Post-arrival Assistance" },
    ];

    const Services = [
        { id: 1, title: "Course and University Guidance", is_drop: false },
        { id: 2, title: "Application Process", is_drop: false },
        { id: 3, title: "Statement of Purpose (SOP) Assistance", is_drop: false },
        { id: 4, title: "Fast-track Offer Letter", is_drop: false },
        { id: 5, title: "Loan Assistance", is_drop: false },
        { id: 6, title: "Scholarship Assistance", is_drop: false },
        { id: 7, title: "Interview Preparation", is_drop: false },
        { id: 8, title: "Visa Application Assistance", is_drop: false },
        {
            id: 9, title: "Pre-departure Services", is_drop: true,
            items: [
                { subtitle: "Assistance to Book Air Tickets" },
                { subtitle: "Assistance for Health Insurance" },
                { subtitle: "Assistance for Money Exchange" },
                { subtitle: "Multi Currency Travel Cards" },
            ]
        },
        {
            id: 10, title: "Post-arrival Services", is_drop: true,
            items: [
                { subtitle: "Assistance for Airport Pick-up and Drop" },
                { subtitle: "Assistance for Accommodation" },
                { subtitle: "Assistance for Part-time Jobs" },
            ]
        },
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
    };

    const handleToggle = (id) => {
        setOpen(isOpen === id ? null : id);
    };
    const handleChange = (id) => {
        if (id === 2) {
            QuickSearchRef.current && QuickSearchRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (id === 1) {
            serviceRef.current && serviceRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // fechData
    useEffect(() => {
        accountsConfig
            .get(`general/offerimages/`)
            .then((response) => {
                const { StatusCode, data } = response.data.app_data;

                if (StatusCode === 6000) {
                    setImages(data);
                } else {
                    setImages([]);
                }
            });

    }, []);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <Container>
                <HeaderEdit backgroundCl="#00AEC0" />
                <QuickSearch ref={QuickSearchRef}>
                    <SearchBar />
                </QuickSearch>
                <Sliders>
                    <Slider {...settings}>
                        {images.map((img, index) => (
                            <div>
                                <img
                                    className='images'
                                    src={isMobile ? img?.mobile_image : img?.image}
                                    alt={img?.image_alt}
                                />
                            </div>
                        ))}
                    </Slider>
                </Sliders>
                <Highlights>
                    <Heading>
                        OUR HIGHLIGHTS
                    </Heading>
                    <CardWrapper>
                        {Highlight.map((hilight, index) => (
                            <Card key={index} onClick={() => handleChange(hilight.id)}>
                                <img src={checkIcon} alt="" />
                                <p>{hilight.title}</p>
                            </Card>
                        ))}
                    </CardWrapper>
                </Highlights>
                <Service ref={serviceRef}>
                    <Heading>
                        OUR SERVICES
                    </Heading>
                    <Dropdown>
                        {Services.map((service, index) => (
                            <Items notLine={service.id} key={index}>
                                <Title onClick={() => handleToggle(service.id)}>
                                    <p>{service.title}</p>
                                    {service.is_drop && <img src={isOpen === service.id ? minusIcon : plusIcon} alt="" />}
                                </Title>
                                {service.is_drop && (
                                    <SubItems isOpen={isOpen === service.id}>
                                        {service.items.map((item, idx) => (
                                            <Subtitle key={idx}>{item.subtitle}</Subtitle>
                                        ))}
                                    </SubItems>
                                )}
                            </Items>
                        ))}
                    </Dropdown>
                </Service>
                <Footer />
            </Container>
        </>
    );
}

export default Offers;

const Container = styled.div`
    width: 100%;
`;

const QuickSearch = styled.div`
    background-color: #00AEC0;
    height: 280px;
    padding-top: 180px;

    @media (max-width: 880px) {
        height: 360px;
        padding-top: 160px;
    }
`;

const Sliders = styled.div`
    margin-top: -10px;
    width: 100%;
    /* height: min-content; */
    .slick-slide {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .images {
        width: 100vw;
        height: 100%;
        /* height: 600px; */
        /* object-fit: cover; */
        object-position: center;
    }
    @media (max-width:767px){
        .images{
            width: 100%;
        }
    }
`;

const Highlights = styled.div`
    width: 80%;
    margin: 0 auto;
    padding-bottom: 20px;
    @media (max-width:1023px){
        width: 90%;
    }
`;

const Service = styled.div`
    width: 80%;
    margin: 0 auto;
    padding-top: 20px;
    @media (max-width:1023px){
        width: 90%;
    }
`;

const Heading = styled.h3`
    font-size: 32px;
    color: #00AEC0;
    text-align: center;
    padding: 30px 0;
    font-family: 'ubuntu-bold';
    @media (max-width:767px){
        font-size: 20px;
    }
`;

const CardWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    @media (max-width:767px){
        grid-template-columns: repeat(1, 1fr);
    }
`;

const Card = styled.div`
    border: 0.5px solid #959595;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 17px;
    cursor: pointer;
    p{
        font-size: 16px;
        font-family: 'ubuntu-bold';
    }
    @media (max-width:767px){
        gap: 14px;
        img{
            width: 20px;
        }
    }
`;

const Dropdown = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    @media (max-width:767px){
        grid-template-columns: repeat(1, 1fr);
    }
`;

const Items = styled.div`
    width: 90%;
    border-bottom: ${props => (props.notLine === 9 || props.notLine === 10 ? 'none' : '1px solid #E0E0E0')};
    padding: 10px;
    @media (max-width: 767px){
        width: 100%;
        padding: 0px;
        border-bottom: ${props => (props.notLine === 10 ? 'none' : '1px solid #E0E0E0')};

    }
`;

const Title = styled.div`
    padding: 20px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;
    p{
        font-family: 'ubuntu-medium';
        font-size: 16px;
    }
    img{
        width: 10px;
        height: 10px;
    }
`;

const SubItems = styled.div`
    padding-left: 20px;
    max-height: ${props => (props.isOpen ? '500px' : '0')};
    overflow: hidden;
    transition: max-height 1s ease-in-out;
`;

const Subtitle = styled.p`
    padding: 5px 0;
    font-family: 'ubuntu-regular';
    font-size: 16px;
`;
