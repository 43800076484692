import React from "react";

//packages
import styled from "styled-components";

//imports
import SpaceText from "../canada/titles/SpaceText";

//images
import map from "../../../../../../assets/images/migrate/aust.svg";
import yellowpoint from "../../../../../../assets/images/migrate/yellowpoint.png";
import { introduction_migrate_australia } from "../../../../../general/commonArrays";

export default function IntroductionAus() {
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
        <Title>Migrate</Title>
          <Details>
            <TextSection>
              <TitleDescription>Why Migrate to Australia?</TitleDescription>
              <DetailDescription>
                <SpaceText /> Migrate to Australia for a high quality of life, 
                robust economy, stunning natural landscapes, and a warm climate. 
                Enjoy excellent healthcare, world-class education, diverse culture, 
                and abundant job opportunities. Live in a safe, inclusive society with a 
                vibrant outdoor lifestyle and strong support for immigrants.
              </DetailDescription>
              <SubTitle>Steps Involved in Australia PR Application Process:</SubTitle>
              <Points>
                {introduction_migrate_australia.map((obj, index) => (
                  <CoverPoint key={index}>
                    <ICon>
                      <img src={yellowpoint} alt="circle" />
                    </ICon>
                    <CoverTextPoint>
                      <TextPoint>{obj.title} </TextPoint>
                      <Span>{obj.sub_title} </Span>
                    </CoverTextPoint>
                  </CoverPoint>
                ))}
              </Points>
            </TextSection>
            <MapContainer>
              <ImageContainer>
                <img src={map} alt="map" />
              </ImageContainer>
            </MapContainer>
          </Details>
        </Content>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  background-color: #00aec0;
  padding-bottom: 80px;
  @media (max-width: 480px) {
    padding-bottom: 10px;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div`
  padding-top: 150px;
  color: #fff;
  @media (max-width: 1080px) {
    padding-top: 0;
  }
`;
const Title = styled.h1`
  color: #fff;
  text-align: center;
  font-size: 50px;
  margin-bottom: 30px;
`;
const Details = styled.div`
  display: flex;
  margin: 50px 0;
  @media (max-width: 1080px) {
    flex-direction: column-reverse;
  }
`;
const TextSection = styled.div`
  width: 60%;
  @media (max-width: 1080px) {
    width: 100%;
  }
`;
const TitleDescription = styled.h1`
  color: #fff;
  letter-spacing: 1px;
  font-size: 48px;
  text-align: center;
  margin-bottom: 20px;
  @media (max-width: 480px) {
    font-size: 32px;
  }
`;
const Points = styled.div`
  &.flex {
    display: flex;
    flex-wrap: wrap;
  }
`;
const CoverPoint = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  &.flex {
    width: 50%;
  }
`;
const ICon = styled.div``;
const CoverTextPoint = styled.div`
  display: flex;
  gap: 5px;
`;
const TextPoint = styled.h4`
  font-size: 18px;
`;
const Span = styled.p``;
const MapContainer = styled.div`
  width: 50%;
  position: relative;
  @media (max-width: 1080px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
const ImageContainer = styled.div`
  position: absolute;
  right: -210px;
  top: -70px;
  img {
    width: 100%;
    display: block;
  }
  @media (max-width: 1280px) {
    position: unset;
  }
  @media (max-width: 480px) {
    width: 90%;
  }
`;
const Pr = styled.div``;
const SubTitle = styled.h3`
  margin-bottom: 20px;
  font-size: 20px;
`;

const DetailDescription = styled.p`
  line-height: 26px;
  font-size: 18px;
  margin-bottom: 20px;
  @media (max-width: 480px) {
    text-align: justify;
  }
`;
