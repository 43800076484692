import React from "react";
import styled from "styled-components";

import item from "../../../../../assets/images/destination/icons/rupee.svg";
import dot from "../../../../../assets/images/destination/icons/dot.svg";

function Investment({ data }) {
  return (
    <Section>
      {data?.map((obj) => (
        <Item>
          <Inner>
            <Icon>
              <img src={item} alt="" />
            </Icon>
            <Value>{obj?.title}</Value>
          </Inner>
        </Item>
      ))}
    </Section>
  );
}

export default Investment;

const Section = styled.div`
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: 768px) {
    padding: 12px 10px;
  }
`;

const Item = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;

  &.inside {
    flex-direction: row;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
`;

const Value = styled.div``;

const Inner = styled.div`
  display: flex;
  gap: 10px;

  &.inside {
    padding-left: 20px;
  }
`;
