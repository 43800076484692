const ADD = "ADD";
const REMOVE = "REMOVE";

const generateLast15Years = () => {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = currentYear; year >= currentYear - 14; year--) {
    years.push(year);
  }

  return years;
};

const generateNext15Years = () => {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = currentYear; year <= currentYear + 14; year++) {
    years.push(year);
  }

  return years;
};

const hideOverFlow = (type) => {
  const html = document.querySelector('html')
  if (type === ADD) {
    html.classList.add('hidden');
  } else {
    html.classList.remove('hidden');  
  }
};

export { generateLast15Years, generateNext15Years, hideOverFlow , ADD, REMOVE };
