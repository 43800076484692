import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Swal from "sweetalert2";

import downArrow from "../../assets/images/careers/icons/down_arrow.svg";
import pdf from "../../assets/images/careers/icons/pdf.svg";
import { accountsConfig } from "../../axiosConfig";
import ButtonLoader from "../general/loader/ButtonLoader";
import { ADD, REMOVE, hideOverFlow } from "../general/functions";

function WebinarRegisterModal({ modal, setModal, id }) {
  const [cv, setcv] = useState("");
  const [error, seterror] = useState("");
  const [loader, setloader] = useState(false);
  const formRef = useRef();

  let validationError = false;

  useEffect(() => {
    if (modal) {
      hideOverFlow(ADD);
    } else {
      hideOverFlow(REMOVE);
    }
  }, [modal]);

  const handleModal = () => {
    setModal(false);
  };

  const handleClrError = (e) => {
    setTimeout(() => {
      seterror("");
      validationError = false;
    }, 3000);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);

    if (formData.get("phone").length !== 10) {
      seterror(1);
      validationError = true;
      handleClrError();
    }
    if (!cv) {
      seterror(2);
      validationError = true;
      handleClrError();
    }

    // submiting the form
    if (!validationError) {
      setloader(true);
      accountsConfig.post("general/apply-careers/", formData).then((res) => {
        const { data, StatusCode } = res.data.app_data;
        setloader(false);

        if (StatusCode === 6000) {
          e.target.reset();
          setcv("");
          showSuccessAlert();
        } else {
          showErrorAlert();
        }
      });
    }
  };

  //sweet alert for success modal
  const showSuccessAlert = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Success",
      showConfirmButton: false,
      html: "<p>Submitted successfully</p>",
      timer: 2500,
      iconColor: "#1eb300",
    });
  };

  //sweet alert for error modal
  const showErrorAlert = () => {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Something went wrong",
      showConfirmButton: false,
      html: "<p>Something went wrong</p>",
      timer: 2500,
      iconColor: "#ff0000",
    });
  };

  return (
    <Section className={modal && "active"}>
      <OverLay onClick={handleModal} />
      <Modal>
        <Container>
          <Form onSubmit={(e) => handleFormSubmit(e)} ref={formRef}>
            <Heading>Send a message</Heading>
            <InputsDiv>
              <InputCover className="name">
                <Label>Name</Label>
                <Input
                  placeholder="Your name"
                  type="text"
                  name="name"
                  required
                />
              </InputCover>

              <InputCover className="phone">
                <Label>
                  Phone Number{" "}
                  {error === 1 && <span>Please enter a 10-digit number</span>}
                </Label>
                <Input
                  placeholder="Your number"
                  type="number"
                  name="phone"
                  pattern="[0-9]{10}"
                  title="Please enter a 10-digit number"
                  required
                />
              </InputCover>

              <InputCover className="email">
                <Label htmlFor="email">Email </Label>
                <Input
                  id="email"
                  placeholder="Email"
                  name="email"
                  type="email"
                  required
                />
              </InputCover>
            </InputsDiv>
            <Div>
              <CancelBtn type="button" onClick={handleModal}>
                Cancel
              </CancelBtn>
              <SubmiBtn type={loader ? "button" : "submit"}>
                {loader ? <ButtonLoader /> : "Submit"}
              </SubmiBtn>
            </Div>
            {/* jobid */}
            <input type="text" name="apply_for" hidden value={id} />
          </Form>
        </Container>
      </Modal>
    </Section>
  );
}

export default WebinarRegisterModal;

const Section = styled.section`
  position: fixed;
  inset: 0;
  backdrop-filter: blur(2px);
  z-index: 255;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0000008a;
  transform: scale(0, 0);

  &.active {
    transform: scale(1, 1);
  }
`;

const Modal = styled.div`
  padding: 30px;
  box-sizing: border-box;
  height: 100%;
  max-height: 580px;
  width: 80%;
  max-width: 1440px;
  margin: auto;
  position: relative;
  z-index: 256;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding: 24px;
  }

  @media screen and (max-width: 580px) {
    width: 100%;
    padding: 18px;
  }
`;

const Container = styled.div`
  width: 100%;
`;

const Form = styled.form`
  padding: 30px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 80px;

  @media screen and (max-width: 580px) {
    padding: 15px;
  }
`;

const InputsDiv = styled.div`
  display: grid;
  gap: 24px;
  grid-template-areas:
    "name name"
    "phone email"
    "cv cv";

  @media screen and (max-width: 768px) {
    grid-template-areas:
      "name name"
      "phone phone"
      "email email"
      "cv cv";
  }
`;

const InputCover = styled.div`
  border: 1.5px solid #cfd3d4;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  background-color: #fff;

  &.name {
    grid-area: name;
  }

  &.email {
    grid-area: email;
  }

  &.phone {
    grid-area: phone;
  }

  &.role {
    grid-area: role;
  }

  &.cv {
    grid-area: cv;
  }

  @media screen and (max-width: 768px) {
    height: 65px;
  }
`;

const Heading = styled.div`
  color: #565757;
  font-size: 21px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-medium";
`;

const Label = styled.label`
  color: #00aec0;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  position: relative;
  cursor: pointer;

  span {
    font-size: 13px;
    color: red;
  }

  &.role-select {
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 0;
      background-image: url(${downArrow});
      background-size: contain;
      width: 25px;
      height: 30px;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  &.cv {
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 0;
      background-image: url(${pdf});
      background-size: contain;
      background-repeat: no-repeat;
      width: 25px;
      height: 30px;
      cursor: pointer;
    }
  }

  &.cv-label {
    color: #abafb1;
    width: max-content;
    border-bottom: 1px solid #abafb1;
    cursor: pointer;
  }
`;
const Input = styled.input`
  border: none;
  outline: none;
  padding: 0;
  &::placeholder {
    color: #abafb1;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    font-family: "ubuntu-regular";
    padding: 0;
  }
`;

const Select = styled.select`
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #abafb1;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-regular";
  position: absolute;
  left: 0;
  right: 0;
  padding-left: 15px;
  padding-top: 25px;
  background-color: transparent;

  option:hover {
    background-color: red;
  }
`;

const Option = styled.option`
  color: #000000;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-family: "ubuntu-regular";
  background-color: #ffffff;
  padding: 20px !important;
`;

const FileInput = styled.input`
  display: none !important;
`;

const SubmiBtn = styled.button`
  width: 20%;
  background-color: #00aec0;
  color: #fff;
  text-align: center;
  font-size: 18px;
  line-height: normal;
  border: none;
  outline: none;
  padding: 14px 10px;
  cursor: pointer;
  border-radius: 4px;

  @media screen and (max-width: 768px) {
    width: 50%;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

const CancelBtn = styled.button`
  width: 20%;
  background-color: #e8e8e8;
  color: #696969;
  text-align: center;
  font-size: 18px;
  line-height: normal;
  border: none;
  outline: none;
  padding: 14px 10px;
  font-weight: unset;
  cursor: pointer;
  border-radius: 4px;

  @media screen and (max-width: 768px) {
    width: 50%;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

const Div = styled.div`
  display: flex;
  gap: 15px;
`;

const OverLay = styled.div`
  position: absolute;
  inset: 0;
`;
