import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import ParticipateModal from '../../../../modals/ParticipateModal';
import ParticipateImageModal from '../../../../modals/ParticipateImageModal';

function QuickFinder() {
  const location = useLocation();
  const isOfferPage = location.pathname === '/offers';
  const navigate = useNavigate();
  const [isModal, setModal] = useState(false);
  const [isImageModal, setImageModal] = useState(false);
  const [currentContent, setCurrentContent] = useState(0);

  const contents = ["Participate and Win", "Register Now", "Explore More"];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentContent((prevContent) => (prevContent + 1) % contents.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [contents.length]);

  useEffect(() => {
    if (location.pathname === '/') {
      setImageModal(true);
    }
  }, []);

  return (
    <>
      <Section>
        <Whatsapp href="https://wa.me/+919645020503">
          <i className="ri-whatsapp-fill"></i>
        </Whatsapp>
        <HelpMe onClick={() => navigate('/contact')}>
          Help me study abroad
        </HelpMe>
      </Section>
      <ParticipateAndWin isOfferPage={isOfferPage} onClick={() => setModal(true)}>
        <Content>
          <p>{contents[currentContent]}</p>
        </Content>
      </ParticipateAndWin>
      <ParticipateImageModal
        isModal={isImageModal}
        setModal={setImageModal}
        participateModal={setModal}
      />
      <ParticipateModal isModal={isModal} setModal={setModal} />
    </>
  );
}

export default QuickFinder;

const fadeInOut = keyframes`
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
`;

const ParticipateAndWin = styled.div`
  cursor: pointer;
  background-color: #D64105;
  position: fixed;
  right: 0%;
  top: 40%;
  border-radius: 20px 0px 0px 20px;
  width: 60px;
  height: 250px;
  z-index: 100;
  display:  ${props => props.isOfferPage ? 'none' : 'flex'};
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      180deg, /* Change the direction to top-bottom */
      rgba(255, 255, 255, 0) 30%,
      rgb(255 255 255 / 53%),
      rgba(255, 255, 255, 0) 70%
    );
    top: -100px;
    left: 0;
    animation: shimmerAnimation 3s infinite linear;
  }

  @keyframes shimmerAnimation {
    0% {
      top: -100px;
    }
    20% {
      top: 100%;
    }
    100% {
      top: 100%;
    }
  }

  @media (max-width: 768px) {
    width: 40px;
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeInOut} 4s linear infinite; // Change animation duration to 5 seconds

  p {
    text-align: center;
    writing-mode: vertical-rl;
    rotate: 180deg;
    text-orientation: mixed;
    font-size: 1.3rem;
    color: white;
    font-family: 'ubuntu-bold';
  }

  @media (max-width: 768px) {
    width: 50%;
    
    p {
      font-size: 1rem;
    }
  }
`;

const Section = styled.div`
  @media (max-width: 480px){
    background-color: rgba(82, 82, 82, 0.6);
    position: fixed;
    cursor: pointer;
    bottom: 1px;
    width: 100%;
    height: 100px;
    z-index: 100;
    display: none;
  }
`;

const Whatsapp = styled.a`
  color: #25d366;
  font-size: 26px;
  background: #fff;
  z-index: 10000;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  position: fixed;
  cursor: pointer;
  border-radius: 50%;
  bottom: 40px;
  right: 40px;
  font-size: 40px;

  @media all and (max-width: 480px) {
    right: 30px;
    bottom: 20px;
  }
`;

const HelpMe = styled.p`
  font-size: 18px;
  z-index: 10000;
  text-decoration: none;
  position: fixed;
  cursor: pointer;
  border-radius: 30px;
  padding: 20px 20px;
  bottom: 40px;
  right: 120px;
  color: white;
  font-family: 'ubuntu-bold';
  background-color: #D64105;

  @media (max-width: 480px){
    bottom: 20px;
  }

  @media (max-width: 375px){
    bottom: 20px;
    right: 110px;
    font-size: 16px;
    padding: 18px 18px;
  }
`;
